import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AlertCircle,
  ArrowLeft,
  CalendarTime,
  CircleCheck,
  Edit,
  Plus,
  Trash
} from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Checkbox,
  Divider,
  MultiSelect,
  Select,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import dayjs from 'dayjs';
import { arrayMove } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EditProductCard from './EditProductCard';
import EditProductVariant from './EditProductVariant';
import ManageProductVariants from './ManageProductVariants';
import SchedulerModal from '../../common/SchedulerModal';
import AppCheckbox from '../../common/AppCheckbox';
import {
  ECOM_PRODUCT_STATUS_ENUM,
  WEIGHT_UNIT_ENUM
} from '../../../config/constants';
import MediaDropzone from '../media/MediaDropzone';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import ConfirmModal from '../../common/ConfirmModal';
import { triggerNotification } from '../../../helpers/notification';
import AppCard from '../../common/AppCard';
import { uploadFileContentRecursively } from '../../../helpers/awsHelper';
import { PRODUCT_CATEGORY_LIST } from '../../../config/productCategories';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import { formatUtcDate, sterilizeUrlHandle } from '../../../helpers/format';
import CustomNumberInput from '../../common/CustomNumberInput';
import ManageProductInputs from './ManageProductInputs';

const PRODUCT_STATUS = [
  { value: ECOM_PRODUCT_STATUS_ENUM.ACTIVE, label: 'Active' },
  { value: ECOM_PRODUCT_STATUS_ENUM.DRAFT, label: 'Draft' }
];

const VIEW_MODAL_ACTIONS_ENUM = {
  DISCARD: 'DISCARD',
  DELETE: 'DELETE',
  ARCHIVE: 'ARCHIVE',
  DUPLICATE: 'DUPLICATE',
  SCHEDULE_START: 'SCHEDULE_START',
  SCHEDULE_END: 'SCHEDULE_END'
};

const initialState = {
  name: '',
  description: '',
  productType: '',
  fkEcomCategory: '',
  fundraisingRecommended: false,
  scheduleDate: null,
  scheduleEndDate: null,
  batchOrderShipping: false,
  minimumQuantity: '',
  requireExactMinimum: false,
  urlHandle: null,
  fkEcomProductStatus: ECOM_PRODUCT_STATUS_ENUM.DRAFT,
  ecomVendorCollections: [],
  ecomVendorProductTags: [],
  ecomVendorProductMedia: [],
  ecomVendorProductInputs: [],
  ecomVendorProductVariant: {
    key: new Date().getTime().toString(),
    price: 0,
    compareAtPrice: null,
    costPerItem: null,
    sku: '',
    barcode: '',
    trackQuantity: false,
    continueSellingWhenOutOfStock: false,
    requiresShipping: true,
    weight: 0,
    weightUnit: WEIGHT_UNIT_ENUM.KG,
    originCountry: '',
    originProvince: '',
    harmonizedSystemCode: '',
    ecomVendorProductVariantInputs: [],
    ecomVendorProductVariantOptions: [],
    ecomVendorProductVariantMedia: []
  },
  ecomVendorProductVariants: []
};

const EditProductView = ({ duplicate }) => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const {
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller
  } = useMediaQueryIndex();
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const { ecomVendorProductUuid } = useParams();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchVendorProduct,
    createVendorProducts,
    updateVendorProducts,
    deleteVendorProducts,
    fetchVendorSetupSummary
  } = useContext(VendorContext);
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const [
    selectedEcomVendorProductMediaKeys,
    setSelectedEcomVendorProductMediaKeys
  ] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [newProductUuid, setNewProductUuid] = useState(null);
  const {
    hasInitialized,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    hasUnsavedChanges,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(initialState, {
    confirmDiscard: true,
    containerWidth: 950
  });
  const ecomVendor = authState.ecomVendors.find(
    (v) => v.pkEcomVendor.toString() === authState.pkEcomVendor?.toString()
  );
  const loading =
    (ecomVendorProductUuid && !newProductUuid && !hasFetched.current) ||
    state.ecomVendor.loading;
  const ecomVendorProduct =
    state.ecomVendorProduct.value?.uuid === ecomVendorProductUuid
      ? state.ecomVendorProduct.value
      : null;
  const selectedMedia =
    formState.ecomVendorProductMedia?.filter(
      (m) => !m.deleted && selectedEcomVendorProductMediaKeys.includes(m.key)
    ) ?? [];
  const titleUrlHandle = sterilizeUrlHandle(formState.name);

  useEffect(() => {
    if (!ecomVendorProductUuid) {
      resetFormState(initialState);
    }
    else if (!newProductUuid && ecomVendorProductUuid) {
      fetchVendorProduct(ecomVendorProductUuid, null, () => {
        triggerNotification('Product not found!');
        navigate('/vendor/products/new');
      });
      hasFetched.current = true;
    }
  }, [ecomVendorProductUuid]);

  useEffect(() => {
    if (!state.ecomVendorProduct.loading && !!ecomVendorProduct) {
      // eslint-disable-next-line no-use-before-define
      resetFormState(getInitializeExistingProductState());
      setSubmitError(null);
    }
  }, [state.ecomVendorProduct.loading, ecomVendorProduct, duplicate]);

  const getInitializeExistingProductState = () => ({
    ...initialState,
    ...ecomVendorProduct,
    batchOrderShipping: !!ecomVendorProduct.minimumQuantity,
    urlHandle: !duplicate ? ecomVendorProduct?.urlHandle ?? null : null,
    fkEcomCategory: ecomVendorProduct.fkEcomCategory?.toString() ?? '',
    fkEcomProductStatus:
      ecomVendorProduct.fkEcomProductStatus?.toString() ??
      ECOM_PRODUCT_STATUS_ENUM.DRAFT.toString(),
    fundraisingRecommended: ecomVendorProduct.fundraisingRecommended,
    scheduleDate: ecomVendorProduct.scheduleDate
      ? dayjs(formatUtcDate(ecomVendorProduct.scheduleDate))
      : null,
    scheduleEndDate: ecomVendorProduct.scheduleEndDate
      ? dayjs(formatUtcDate(ecomVendorProduct.scheduleEndDate))
      : null,
    ecomVendorProductMedia: ecomVendorProduct.ecomVendorProductMedia
      .map((m) => ({
        ...m,
        key: m.pkEcomVendorProductMedia.toString(),
        file: null,
        sort: m.sort,
        alt: m.alt,
        contentType: m.contentType,
        src: m.src,
        preview: m.src
      }))
      .sort((a, b) => a.sort - b.sort),
    ecomVendorProductInputs: ecomVendorProduct.ecomVendorProductInputs
      .sort((a, b) => a.sort - b.sort)
      .map((i, iIndex) => ({
        ...i,
        key: (new Date().getTime() + iIndex).toString(),
        ecomVendorProductInputOptions: i.ecomVendorProductInputOptions
          .sort((a, b) => a.sort - b.sort)
          .map((o, index) => ({
            ...o,
            key: (new Date().getTime() + index).toString()
          }))
      })),
    ecomVendorProductVariant: {
      key: new Date().getTime().toString(),
      ...(ecomVendorProduct.ecomVendorProductVariants[0]
        ? {
            ...ecomVendorProduct.ecomVendorProductVariants[0],
            ecomVendorProductVariantOptions: [],
            uuid: null
          }
        : initialState.ecomVendorProductVariant)
    },
    ecomVendorProductVariants: ecomVendorProduct.ecomVendorProductVariants.map(
      (v) => ({
        ...v,
        key: v.pkEcomVendorProductVariant.toString(),
        fkEcomVendorProductMedia: null,
        src:
          ecomVendorProduct.ecomVendorProductMedia.find(
            (m) => m.pkEcomVendorProductMedia === v.fkEcomVendorProductMedia
          )?.src ?? null,
        ecomVendorProductVariantInputs: ecomVendorProduct.ecomVendorProductInputs
          .filter((i) =>
            i.ecomVendorProductVariants.some(
              (pk) => pk === v.pkEcomVendorProductVariant
            )
          )
          .map((i, index) => ({
            ...i,
            key: (new Date().getTime() + index).toString(),
            fkEcomVendorProductInput: i.pkEcomVendorProductInput
          })),
        ecomVendorProductVariantOptions: [
          ...v.ecomVendorProductVariantOptions.map((op) => ({
            ...op,
            key: `${op.name}-${op.nameSort}`,
            valueKey: `${op.value}-${op.valueSort}`,
            initialized: true
          }))
        ]
      })
    ),
    ecomVendorCollections: ecomVendorProduct.ecomVendorCollections.map((c) =>
      c.pkEcomVendorCollection.toString()
    )
  });

  const getRequestData = (
    media,
    productFormState,
    unsavedChangesOnly = false
  ) => {
    const variants =
      productFormState.ecomVendorProductVariants.length === 0
        ? [productFormState.ecomVendorProductVariant]
        : productFormState.ecomVendorProductVariants;
    return {
      uuid: ecomVendorProduct?.uuid,
      fkEcomProductStatus: productFormState.fkEcomProductStatus,
      ecomVendorCollections: productFormState.ecomVendorCollections,
      ecomVendorProduct: {
        ...productFormState,
        fkEcomCategory: !productFormState.fkEcomCategory
          ? 0
          : productFormState.fkEcomCategory,
        fundraisingRecommended: productFormState.fundraisingRecommended,
        minimumQuantity:
          productFormState.batchOrderShipping &&
          productFormState.minimumQuantity > 1
            ? productFormState.minimumQuantity
            : null,
        requireExactMinimum: productFormState.batchOrderShipping
          ? productFormState.requireExactMinimum
          : false,
        ecomVendorProductMedia: media,
        ecomVendorProductInputs: productFormState.ecomVendorProductInputs.map(
          (i) => ({
            ...i,
            referenceKey: i.key
          })
        )
      },
      ecomVendorProductVariants: [
        ...variants
          .filter((v) => !unsavedChangesOnly || v.hasUnsavedChanges)
          .map((v) => ({
            ...v,
            weight: v.weight ? v.weight : -1,
            costPerItem: v.costPerItem ? v.costPerItem : -1,
            compareAtPrice: v.compareAtPrice ? v.compareAtPrice : -1,
            quantity: v.quantity ? v.quantity : -1,
            src: media.find((m) => m.preview === v.src && !m.deleted)?.src ?? ''
          })),
        ...(ecomVendorProduct
          ? ecomVendorProduct.ecomVendorProductVariants
              .filter((v) => variants.every((r) => r.uuid !== v.uuid))
              .map((m) => ({ ...m, deleted: true }))
          : [])
      ]
    };
  };

  const onSubmitError = (message) => {
    setSubmitError(message);
    if (message !== submitError) {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
  };

  const onSubmitProductForm = (productFormState) => {
    const variantMissingOptions = productFormState.ecomVendorProductVariants.find(
      (v) => v.ecomVendorProductVariantOptions.some((o) => !o.value || !o.name)
    );
    const missingOptionName = variantMissingOptions?.ecomVendorProductVariantOptions.some(
      (o) => !o.name
    );
    const missingOptionValue = variantMissingOptions?.ecomVendorProductVariantOptions.find(
      (o) => !o.value
    );

    const duplicateVariant = productFormState.ecomVendorProductVariants.find(
      (v) =>
        productFormState.ecomVendorProductVariants.some(
          (f) =>
            f.key !== v.key &&
            f.ecomVendorProductVariantOptions.every((o) =>
              v.ecomVendorProductVariantOptions.some(
                (o2) =>
                  o2.name.toLowerCase().trim() ===
                    o.name.toLowerCase().trim() &&
                  o2.value.toLowerCase().trim() === o.value.toLowerCase().trim()
              )
            )
        )
    );

    const variantMissingPricing = productFormState.ecomVendorProductVariants.find(
      (v) => !v.price || v.price <= 0
    );

    if (missingOptionName) {
      onSubmitError(
        'Variant option name is required. Please enter a value or remove it.'
      );
    }
    else if (missingOptionValue) {
      onSubmitError(
        <AppText style={{ fontSize: 14 }}>
          Variant option value for <b>{missingOptionValue.name}</b> is required.
        </AppText>
      );
    }
    else if (duplicateVariant) {
      onSubmitError(
        'Duplicate variant options found. Please remove or change the duplicate options.'
      );
    }
    else if (
      variantMissingPricing &&
      productFormState.fkEcomProductStatus.toString() ===
        ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ) {
      onSubmitError(
        'Price required for active products. Please enter a value or change the status to draft.'
      );
    }
    else {
      submitFormState((formData, onErrorCallback) =>
        uploadFileContentRecursively(
          formState.ecomVendorProductMedia,
          `ecom-vendor-${
            authState.pkEcomVendor
          }-product-${new Date().getTime()}`,
          (media) => {
            if (!duplicate && ecomVendorProduct) {
              const requestData = getRequestData(media, productFormState, true);
              updateVendorProducts(
                authState.pkEcomVendor,
                { ecomVendorProducts: [requestData], selectFirstItem: true },
                (data) => {
                  if (newProductUuid && data[0].uuid !== newProductUuid) {
                    setNewProductUuid(null);
                  }
                  triggerNotification(`Product updated!`, 'success');
                },
                onErrorCallback
              );
            }
            else {
              const requestData = getRequestData(media, productFormState);
              createVendorProducts(
                authState.pkEcomVendor,
                { ecomVendorProducts: [requestData], selectFirstItem: true },
                (data) => {
                  setNewProductUuid(data[0]?.uuid);
                  triggerNotification('Product created!', 'success');
                  navigate(`/vendor/products/${data[0]?.uuid}`);

                  if (
                    state.ecomVendorSetupSummary.value?.ecomVendor
                      .sandboxEnabled &&
                    !state.ecomVendorSetupSummary.value?.hasProducts
                  ) {
                    fetchVendorSetupSummary(authState.pkEcomVendor, {
                      skipLoading: true
                    });
                  }
                },
                onErrorCallback
              );
            }
          },
          onErrorCallback
        )
      );
    }
  };

  const getBackPath = () => {
    switch (fromLocation?.toLowerCase()) {
      case 'inventory':
        return '/vendor/inventory';
      case 'products':
        return '/vendor/products';
      default: {
        if (duplicate) {
          return `/vendor/products/${ecomVendorProductUuid}`;
        }
        return '/vendor/products';
      }
    }
  };

  return !loading &&
    ecomVendor &&
    (!ecomVendorProductUuid || (ecomVendorProduct && hasInitialized)) ? (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmitProductForm(formState);
      }}
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: isLargeMobileOrSmaller ? 'stretch' : 'center',
          flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            to={getBackPath()}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppText
            style={{
              flex: 1,
              fontSize: 22,
              fontWeight: 700,
              wordBreak: 'break-word'
            }}
          >
            {ecomVendorProduct?.name ?? 'Add product'}
          </AppText>
          {ecomVendorProduct && !duplicate && (
            <AppStack style={{ marginLeft: 5 }}>
              {ecomVendorProduct.fkEcomProductStatus.toString() ===
              ECOM_PRODUCT_STATUS_ENUM.ACTIVE ? (
                <Badge color="green">Active</Badge>
              ) : ecomVendorProduct.fkEcomProductStatus.toString() ===
                ECOM_PRODUCT_STATUS_ENUM.ARCHIVED ? (
                <Badge color="dark">Archived</Badge>
              ) : (
                <Badge color="gray">Draft</Badge>
              )}
            </AppStack>
          )}
        </AppFlexbox>

        {ecomVendorProduct && !duplicate && (
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <Button
              color="dark"
              component={Link}
              disabled={isSubmitting}
              radius="md"
              size="xs"
              style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
              to={`/vendor/products/${ecomVendorProductUuid}/duplicate`}
              variant="light"
            >
              Duplicate
            </Button>
            <Button
              color="dark"
              component={Link}
              disabled={isSubmitting}
              radius="md"
              size="xs"
              style={{ flex: isLargeMobileOrSmaller ? 1 : 'unset' }}
              target="_blank"
              to={`/vendor/catalog/products/${ecomVendor.urlHandle}/${ecomVendorProduct.urlHandle}`}
              variant="light"
            >
              Preview
            </Button>
          </AppFlexbox>
        )}
      </AppFlexbox>
      {submitError && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppFlexbox
            style={{
              padding: 8,
              gap: 8,
              backgroundColor: '#c40000',
              color: '#fff'
            }}
          >
            <AppStack>
              <AlertCircle size={18} />
            </AppStack>
            <AppText style={{ fontSize: 14 }}>
              Please fix the error with this product.
            </AppText>
          </AppFlexbox>
          <AppStack style={{ padding: 8 }}>
            {typeof submitError === 'string' ? (
              <AppText style={{ fontSize: 14 }}>{submitError}</AppText>
            ) : (
              submitError
            )}
          </AppStack>
        </AppCard>
      )}

      {duplicate && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 8 }}
          withBorder
        >
          <AppFlexbox style={{ gap: 8 }}>
            <AppStack>
              <AlertCircle color="#eca70a" size={18} />
            </AppStack>
            <AppText style={{ fontSize: 14 }}>
              Duplicate this product by changing the <b>Title</b> and clicking
              save.
            </AppText>
          </AppFlexbox>
        </AppCard>
      )}

      {newProductUuid && newProductUuid === ecomVendorProduct?.uuid && (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ padding: 8, backgroundColor: '#067D62' }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <CircleCheck color="#FFF" size={18} />
                <AppText
                  style={{ fontSize: 14, fontWeight: 500, color: '#FFF' }}
                >
                  Added {ecomVendorProduct.name}
                </AppText>
              </AppFlexbox>
            </AppFlexbox>

            <Divider />
            <AppFlexbox style={{ padding: 8 }}>
              <AppText
                style={{
                  color: '#000',
                  fontSize: 14
                }}
              >
                <Anchor
                  component={Link}
                  style={{
                    color: '#000',
                    fontSize: 14,
                    textDecoration: 'underline'
                  }}
                  to="/vendor/products/new"
                >
                  Add another product
                </Anchor>
                ,{' '}
                <Anchor
                  component={Link}
                  style={{
                    color: '#000',
                    fontSize: 14,
                    textDecoration: 'underline'
                  }}
                  to="/vendor/products"
                >
                  view your products
                </Anchor>
                , or{' '}
                <Anchor
                  component={Link}
                  style={{
                    color: '#000',
                    fontSize: 14,
                    textDecoration: 'underline'
                  }}
                  to="/vendor/settings#locations"
                >
                  change shipping locations
                </Anchor>
              </AppText>
            </AppFlexbox>
          </AppStack>
        </AppCard>
      )}

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack
          style={{
            flex: 2,
            gap: 16
          }}
        >
          <EditProductCard contentProps={{ style: { padding: 16 } }}>
            <TextInput
              disabled={isSubmitting}
              label="Title"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              placeholder="Short sleeve t-shirt"
              required
              style={{ flex: 1 }}
              value={formState.name}
            />
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  gap: 5,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Description
                </AppText>

                {formState.description.length > 0 && (
                  <AppText style={{ fontSize: 13, color: '#666' }}>
                    {formState.description.length} / 750
                  </AppText>
                )}
              </AppFlexbox>
              <Textarea
                autosize
                disabled={isSubmitting}
                maxLength={750}
                minRows={8}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value.substring(0, 750)
                  })
                }
                value={formState.description ?? ''}
              />
            </AppStack>
          </EditProductCard>

          <EditProductCard contentProps={{ gap: 8 }}>
            {!selectedMedia.length ? (
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 16
                }}
              >
                <AppFlexbox
                  onClick={() => setSelectedEcomVendorProductMediaKeys([])}
                  style={{ alignItems: 'center', gap: 8, cursor: 'pointer' }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                    Media
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
            ) : (
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 16
                }}
              >
                <AppFlexbox
                  onClick={() => setSelectedEcomVendorProductMediaKeys([])}
                  style={{ alignItems: 'center', gap: 8, cursor: 'pointer' }}
                >
                  <AppCheckbox checked onChange={() => {}} size="xs" />
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    {selectedMedia.length} selected
                  </AppText>
                </AppFlexbox>

                <Anchor
                  onClick={() => {
                    if (!isSubmitting) {
                      setFormState({
                        ...formState,
                        ecomVendorProductMedia: formState.ecomVendorProductMedia.map(
                          (m) => ({
                            ...m,
                            deleted: selectedEcomVendorProductMediaKeys.includes(
                              m.key
                            )
                              ? true
                              : m.deleted
                          })
                        )
                      });
                      setSelectedEcomVendorProductMediaKeys([]);
                    }
                  }}
                  style={{ fontSize: 14, color: '#c40000', fontWeight: 500 }}
                >
                  Remove
                </Anchor>
              </AppFlexbox>
            )}

            <MediaDropzone
              disabled={isSubmitting}
              enableMediaSelect
              files={formState.ecomVendorProductMedia.filter((f) => !f.deleted)}
              fkEcomVendor={authState.pkEcomVendor}
              onChangeOrder={({ active, over }) => {
                const oldIndex = formState.ecomVendorProductMedia.findIndex(
                  (f) => f.sort === active.id
                );
                const newIndex = formState.ecomVendorProductMedia.findIndex(
                  (f) => f.sort === over.id
                );
                setFormState({
                  ...formState,
                  ecomVendorProductMedia: arrayMove(
                    formState.ecomVendorProductMedia,
                    oldIndex,
                    newIndex
                  ).map((item, index) => ({
                    ...item,
                    sort: index + 1
                  }))
                });
              }}
              onSelectFile={(file) => {
                if (
                  !selectedEcomVendorProductMediaKeys.find(
                    (k) => k === file.key
                  )
                ) {
                  setSelectedEcomVendorProductMediaKeys([
                    ...selectedEcomVendorProductMediaKeys,
                    file.key
                  ]);
                }
                else {
                  setSelectedEcomVendorProductMediaKeys([
                    ...selectedEcomVendorProductMediaKeys.filter(
                      (k) => k !== file.key
                    )
                  ]);
                }
              }}
              onUpload={(uploadedMedia) => {
                const ecomVendorProductMedia = [
                  ...formState.ecomVendorProductMedia
                    .map((m) => ({
                      ...m,
                      deleted: !uploadedMedia.some((u) => u.key === m.key)
                    }))
                    .sort((a, b) => a.sort - b.sort),
                  ...uploadedMedia
                    .filter(
                      (m) =>
                        !formState.ecomVendorProductMedia.some(
                          (f) => f.key === m.key
                        )
                    )
                    .map((m, index) => ({
                      key: (new Date().getTime() + index).toString(),
                      ...m,
                      file: m.file,
                      fkEcomVendorMedia: m.fkEcomVendorMedia ?? null,
                      src: m.src,
                      preview: m.preview,
                      contentType: m.contentType,
                      alt: m.alt,
                      deleted: false
                    }))
                ].map((m, i) => ({
                  ...m,
                  sort: i + 1
                }));

                setFormState({
                  ...formState,
                  ecomVendorProductMedia,
                  ecomVendorProductVariants: [
                    ...formState.ecomVendorProductVariants.map((v) => ({
                      ...v,
                      src: ecomVendorProductMedia.find(
                        (m) => m.preview === v.src && !m.deleted
                      )
                        ? v.src
                        : null
                    }))
                  ]
                });
              }}
              selectedMediaKeys={selectedEcomVendorProductMediaKeys}
              showSortGrid
            />
          </EditProductCard>

          {(!ecomVendorProductUuid ||
            ecomVendorProduct.ecomVendorProductVariants.some(
              (v) => v.ecomVendorProductVariantOptions.length === 0
            )) && (
            <EditProductVariant
              disabled={isSubmitting}
              ecomVendorProductVariant={
                !ecomVendorProductUuid
                  ? formState.ecomVendorProductVariant
                  : formState.ecomVendorProductVariants[0] ??
                    formState.ecomVendorProductVariant
              }
              onChange={(ecomVendorProductVariant) => {
                if (!ecomVendorProductUuid) {
                  setFormState({
                    ...formState,
                    ecomVendorProductVariant
                  });
                }
                else {
                  setFormState({
                    ...formState,
                    ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                      (v) =>
                        v.key === ecomVendorProductVariant.key
                          ? {
                              ...ecomVendorProductVariant,
                              hasUnsavedChanges: true
                            }
                          : v
                    )
                  });
                }
              }}
            />
          )}

          <ManageProductInputs
            disabled={isSubmitting}
            ecomVendorProductInputs={formState.ecomVendorProductInputs}
            ecomVendorProductVariants={formState.ecomVendorProductVariants}
            hasUnsavedChanges={hasUnsavedChanges}
            onAddProductInput={(inputData) => {
              setFormState({
                ...formState,
                ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                  (v) => ({
                    ...v,
                    hasUnsavedChanges: true,
                    ecomVendorProductVariantInputs: inputData.ecomVendorProductVariantInputs.some(
                      (iv) => iv.key === v.key
                    )
                      ? [
                          ...v.ecomVendorProductVariantInputs,
                          {
                            fkEcomVendorProductInput:
                              inputData.fkEcomVendorProductInput,
                            ecomVendorProductInputReferenceKey: inputData.key
                          }
                        ]
                      : v.ecomVendorProductVariantInputs
                  })
                ),
                ecomVendorProductInputs: [
                  ...formState.ecomVendorProductInputs,
                  {
                    ...inputData,
                    key: new Date().getTime().toString(),
                    sort: formState.ecomVendorProductInputs.length + 1
                  }
                ]
              });
            }}
            onRemoveProductInput={(inputData) => {
              setFormState({
                ...formState,
                ecomVendorProductInputs: formState.ecomVendorProductInputs.filter(
                  (i) => i.key !== inputData.key
                ),
                ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                  (v) => ({
                    ...v,
                    hasUnsavedChanges: true,
                    ecomVendorProductVariantInputs: v.ecomVendorProductVariantInputs.filter(
                      (iv) =>
                        iv.ecomVendorProductInputReferenceKey !==
                          inputData.key &&
                        iv.fkEcomVendorProductInput !==
                          inputData.pkEcomVendorProductInput
                    )
                  })
                )
              });
            }}
            onSortProductInputs={(inputs) => {
              setFormState({
                ...formState,
                ecomVendorProductInputs: inputs.map((i, index) => ({
                  ...i,
                  sort: index + 1
                }))
              });
            }}
            onUpdateProductInput={(inputData) => {
              setFormState({
                ...formState,
                ecomVendorProductInputs: formState.ecomVendorProductInputs.map(
                  (i) =>
                    i.key === inputData.key
                      ? {
                          ...i,
                          ...inputData
                        }
                      : i
                ),
                ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                  (v) => {
                    const filteredInputs = v.ecomVendorProductVariantInputs.filter(
                      (iv) => {
                        const isDifferentInput =
                          iv.fkEcomVendorProductInput !==
                          inputData.pkEcomVendorProductInput;
                        const isDifferentReferenceKey =
                          iv.ecomVendorProductInputReferenceKey !==
                          inputData.key;

                        return !iv.ecomVendorProductInputReferenceKey
                          ? isDifferentInput
                          : isDifferentReferenceKey && isDifferentInput;
                      }
                    );

                    const newInput = inputData.ecomVendorProductVariantInputs.some(
                      (iv) => iv.key === v.key
                    )
                      ? [
                          {
                            fkEcomVendorProductInput:
                              inputData.pkEcomVendorProductInput,
                            ecomVendorProductInputReferenceKey: inputData.key
                          }
                        ]
                      : [];

                    return {
                      ...v,
                      hasUnsavedChanges: true,
                      ecomVendorProductVariantInputs: [
                        ...filteredInputs,
                        ...newInput
                      ]
                    };
                  }
                )
              });
            }}
            productPrice={formState.ecomVendorProductVariant.price}
          />

          <ManageProductVariants
            disabled={isSubmitting}
            ecomVendorProduct={formState}
            ecomVendorProductVariantCopy={formState.ecomVendorProductVariant}
            ecomVendorProductVariants={formState.ecomVendorProductVariants}
            hasUnsavedChanges={hasUnsavedChanges}
            onUpdateVendorProductVariantImage={(media, variants) => {
              const existingMedia = formState.ecomVendorProductMedia.find(
                (m) => m.preview === media?.preview
              );
              setFormState({
                ...formState,
                ecomVendorProductMedia:
                  existingMedia || !media
                    ? formState.ecomVendorProductMedia
                    : [
                        ...formState.ecomVendorProductMedia,
                        {
                          ...media,
                          deleted: false,
                          sort: formState.ecomVendorProductMedia.length + 1
                        }
                      ],
                ecomVendorProductVariants: [
                  ...formState.ecomVendorProductVariants.map((v) =>
                    variants.some((i) => i.key === v.key)
                      ? {
                          ...v,
                          hasUnsavedChanges: true,
                          src: media?.preview ?? null
                        }
                      : v
                  )
                ]
              });
            }}
            onUpdateVendorProductVariants={(newVariants) => {
              setFormState({
                ...formState,
                ecomVendorProductVariants: newVariants
              });
            }}
            submitError={submitError}
          />

          <EditProductCard title="Search optimization">
            <AppStack style={{ gap: 8 }}>
              <TextInput
                disabled={isSubmitting}
                label="URL handle"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    urlHandle: e.currentTarget.value.replace(
                      /[^a-zA-Z0-9-_]/g,
                      ''
                    )
                  })
                }
                value={
                  formState.urlHandle != null
                    ? formState.urlHandle
                    : titleUrlHandle
                }
              />
              <AppText style={{ fontSize: 13, color: '#666', marginTop: 5 }}>
                A URL handle is the web address for this product. It can only
                contain letters, numbers, and hyphens. If left blank, the title
                will be used to create one for you
              </AppText>

              {(formState.name || formState.urlHandle) && (
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 13,
                      color: '#666',
                      whiteSpace: 'nowrap',
                      overflow: 'unset'
                    }}
                  >
                    Current url:
                  </AppText>
                  <Anchor
                    component={Link}
                    style={{
                      fontSize: 13,
                      color: 'dodgerblue',
                      fontWeight: 500,
                      wordBreak: 'break-word'
                    }}
                    target="_blank"
                    to={`https://store.sportsheadz.com/vendor/catalog/products/${
                      ecomVendor.urlHandle
                    }/${
                      formState.urlHandle ? formState.urlHandle : titleUrlHandle
                    }`}
                    underline="always"
                  >
                    https://store.sportsheadz.com/vendor/catalog/products/
                    {ecomVendor.urlHandle}/
                    {formState.urlHandle ? formState.urlHandle : titleUrlHandle}
                  </Anchor>
                </AppFlexbox>
              )}
            </AppStack>
          </EditProductCard>
        </AppStack>
        <AppStack style={{ flex: 1 }}>
          <EditProductCard title="Status">
            <Select
              data={PRODUCT_STATUS}
              disabled={isSubmitting}
              onChange={(v) =>
                setFormState({
                  ...formState,
                  fkEcomProductStatus: v
                })
              }
              value={formState.fkEcomProductStatus ?? ''}
            />
          </EditProductCard>
          <EditProductCard title="Publishing">
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Sales channels
              </AppText>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppStack
                      style={{
                        backgroundColor: 'green',
                        width: 10,
                        height: 10,
                        borderRadius: 10
                      }}
                    />
                    <AppText style={{ fontSize: 14 }}>Online Store</AppText>
                  </AppFlexbox>

                  {!formState.scheduleDate && (
                    <ActionableIcon
                      color="dark"
                      disabled={isSubmitting}
                      onClick={() =>
                        onOpenModal(VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_START)
                      }
                    >
                      <CalendarTime size={20} />
                    </ActionableIcon>
                  )}
                </AppFlexbox>
                {formState.scheduleDate && (
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppStack
                      style={{
                        minWidth: 10,
                        height: 10,
                        borderRadius: 10
                      }}
                    />

                    <AppStack style={{ flex: 1, gap: 5 }}>
                      <AppFlexbox
                        style={{
                          gap: 5,
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppText style={{ color: '#666', fontSize: 12 }}>
                          Scheduled for{' '}
                          {dayjs(formState.scheduleDate).format('MMMM D, YYYY')}{' '}
                          at {formState.scheduleDate.format('h:mm A')} EDT
                        </AppText>

                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          <ActionIcon
                            color="dark"
                            disabled={isSubmitting}
                            onClick={() =>
                              onOpenModal(
                                VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_START
                              )
                            }
                            size="sm"
                            variant="subtle"
                          >
                            <Edit size={20} />
                          </ActionIcon>
                          <ActionIcon
                            color="dark"
                            disabled={isSubmitting}
                            onClick={() => {
                              setFormState({
                                ...formState,
                                scheduleDate: null
                              });
                            }}
                            size="sm"
                            variant="subtle"
                          >
                            <Trash size={20} />
                          </ActionIcon>
                        </AppFlexbox>
                      </AppFlexbox>
                      {formState.scheduleEndDate ? (
                        <AppFlexbox
                          style={{
                            gap: 5,
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppText style={{ color: '#666', fontSize: 12 }}>
                            Ends{' '}
                            {dayjs(formState.scheduleEndDate).format(
                              'MMMM D, YYYY'
                            )}{' '}
                            at {formState.scheduleEndDate.format('h:mm A')} EDT
                          </AppText>

                          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                            <ActionIcon
                              color="dark"
                              disabled={isSubmitting}
                              onClick={() =>
                                onOpenModal(
                                  VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END
                                )
                              }
                              size="sm"
                              variant="subtle"
                            >
                              <Edit size={20} />
                            </ActionIcon>
                            <ActionIcon
                              color="dark"
                              disabled={isSubmitting}
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  scheduleEndDate: null
                                });
                              }}
                              size="sm"
                              variant="subtle"
                            >
                              <Trash size={20} />
                            </ActionIcon>
                          </AppFlexbox>
                        </AppFlexbox>
                      ) : (
                        <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                          <Plus color="dodgerblue" size={14} />
                          <Anchor
                            onClick={() =>
                              onOpenModal(VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END)
                            }
                            style={{ fontSize: 12, color: 'dodgerblue' }}
                          >
                            Add an end date
                          </Anchor>
                        </AppFlexbox>
                      )}
                    </AppStack>
                  </AppFlexbox>
                )}
              </AppStack>
            </AppStack>

            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Markets
              </AppText>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                  <AppStack
                    style={{
                      backgroundColor: 'green',
                      width: 10,
                      height: 10,
                      borderRadius: 10
                    }}
                  />
                  <AppText style={{ fontSize: 14 }}>Canada</AppText>
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>
          </EditProductCard>

          <EditProductCard
            contentProps={{ style: { padding: 0 } }}
            title="Purchasing"
          >
            <AppStack style={{ padding: '0px 16px', paddingTop: 8 }}>
              <AppCheckbox
                checked={formState.batchOrderShipping}
                description="When selected, this product ships in batches, grouping multiple customer orders to a single destination per shipment."
                disabled={isSubmitting}
                label="Batch shipping"
                onChange={() =>
                  setFormState({
                    ...formState,
                    batchOrderShipping: !formState.batchOrderShipping
                  })
                }
                styles={{
                  description: { fontSize: 13 },
                  label: { fontWeight: 500 }
                }}
              />
            </AppStack>

            {formState.batchOrderShipping ? (
              <>
                <Divider />
                <AppStack style={{ padding: 16, paddingTop: 0 }}>
                  <AppCheckbox
                    checked={formState.requireExactMinimum}
                    description="When selected, orders must match the exact quantity to qualify for batch shipment."
                    disabled={isSubmitting}
                    label="Require exact quantity"
                    onChange={() =>
                      setFormState({
                        ...formState,
                        requireExactMinimum: !formState.requireExactMinimum
                      })
                    }
                    styles={{
                      description: { fontSize: 13 },
                      label: { fontWeight: 500 }
                    }}
                  />
                  <CustomNumberInput
                    decimalScale={0}
                    description={
                      formState.requireExactMinimum
                        ? 'The exact number of units required to qualify for batch shipment. Orders must meet this quantity precisely.'
                        : 'The minimum number of units required to initiate a batch shipment. Orders can exceed this amount and still qualify.'
                    }
                    disabled={isSubmitting}
                    fixedDecimalScale
                    hideControls={isSubmitting}
                    label={
                      formState.requireExactMinimum
                        ? 'Order quantity'
                        : 'Minimum order quantity'
                    }
                    min={1}
                    onChange={(value) =>
                      setFormState({
                        ...formState,
                        minimumQuantity: value
                      })
                    }
                    required
                    style={{ flex: 1 }}
                    styles={{
                      description: { fontSize: 13 },
                      label: { fontWeight: 500 }
                    }}
                    value={formState.minimumQuantity}
                  />
                </AppStack>
              </>
            ) : (
              <>
                <Divider />
                <AppStack style={{ padding: 16, paddingTop: 0 }}>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Orders will be fulfilled individually as they are placed.
                  </AppText>
                </AppStack>
              </>
            )}
          </EditProductCard>

          <EditProductCard title="Product organization">
            <Select
              clearable
              data={PRODUCT_CATEGORY_LIST.sort((a, b) =>
                a.name.localeCompare(b.name)
              ).map((c) => ({
                value: c.value.toString(),
                label: c.name
              }))}
              disabled={isSubmitting}
              label="Category"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkEcomCategory: value
                })
              }
              searchable
              value={formState.fkEcomCategory}
            />
            <TextInput
              disabled={isSubmitting}
              label="Sub category"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  productType: e.currentTarget.value
                })
              }
              value={formState.productType ?? ''}
            />
            <MultiSelect
              data={
                state.ecomVendorCollections.value
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((c) => ({
                    value: c.pkEcomVendorCollection.toString(),
                    label: c.name
                  })) ?? []
              }
              disabled={isSubmitting}
              hidePickedOptions
              label="Collections"
              onChange={(values) =>
                setFormState({
                  ...formState,
                  ecomVendorCollections: values
                })
              }
              placeholder=""
              value={formState.ecomVendorCollections.filter((c) =>
                state.ecomVendorCollections.value.some(
                  (v) => v.pkEcomVendorCollection.toString() === c
                )
              )}
            />
            <Checkbox
              checked={formState.fundraisingRecommended}
              description="Recommend this product to be used and advertise to stores for fundraising."
              disabled={isSubmitting}
              label="Fundraising product"
              onChange={() => {
                setFormState({
                  ...formState,
                  fundraisingRecommended: !formState.fundraisingRecommended
                });
              }}
              style={{ marginTop: 5 }}
              styles={{ label: { fontWeight: 500 } }}
            />
          </EditProductCard>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        p={{ base: 16, sm: '16px 0px' }}
        style={{
          justifyContent: 'flex-end',
          flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
        }}
      >
        {ecomVendorProduct && !duplicate && (
          <>
            <Button
              color="dark"
              disabled={isSubmitting}
              onClick={() => {
                onOpenModal(VIEW_MODAL_ACTIONS_ENUM.ARCHIVE);
              }}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="button"
              variant="outline"
            >
              {ecomVendorProduct.fkEcomProductStatus.toString() ===
              ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                ? 'Unarchive product'
                : 'Archive product'}
            </Button>
            <Button
              color="red"
              disabled={isSubmitting}
              onClick={() => {
                onOpenModal(VIEW_MODAL_ACTIONS_ENUM.DELETE);
              }}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="button"
              variant="filled"
            >
              Delete product
            </Button>
          </>
        )}

        <Button
          color="dark"
          disabled={!hasUnsavedChanges}
          id="save-button"
          loading={isSubmitting}
          radius="md"
          size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
          type="submit"
          variant="filled"
        >
          Save
        </Button>
      </AppFlexbox>

      <SchedulerModal
        initialDate={
          modalState.action === VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END
            ? formState.scheduleEndDate
            : formState.scheduleDate
        }
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_START ||
            modalState.action === VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END)
        }
        minDate={
          modalState.action === VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END
            ? new Date(formState.scheduleDate)
            : null
        }
        onClose={onCloseModal}
        onConfirm={(dateTime) => {
          onCloseModal();
          if (modalState.action === VIEW_MODAL_ACTIONS_ENUM.SCHEDULE_END) {
            setFormState({
              ...formState,
              scheduleEndDate: dateTime
            });
          }
          else {
            setFormState({
              ...formState,
              scheduleDate: dateTime
            });
          }
        }}
      />

      {ConfirmDiscardModal}

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete product"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_MODAL_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to delete <b>{ecomVendorProduct?.name}</b>?
              Any media that’s only used by this product will also be deleted.
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteVendorProducts(
            authState.pkEcomVendor,
            [ecomVendorProduct.pkEcomVendorProduct],
            () => {
              triggerNotification('Product deleted!', 'success');
              navigate('/vendor/products');
              if (
                state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled
              ) {
                fetchVendorSetupSummary(authState.pkEcomVendor, {
                  skipLoading: true
                });
              }
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${ecomVendorProduct?.name}?`}
      />

      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText={`Yes, ${
          ecomVendorProduct?.fkEcomProductStatus.toString() ===
          ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
            ? 'unarchive'
            : 'archive'
        } product`}
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_MODAL_ACTIONS_ENUM.ARCHIVE
        }
        message={
          <AppStack style={{ gap: 10 }}>
            {ecomVendorProduct?.fkEcomProductStatus.toString() ===
            ECOM_PRODUCT_STATUS_ENUM.ARCHIVED ? (
              <AppText style={{ fontSize: 14 }}>
                Unarchiving this product will change its status to draft so you
                can work on it again.
              </AppText>
            ) : (
              <>
                <AppText style={{ fontSize: 16 }}>
                  Are you sure you want to archive this product? Archiving this
                  product will hide it from your store page.
                </AppText>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  You’ll find it using the status filter in your product list.
                </AppText>
              </>
            )}
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          const requestData = getRequestData(
            formState.ecomVendorProductMedia,
            formState
          );
          updateVendorProducts(
            authState.pkEcomVendor,
            {
              selectFirstItem: true,
              ecomVendorProducts: [
                {
                  ...requestData,
                  fkEcomProductStatus:
                    ecomVendorProduct.fkEcomProductStatus.toString() ===
                    ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                      ? ECOM_PRODUCT_STATUS_ENUM.DRAFT
                      : ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                }
              ]
            },
            () => {
              onCloseModal();
              triggerNotification(
                `Product ${
                  ecomVendorProduct.fkEcomProductStatus.toString() ===
                  ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
                    ? 'unarchive'
                    : 'archive'
                }!`,
                'success'
              );
            },
            (error) => {
              onChangeModalLoading(false);
              triggerNotification(error);
            }
          );
        }}
        title={
          hasUnsavedChanges
            ? 'Save changes and archive product?'
            : 'Archive product?'
        }
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 950,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        p={{ base: '0px 8px', sm: 0 }}
        style={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: isLargeMobileOrSmaller ? 'stretch' : 'center',
          flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
        }}
      >
        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
          <Skeleton height={30} width={200} />
        </AppFlexbox>

        {ecomVendorProductUuid && (
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <Skeleton height={30} w={{ base: '100%', xsm: 80 }} />
            <Skeleton height={30} w={{ base: '100%', xsm: 80 }} />
          </AppFlexbox>
        )}
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isLaptopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 2 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
        <AppStack style={{ flex: 1 }}>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="50%" />
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

EditProductView.propTypes = { duplicate: PropTypes.bool };

export default EditProductView;
