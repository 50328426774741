import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Radio } from '@mantine/core';
import { ArrowLeft, ArrowRight } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import { translateVendorOrderData } from '../../../helpers/vendorHelper';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notification';

const VendorOrderAcceptModal = ({
  isOpen,
  onClose,
  ecomOrder,
  pkEcomOrders,
  selectAll,
  isBulkOrders,
  onSuccess
}) => {
  const { state: authState } = useContext(AuthContext);
  const { state, acceptEcomOrder, fetchPaginatedVendorOrders } = useContext(
    VendorContext
  );
  const [previewState, setPreviewState] = useState({
    ecomOrders: [],
    totalCount: 0,
    previewIndex: 0,
    loading: true
  });
  const [formState, setFormState] = useState({
    pkEcomOrders: [],
    selectAll: false,
    showPreview: false,
    loading: false
  });
  const { ecomOrderProducts: productData } = ecomOrder
    ? translateVendorOrderData(ecomOrder)
    : {};
  const pendingProducts =
    productData?.filter(
      (p) =>
        p.fkEcomOrderProductStatus?.toString() ===
          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING && p.amountRemaining > 0
    ) ?? [];
  const selectedItemCount = pendingProducts.reduce(
    (r, c) => r + c.amountRemaining,
    0
  );

  const allPendingPkEcomOrders = isBulkOrders
    ? state.ecomOrdersSummary.value?.pendingBulkOrders ?? []
    : state.ecomOrdersSummary.value?.pendingOrders ?? [];

  const selectedPkEcomOrders = formState.selectAll
    ? allPendingPkEcomOrders
    : formState.pkEcomOrders;

  const selectedPreviewData =
    previewState.ecomOrders[previewState.previewIndex];

  useEffect(() => {
    if (isOpen) {
      const noProductsSelected = !pkEcomOrders || !pkEcomOrders.length;
      setPreviewState({ ...previewState, loading: noProductsSelected });
      setFormState({
        pkEcomOrders:
          pkEcomOrders?.filter((p) => allPendingPkEcomOrders.includes(p)) ?? [],
        selectAll,
        showPreview: noProductsSelected,
        loading: false
      });
    }
  }, [isOpen, ecomOrder]);

  useEffect(() => {
    if (isOpen && formState.showPreview && selectedPkEcomOrders.length > 0) {
      setPreviewState({ ...previewState, loading: true });
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        {
          pkEcomOrders: selectedPkEcomOrders,
          isBulkShipment: isBulkOrders,
          skipStateUpdate: true
        },
        (result) => {
          setPreviewState({
            ecomOrders: result.data,
            totalCount: result.totalCount,
            previewIndex: 0,
            loading: false
          });
        },
        () => {
          setPreviewState({ ...previewState, loading: false });
          triggerNotification(
            'Failed to fetch order data. Please try again later.'
          );
        }
      );
    }
  }, [isOpen, formState.showPreview]);

  return (
    <ResponsiveModal
      formSectionProps={{
        padding: 0,
        cancelTitle: !ecomOrder && formState.showPreview ? 'Back' : 'Close',
        onCancel: () => {
          if (!ecomOrder && formState.showPreview) {
            setFormState({ ...formState, showPreview: false });
          }
          else {
            onClose();
          }
        },
        submitColor: 'dark',
        submitTitle:
          ecomOrder || formState.showPreview
            ? `Yes, accept ${ecomOrder ? 'products' : 'orders'}`
            : 'Continue',
        isSubmitDisabled: ecomOrder
          ? pendingProducts.length === 0
          : selectedPkEcomOrders.length === 0,
        isLoading: formState.loading,
        onSubmit: () => {
          if (ecomOrder || formState.showPreview) {
            setFormState({ ...formState, loading: true });
            acceptEcomOrder(
              authState.pkEcomVendor,
              {
                pkEcomOrders: ecomOrder
                  ? [ecomOrder.pkEcomOrder]
                  : selectedPkEcomOrders
              },
              () => {
                triggerNotification(
                  ecomOrder
                    ? 'Order accepted'
                    : `${singularPluralFormat(
                        selectedPkEcomOrders.length,
                        'order',
                        'orders'
                      )} accepted`,
                  'success'
                );
                if (onSuccess) {
                  onSuccess();
                }
                onClose();
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, loading: false });
              }
            );
          }
          else {
            setFormState({ ...formState, showPreview: true });
            setPreviewState({ ...previewState, loading: true });
          }
        }
      }}
      isLoading={!ecomOrder && previewState.loading}
      isOpen={isOpen}
      onClose={onClose}
      title={ecomOrder ? 'Accept order products' : 'Accept orders'}
    >
      {ecomOrder ? (
        <AppStack style={{ gap: 0 }}>
          {pendingProducts.length === 0 ? (
            <AppStack style={{ padding: '32px 16px' }}>
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  fontStyle: 'italic',
                  textAlign: 'center',
                  color: '#666'
                }}
              >
                All products have already been accepted for this order.
              </AppText>
            </AppStack>
          ) : (
            <>
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Are you sure you want to accept the following products from
                  this order?
                </AppText>

                <AppText style={{ fontSize: 14 }}>
                  By proceeding, these products will be marked as accepted, and
                  no further changes, including refunds, can be made by the
                  merchant. Any adjustments or refund requests will require the
                  merchant to contact you directly.
                </AppText>

                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This action cannot be undone.
                </AppText>
              </AppStack>
              <Divider
                label={`ACCEPTING ${singularPluralFormat(
                  selectedItemCount,
                  'PRODUCT',
                  'PRODUCTS'
                )}`}
              />
              <AppStack style={{ padding: 16, gap: 8 }}>
                {pendingProducts.map((p, index) => (
                  <React.Fragment key={p.pkEcomOrderProduct}>
                    {index > 0 && <Divider />}

                    <AppFlexbox style={{ gap: 16 }}>
                      <VendorOrderProductPreview
                        hideStatus
                        product={p}
                        quantityIndicatorAmount={p.amountRemaining}
                        showCancelWarnings
                      />
                    </AppFlexbox>
                  </React.Fragment>
                ))}
              </AppStack>
            </>
          )}
        </AppStack>
      ) : (
        <AppStack style={{ gap: 0 }}>
          {formState.showPreview ? (
            <>
              <AppStack style={{ padding: 16 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Are you sure you want to accept{' '}
                  {singularPluralFormat(
                    selectedPkEcomOrders.length,
                    'order',
                    'orders'
                  )}
                  ?
                </AppText>

                <AppText style={{ fontSize: 14 }}>
                  By proceeding, these orders will be marked as accepted, and no
                  further changes, including refunds, can be made by the
                  merchant. Any adjustments or refund requests will require the
                  merchant to contact you directly.
                </AppText>

                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This action cannot be undone.
                </AppText>
              </AppStack>

              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  fontStyle: 'italic',
                  color: '#666',
                  textAlign: 'center',
                  marginBottom: 16,
                  padding: '0px 24px'
                }}
              >
                Review the data below to ensure it is correct before accepting.
                Preview is limited to 50 results.
              </AppText>

              {selectedPreviewData && (
                <AppStack style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '8px 16px',
                      backgroundColor: 'rgba(56, 56, 56, 0.1)'
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                      <AppText style={{ fontWeight: 500 }}>
                        #{selectedPreviewData.pkEcomOrder} (
                        {previewState.previewIndex + 1}/
                        {previewState.ecomOrders.length})
                      </AppText>
                    </AppFlexbox>

                    {previewState.ecomOrders.length > 1 && (
                      <Button.Group>
                        <Button
                          color="dark"
                          onClick={() => {
                            const prevIndex = previewState.previewIndex - 1;
                            setPreviewState({
                              ...previewState,
                              previewIndex:
                                prevIndex < 0
                                  ? previewState.ecomOrders.length - 1
                                  : prevIndex
                            });
                          }}
                          size="compact-sm"
                          variant="outline"
                        >
                          <ArrowLeft size={18} />
                        </Button>
                        <Button
                          color="dark"
                          onClick={() => {
                            const nextIndex = previewState.previewIndex + 1;
                            setPreviewState({
                              ...previewState,
                              previewIndex:
                                nextIndex >= previewState.ecomOrders.length
                                  ? 0
                                  : nextIndex
                            });
                          }}
                          size="compact-sm"
                          variant="outline"
                        >
                          <ArrowRight size={18} />
                        </Button>
                      </Button.Group>
                    )}
                  </AppFlexbox>

                  <AppStack style={{ padding: 16, gap: 8 }}>
                    {[
                      ...selectedPreviewData.ecomOrderProducts,
                      ...selectedPreviewData.ecomOrderProductBulk.map((p) => ({
                        ...p.ecomOrderProduct,
                        pkEcomOrderProduct: `${p.pkEcomOrderProductBulk}-bulk`,
                        count: p.count,
                        uuid: p.ecomOrderProduct.vendorProductUuid
                      }))
                    ]
                      .map((p) => ({
                        key: p.pkEcomOrderProduct,
                        previewImage: p.previewImage,
                        name: p.productName,
                        uuid: p.vendorProductUuid,
                        sku: p.sku,
                        barcode: p.barcode,
                        unitCost: p.productCost + p.addonCost,
                        quantity: p.count - p.cancelledCount - p.returnedCount,
                        addOns: [
                          ...p.ecomVendorProductVariantOptions
                            .sort((a, b) => a.nameSort - b.nameSort)
                            .map((op) => ({
                              sort: op.nameSort,
                              name: op.name,
                              value: op.value
                            })),
                          ...p.ecomOrderProductInputAnswers
                            .map((a) => ({
                              sort: a?.sort,
                              name: a?.label,
                              value: a.value
                            }))
                            .sort((a, b) => a.sort - b.sort)
                        ]
                      }))
                      .filter((p) => p.quantity > 0)
                      .sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      .reduce((r, c) => {
                        const existing = r.find(
                          (p) =>
                            p.uuid === c.uuid &&
                            p.addOns.length === c.addOns.length &&
                            p.addOns.every(
                              (ao, i) =>
                                ao.name === c.addOns[i].name &&
                                ao.value === c.addOns[i].value
                            )
                        );
                        if (existing) {
                          existing.quantity += c.quantity;
                        }
                        else {
                          r.push(c);
                        }
                        return r;
                      }, [])
                      .map((p, index) => (
                        <React.Fragment key={p.key}>
                          {index > 0 && <Divider />}

                          <AppFlexbox style={{ gap: 16 }}>
                            <VendorOrderProductPreview
                              hideStatus
                              product={p}
                              quantityIndicatorAmount={p.quantity}
                              shopPrice
                            />
                          </AppFlexbox>
                        </React.Fragment>
                      ))}
                  </AppStack>
                </AppStack>
              )}
            </>
          ) : (
            <AppStack style={{ padding: 16 }}>
              {allPendingPkEcomOrders.length === 0 ? (
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    padding: '16px',
                    textAlign: 'center'
                  }}
                >
                  All orders have already been accepted.
                </AppText>
              ) : (
                <Radio.Group
                  label="Select the orders to accept"
                  onChange={(v) => {
                    setFormState({
                      ...formState,
                      selectAll: v === 'all'
                    });
                  }}
                  value={formState.selectAll ? 'all' : 'selected'}
                >
                  <AppStack style={{ gap: 10, marginTop: 10 }}>
                    <Radio
                      label={`All orders (${allPendingPkEcomOrders.length})`}
                      value="all"
                    />
                    <Radio
                      label={`${singularPluralFormat(
                        formState.pkEcomOrders.length,
                        'pending order',
                        'pending orders'
                      )} from ${pkEcomOrders.length} selected`}
                      value="selected"
                    />
                  </AppStack>
                </Radio.Group>
              )}
            </AppStack>
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

VendorOrderAcceptModal.propTypes = {
  ecomOrder: PropTypes.object,
  isBulkOrders: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  pkEcomOrders: PropTypes.array,
  selectAll: PropTypes.bool
};

export default VendorOrderAcceptModal;
