/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Image, NumberFormatter } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { isUrlString } from '../../../helpers/format';

const ProductLineItemAddonsDisplay = ({ addOns }) =>
  addOns &&
  addOns.length > 0 && (
    <AppStack style={{ gap: 0 }}>
      {addOns
        .sort((a, b) => a.sort - b.sort)
        .map((addOn) => (
          <AppFlexbox key={addOn.name + addOn.value} style={{ gap: 5 }}>
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <AppText style={{ fontSize: 14, color: '#666' }}>
                {addOn.name.charAt(0).toUpperCase() + addOn.name.slice(1)}:
              </AppText>
              {isUrlString(addOn.value) ? (
                <AppFlexbox
                  style={{
                    gap: 10,
                    marginLeft: 5,
                    alignItems: 'center '
                  }}
                >
                  <AppStack
                    style={{ width: 25, height: 25, justifyContent: 'center' }}
                  >
                    <Image fit="contain" h="100%" src={addOn.value} w="100%" />
                  </AppStack>
                  <Anchor
                    component="p"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(addOn.value, '_blank');
                    }}
                    style={{
                      fontSize: 14,
                      color: 'dodgerblue'
                    }}
                  >
                    Download image
                  </Anchor>
                </AppFlexbox>
              ) : (
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  {addOn.value}
                </AppText>
              )}
              {addOn.unitAmountInCents > 0 && (
                <AppFlexbox
                  style={{
                    gap: 5,
                    textAlign: 'end',
                    marginLeft: 10
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      fontWeight: 500
                    }}
                  >
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="+$"
                      thousandSeparator
                      value={addOn.unitAmountInCents / 100}
                    />
                  </AppText>
                </AppFlexbox>
              )}
            </AppFlexbox>
          </AppFlexbox>
        ))}
    </AppStack>
  );

ProductLineItemAddonsDisplay.propTypes = { addOns: PropTypes.array };

export default ProductLineItemAddonsDisplay;
