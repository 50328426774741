import React from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, Divider, NumberFormatter, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { ChevronDown } from 'tabler-icons-react';
import AppCard from '../../../../common/AppCard';
import AppStack from '../../../../common/AppStack';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppText from '../../../../common/AppText';
import CustomTooltip from '../../../../common/CustomTooltip';
import { formatUtcDate } from '../../../../../helpers/format';
import {
  CURRENCY_TYPE_LIST,
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  PAYMENT_TYPE_ENUM
} from '../../../../../config/constants';
import WsAccountOrderSummaryProduct from './WsAccountOrderSummaryProduct';
import * as classes from '../../../../../styles/nestedStyles.module.css';

const WsAccountOrderSummary = ({
  ecomOrderSummary,
  mediaQueries,
  baseStoreUrl,
  onNavigate
}) => {
  const { isTabletOrSmaller, getResponsiveStyle: rs } = mediaQueries;

  const firstPayment = ecomOrderSummary?.ecomOrderPayments.sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  )[0];

  const { refunded = 0, paid = 0 } =
    ecomOrderSummary?.ecomOrderPayments.reduce(
      (r, current) => {
        if (
          current.fkRegFormSubmissionPaymentType.toString() ===
          PAYMENT_TYPE_ENUM.REFUND
        ) {
          return {
            ...r,
            refunded: r.refunded + current.amount
          };
        }
        return {
          ...r,
          paid: r.paid + current.amount
        };
      },
      {
        refunded: 0,
        reimbursed: 0,
        paid: 0
      }
    ) ?? {};

  const { itemCount = 0, cancelledCount = 0, isFullyFulfilled = true } =
    ecomOrderSummary?.ecomOrderProducts.reduce(
      (r, current) => ({
        itemCount: r.itemCount + current.count,
        cancelledCount: r.cancelledCount + current.cancelledCount,
        isFullyFulfilled: current.isBulkShipment
          ? r.isFullyFulfilled
          : r.isFullyFulfilled &&
            current.fkEcomOrderProductStatus.toString() !==
              ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
            current.fkEcomOrderProductStatus.toString() !==
              ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
      }),
      {
        itemCount: 0,
        cancelledCount: 0,
        isFullyFulfilled: true
      }
    ) ?? {};

  return ecomOrderSummary ? (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: 16,
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs([
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row'
            ])
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 10, 10, 40]),
              flex: rs([1, 1, 1, 'unset']),
              alignSelf: rs(['stretch', 'stretch', 'stretch', 'unset']),
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>ORDER PLACED</AppText>
              <AppText style={{ fontSize: 16 }} weight={700}>
                {firstPayment
                  ? dayjs(formatUtcDate(firstPayment.createdAt)).format(
                      'MMM D, YYYY'
                    )
                  : 'N/A'}
              </AppText>
            </AppStack>
            <AppStack
              style={{ gap: 0, alignItems: rs(['end', 'end', 'end', 'start']) }}
            >
              <AppText style={{ fontSize: 14 }}>TOTAL</AppText>
              <AppFlexbox style={{ gap: 10 }}>
                <AppText style={{ fontSize: 16 }} weight={700}>
                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={paid / 100}
                  />
                </AppText>
                <AppText style={{ fontSize: 16 }} weight={700}>
                  {
                    CURRENCY_TYPE_LIST.find(
                      (c) =>
                        c.value.toString() ===
                        firstPayment.fkCurrencyType.toString()
                    )?.abbr
                  }
                </AppText>
              </AppFlexbox>
            </AppStack>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>SHIP TO</AppText>
              <CustomTooltip
                label={
                  ecomOrderSummary.ecomCustomerAddressShipping && (
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                        {ecomOrderSummary.ecomCustomerAddressShipping.fullName}
                      </AppText>
                      <AppText style={{ fontSize: 14 }}>
                        {ecomOrderSummary.ecomCustomerAddressShipping.address1}
                      </AppText>
                      <AppText style={{ fontSize: 14 }}>
                        {ecomOrderSummary.ecomCustomerAddressShipping.city},{' '}
                        {ecomOrderSummary.ecomCustomerAddressShipping.state}{' '}
                        {
                          ecomOrderSummary.ecomCustomerAddressShipping
                            .postalCode
                        }
                      </AppText>
                      <AppText style={{ fontSize: 14 }}>
                        {ecomOrderSummary.ecomCustomerAddressShipping.country}
                      </AppText>
                    </AppStack>
                  )
                }
              >
                <AppFlexbox
                  className={classes['hover-text-action']}
                  style={{
                    gap: 5,
                    alignItems: 'center',
                    color: 'dodgerblue',
                    fontWeight: 700
                  }}
                >
                  <AppText style={{ fontSize: 16 }}>
                    {ecomOrderSummary.ecomCustomerAddressShipping?.fullName}{' '}
                  </AppText>
                  <ChevronDown color="#FFF" size={20} />
                </AppFlexbox>
              </CustomTooltip>
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'row', 'row', 'column'])
            }}
          >
            <AppText style={{ fontSize: 14 }} weight={500}>
              ORDER #{ecomOrderSummary.pkEcomOrder}
            </AppText>
            <Anchor
              component={Link}
              onClick={onNavigate}
              style={{
                fontSize: 16,
                color: 'dodgerblue',
                textAlign: 'end',
                fontWeight: 700
              }}
              to={`${baseStoreUrl}/account/orders/${ecomOrderSummary.pkEcomOrder}`}
            >
              View order details
            </Anchor>
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ gap: 0 }}>
          {ecomOrderSummary.ecomOrderProducts.map((p, index) => (
            <React.Fragment key={p.pkEcomOrderProduct}>
              {index > 0 && <Divider />}
              <AppStack style={{ padding: 16 }}>
                <WsAccountOrderSummaryProduct
                  addOns={[
                    ...p.ecomVendorProductVariantOptions.map((op) => ({
                      sort: op.nameSort,
                      name: op.name,
                      value: op.value,
                      unitAmountInCents: 0
                    })),
                    ...p.ecomOrderProductInputAnswers.map((op) => ({
                      sort: op.sort + p.ecomVendorProductVariantOptions.length,
                      name: op.label,
                      value: op.value,
                      unitAmountInCents: op.adjustedCost
                    }))
                  ]}
                  baseStoreUrl={baseStoreUrl}
                  cancelledCount={p.cancelledCount}
                  count={p.count}
                  ecomOrderProductSummary={p}
                  ecomOrderSummary={ecomOrderSummary}
                  hidePrice
                  mediaQueries={mediaQueries}
                  onNavigate={onNavigate}
                  previewImage={p.previewImage}
                  productName={p.productName}
                  shippingCount={p.shippingCount}
                  trackingUrl={`${baseStoreUrl}/account/orders/${ecomOrderSummary.pkEcomOrder}?tracking=${p.pkEcomOrderProduct}`}
                  unitPrice={p.productCost + p.addonCost}
                  urlHandle={p.urlHandle}
                />
              </AppStack>
            </React.Fragment>
          ))}

          {ecomOrderSummary.ecomOrderProducts.length > 5 && (
            <AppText
              component={Link}
              onClick={onNavigate}
              style={{
                fontSize: 20,
                textDecoration: 'none',
                color: 'dodgerblue'
              }}
              to={`/orders/${ecomOrderSummary.pkEcomOrder}`}
              weight={500}
            >
              See All {ecomOrderSummary.ecomOrderProducts.length} items
            </AppText>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0 }}
      withBorder
    >
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppFlexbox
          style={{
            flex: 1,
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: 16,
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs([
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row'
            ])
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 10, 10, 40]),
              flex: 1,
              alignSelf: rs(['stretch', 'stretch', 'stretch', 'unset']),
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <AppStack style={{ flex: 1, gap: 10 }}>
              <Skeleton height={16} width="85%" />
              <Skeleton height={16} width="85%" />
            </AppStack>

            <AppStack
              style={{
                flex: 1,
                gap: 10,
                alignItems: rs(['end', 'end', 'end', 'start'])
              }}
            >
              <Skeleton height={16} width={80} />

              <Skeleton height={16} width={80} />
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              gap: 10,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'row', 'row', 'column'])
            }}
          >
            <Skeleton height={18} width="45%" />
            <Skeleton height={18} width="45%" />
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ padding: 16, gap: 20 }}>
          <WsAccountOrderSummaryProduct
            baseStoreUrl={baseStoreUrl}
            loading
            mediaQueries={mediaQueries}
            onNavigate={onNavigate}
          />
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

WsAccountOrderSummary.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomOrderSummary: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func
};

export default WsAccountOrderSummary;
