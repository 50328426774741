import React from 'react';
import { TruckDelivery, User, Users } from 'tabler-icons-react';

const NOTIFICATION_SECTION_ENUM = {
  CUSTOMER_NOTIFICATIONS: 'CUSTOMER_NOTIFICATIONS',
  STAFF_NOTIFICATIONS: 'STAFF_NOTIFICATIONS',
  VENDOR_NOTIFICATIONS: 'VENDOR_NOTIFICATIONS'
};

const NOTIFICATION_SECTIONS = [
  {
    label: 'Customer notifications',
    value: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    icon: <User size={18} />,
    path: 'customer'
  },
  {
    label: 'Staff notifications',
    value: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    icon: <Users size={18} />,
    path: 'staff'
  },
  {
    label: 'Vendor notifications',
    value: NOTIFICATION_SECTION_ENUM.VENDOR_NOTIFICATIONS,
    icon: <TruckDelivery size={18} />,
    path: 'vendor'
  }
];

const NOTIFICATION_ENUM = {
  // CUSTOMER NOTIFICATIONS
  ORDER_CONFIRMATION: 'order_confirmation',
  SHIPPING_CONFIRMATION: 'shipping_confirmation',
  ORDER_EDITED: 'order_edited',
  ORDER_CANCELLED: 'order_cancelled',
  ORDER_REFUNDED: 'order_refunded',

  // STAFF NOTIFICATIONS
  ESCROW_CLAIMED: 'escrow_claimed',
  ESCROW_AVAILABLE: 'escrow_available',
  ORDER_RECEIVED: 'order_received',
  ORDER_CREDITED: 'order_credited',

  // VENDOR NOTIFICATIONS
  FULFILLMENT_REQUEST: 'fulfillment_request',
  VENDOR_ORDER_RECEIVED: 'vendor_order_received',
  VENDOR_ORDER_CANCELLED: 'vendor_order_cancelled'
};

const NOTIFICATIONS = [
  // CUSTOMER NOTIFICATIONS
  {
    label: 'Order confirmation',
    description: 'Sent to the customer when an order is placed.',
    value: NOTIFICATION_ENUM.ORDER_CONFIRMATION,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'order-confirmation',
    group: 'Order processing'
  },
  {
    label: 'Shipping confirmation',
    description: 'Sent to the customer when an order has shipped.',
    value: NOTIFICATION_ENUM.SHIPPING_CONFIRMATION,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'shipping-confirmation',
    group: 'Order processing'
  },
  {
    label: 'Order cancelled',
    description:
      'Sent to the customer when the order or any products are cancelled.',
    value: NOTIFICATION_ENUM.ORDER_CANCELLED,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'order-cancelled',
    group: 'Order changes'
  },
  {
    label: 'Order refunded',
    description: 'Sent to the customer when an order is refunded.',
    value: NOTIFICATION_ENUM.ORDER_REFUNDED,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'order-refunded',
    group: 'Order changes'
  },

  // STAFF NOTIFICATIONS
  {
    label: 'Order received',
    description: 'Sent to staff when an order is received.',
    value: NOTIFICATION_ENUM.ORDER_RECEIVED,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'order-received',
    group: 'Order processing'
  },
  {
    label: 'Order credited',
    description:
      'Sent to staff when an order is credited (e.g. for a vendor refund).',
    value: NOTIFICATION_ENUM.ORDER_CREDITED,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'order-credited',
    group: 'Order changes'
  },
  {
    label: 'Escrow available',
    description: 'Sent to staff when escrow is available to be claimed.',
    value: NOTIFICATION_ENUM.ESCROW_AVAILABLE,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'escrow-available',
    group: 'Escrow'
  },
  {
    label: 'Escrow claimed',
    description: 'Sent to staff when escrow is claimed.',
    value: NOTIFICATION_ENUM.ESCROW_CLAIMED,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'escrow-claimed',
    group: 'Escrow'
  },

  // VENDOR NOTIFICATIONS
  {
    label: 'Order received',
    description: 'Sent to the vendor when an order is received.',
    value: NOTIFICATION_ENUM.VENDOR_ORDER_RECEIVED,
    section: NOTIFICATION_SECTION_ENUM.VENDOR_NOTIFICATIONS,
    path: 'vendor-order-received',
    group: 'Order processing'
  },
  {
    label: 'Order cancelled',
    description: 'Sent to the vendor when an order or product is cancelled.',
    value: NOTIFICATION_ENUM.VENDOR_ORDER_CANCELLED,
    section: NOTIFICATION_SECTION_ENUM.VENDOR_NOTIFICATIONS,
    path: 'vendor-order-cancelled',
    group: 'Order processing'
  },
  {
    label: 'Fulfillment request',
    description: 'Sent when reminding a vendor to fulfill an order.',
    value: NOTIFICATION_ENUM.FULFILLMENT_REQUEST,
    section: NOTIFICATION_SECTION_ENUM.VENDOR_NOTIFICATIONS,
    path: 'fulfillment-request',
    group: 'Reminders'
  }
];

export { NOTIFICATION_SECTION_ENUM, NOTIFICATION_SECTIONS, NOTIFICATIONS };
