import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import {
  Anchor,
  Badge,
  Button,
  Checkbox,
  Divider,
  NumberInput,
  Skeleton
} from '@mantine/core';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import OrderHelpModal, { ORDER_FULFILLMENT_TYPE_ENUM } from './OrderHelpModal';
import { triggerNotification } from '../../../helpers/notification';
import {
  generateTrackingLink,
  translateVendorOrderData
} from '../../../helpers/vendorHelper';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import EditTrackingDetailsFormSection from './EditTrackingDetailsFormSection';
import VendorOrderFulfillmentConfirmationModal from './VendorOrderFulfillmentConfirmationModal';
import { WEIGHT_UNIT_LIST } from '../../../config/constants';

const VendorOrderFulfillmentView = ({ ecomOrder, loading }) => {
  const navigate = useNavigate();
  const { pkEcomOrder } = useParams();
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const {
    fetchVendorOrder,
    fetchVendorSetupSummary,
    fulfillEcomVendorOrderProducts
  } = useContext(VendorContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [formState, setFormState] = useState({
    trackingNumber: '',
    trackingUrl: '',
    shippingCarrier: '',
    notifyCustomer: true,
    additionalShipping: [],
    ecomOrderShippingProducts: [],
    loading: false,
    error: null
  });

  const { ecomOrderProducts: productData } = translateVendorOrderData(
    ecomOrder
  );

  const productsNeedingFulfillment = productData.filter(
    (f) => f.amountRemaining > 0
  );

  const itemCount =
    productsNeedingFulfillment.reduce((r, c) => r + c.amountRemaining, 0) ?? 0;
  const itemCountSelected =
    formState.ecomOrderShippingProducts.length > 0
      ? formState.ecomOrderShippingProducts.reduce((r, c) => r + c.count, 0)
      : itemCount;

  const trackingLink = generateTrackingLink(
    formState.shippingCarrier,
    formState.trackingNumber,
    formState.trackingUrl
  );
  const confirmFulfillmentModalOpen =
    modalState.isOpen && modalState.action === 'confirm';

  useEffect(() => {
    if (!loading && ecomOrder) {
      if (productsNeedingFulfillment.length === 0) {
        navigate(`/vendor/orders/${ecomOrder.pkEcomOrder}`);
      }

      setFormState({
        trackingNumber: '',
        trackingUrl: '',
        shippingCarrier: '',
        notifyCustomer: true,
        additionalShipping: [],
        ecomOrderShippingProducts: productsNeedingFulfillment.map((p) => ({
          isBulkShipment: !!p.pkEcomOrderProductBulk,
          fkEcomOrderProductBulk: p.pkEcomOrderProductBulk,
          fkEcomOrderProduct: p.pkEcomOrderProduct,
          count: p.amountRemaining
        })),
        loading: false,
        error: null
      });
    }
  }, [ecomOrder, loading]);

  const onSubmitFulfillment = () => {
    const hasTrackingNumberButNoCarrier =
      (!!formState.trackingNumber && !formState.shippingCarrier) ||
      formState.additionalShipping.some(
        (as) => !!as.trackingNumber && !as.shippingCarrier
      );
    if (hasTrackingNumberButNoCarrier) {
      triggerNotification(
        'Please select a shipping carrier for all tracking numbers'
      );
    }
    else {
      setFormState({
        ...formState,
        loading: true
      });
      const shippingProducts = formState.ecomOrderShippingProducts.filter(
        (p) => p.count > 0
      );

      fulfillEcomVendorOrderProducts(
        authState.pkEcomVendor,
        [
          {
            fkEcomOrder: ecomOrder.pkEcomOrder,
            ...formState,
            ecomOrderShippingProducts: shippingProducts,
            trackingUrl: generateTrackingLink(
              formState.shippingCarrier,
              formState.trackingNumber,
              formState.trackingUrl
            )
          },
          ...formState.additionalShipping.map((as) => ({
            fkEcomOrder: ecomOrder.pkEcomOrder,
            ...as,
            notifyCustomer: formState.notifyCustomer,
            ecomOrderShippingProducts: shippingProducts.map((p) => ({
              ...p,
              count: 0
            })),
            trackingUrl: generateTrackingLink(
              as.shippingCarrier,
              as.trackingNumber,
              as.trackingUrl
            )
          }))
        ],
        () => {
          triggerNotification('Order fulfilled', 'success');
          navigate(`/vendor/orders/${ecomOrder.pkEcomOrder}`);
          fetchVendorOrder(authState.pkEcomVendor, { pkEcomOrder });
          fetchVendorSetupSummary(authState.pkEcomVendor, {
            skipLoading: true
          });
        },
        (e) => {
          triggerNotification(e);
          setFormState({
            ...formState,
            loading: false
          });
        }
      );
    }
  };

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <OrderHelpModal
        isOpen={modalState.isOpen && modalState.action === 'helpModal'}
        onClose={onCloseModal}
        type={ORDER_FULFILLMENT_TYPE_ENUM.FULFILLMENT}
      />

      <VendorOrderFulfillmentConfirmationModal
        formState={formState}
        isOpen={confirmFulfillmentModalOpen}
        onClose={onCloseModal}
        onSubmit={onSubmitFulfillment}
        productData={productData}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/vendor/orders/${ecomOrder.pkEcomOrder}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppText
              style={{
                flex: 1,
                fontSize: 24,
                fontWeight: 700,
                wordBreak: 'break-word'
              }}
            >
              Fulfill {itemCount === 1 ? 'item' : 'items'}
            </AppText>
          </AppStack>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpenModal('confirm');
        }}
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox
                style={{
                  gap: 12,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    #{ecomOrder.pkEcomOrder}
                  </AppText>
                  <Badge color="orange" variant="filled">
                    Unfulfilled
                  </Badge>
                </AppFlexbox>
              </AppFlexbox>

              <AppCard
                radius={8}
                shadow="none"
                style={{ padding: 0 }}
                withBorder
              >
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox style={{ padding: 8 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {ecomOrder.ecomCustomer.user?.name ||
                        ecomOrder.ecomCustomer.email}
                    </AppText>
                  </AppFlexbox>
                  <Divider />
                  {productsNeedingFulfillment.map((p, index) => {
                    const productRequest = formState.ecomOrderShippingProducts.find(
                      (pr) =>
                        pr.isBulkShipment
                          ? pr.fkEcomOrderProductBulk ===
                            p.pkEcomOrderProductBulk
                          : pr.fkEcomOrderProduct === p.pkEcomOrderProduct
                    );

                    return (
                      <React.Fragment key={p.key}>
                        {index > 0 && <Divider />}

                        <AppFlexbox
                          style={{
                            gap: 8,
                            padding: 8,
                            justifyContent: 'space-between'
                          }}
                        >
                          <VendorOrderProductPreview product={p} />

                          <AppStack>
                            <AppFlexbox
                              style={{
                                alignItems: 'center',
                                justifyContent: 'end',
                                gap: 8
                              }}
                            >
                              {p.weight && p.weightUnit && (
                                <AppText
                                  style={{ fontSize: 14, fontWeight: 500 }}
                                >
                                  {p.weight}{' '}
                                  {
                                    WEIGHT_UNIT_LIST.find(
                                      (u) =>
                                        u.value.toString() ===
                                        p.weightUnit.toString()
                                    )?.label
                                  }
                                </AppText>
                              )}

                              <NumberInput
                                disabled={
                                  formState.loading ||
                                  confirmFulfillmentModalOpen
                                }
                                max={p.amountRemaining}
                                min={0}
                                onChange={(v) =>
                                  setFormState({
                                    ...formState,
                                    ecomOrderShippingProducts: formState.ecomOrderShippingProducts.map(
                                      (pr) => {
                                        if (
                                          pr.fkEcomOrderProduct ===
                                          p.pkEcomOrderProduct
                                        ) {
                                          return {
                                            ...pr,
                                            count: v
                                          };
                                        }
                                        return pr;
                                      }
                                    )
                                  })
                                }
                                rightSection={
                                  <AppFlexbox>
                                    <AppText
                                      style={{ fontSize: 15, color: '#999' }}
                                    >
                                      of {p.amountRemaining}
                                    </AppText>
                                  </AppFlexbox>
                                }
                                rightSectionWidth="40%"
                                style={{ flex: 1, maxWidth: 100 }}
                                value={productRequest?.count}
                              />
                            </AppFlexbox>
                          </AppStack>
                        </AppFlexbox>
                      </React.Fragment>
                    );
                  })}
                </AppStack>
              </AppCard>
            </AppStack>
            <Divider />

            <AppStack style={{ gap: 8, padding: 16 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Tracking information
              </AppText>
              <EditTrackingDetailsFormSection
                disabled={confirmFulfillmentModalOpen}
                formState={formState}
                setFormState={setFormState}
              />
            </AppStack>

            <Divider />
            <AppStack style={{ padding: 16 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Notify the customer of shipment details
              </AppText>

              <Checkbox
                checked={formState.notifyCustomer}
                disabled={formState.loading}
                label="Send shipment details to the customer now"
                onChange={() =>
                  setFormState({
                    ...formState,
                    notifyCustomer: !formState.notifyCustomer
                  })
                }
              />
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/vendor/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            fulfillmentPreview
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
          />

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <AppStack style={{ flex: 1, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Summary
                    </AppText>
                  </AppFlexbox>
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14 }}>
                      {itemCountSelected} of{' '}
                      {singularPluralFormat(itemCount, 'item', 'items')}
                    </AppText>
                    {trackingLink ? (
                      <AppFlexbox>
                        <Anchor
                          component="a"
                          href={trackingLink}
                          style={{ fontSize: 14, color: 'dodgerblue' }}
                          target="_blank"
                        >
                          View tracking
                        </Anchor>
                      </AppFlexbox>
                    ) : (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Tracking not available
                      </AppText>
                    )}
                    {formState.additionalShipping
                      .map((a) =>
                        generateTrackingLink(
                          a.shippingCarrier,
                          a.trackingNumber,
                          a.trackingUrl
                        )
                      )
                      .filter((f) => !!f)
                      .map((t, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <AppFlexbox key={index}>
                          <Anchor
                            component="a"
                            href={t}
                            style={{ fontSize: 14, color: 'dodgerblue' }}
                            target="_blank"
                          >
                            View tracking #{index + 2}
                          </Anchor>
                        </AppFlexbox>
                      ))}
                  </AppStack>
                </AppStack>
              </AppStack>
              <Divider />
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <Button
                  color="dark"
                  disabled={itemCountSelected === 0}
                  loading={formState.loading && !confirmFulfillmentModalOpen}
                  radius="md"
                  type="submit"
                  variant="filled"
                >
                  Fulfill {itemCountSelected === 1 ? 'item' : 'items'}
                </Button>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      {/* <AppFlexbox style={{ justifyContent: 'center', padding: 16 }}>
        <AppText style={{ fontSize: 14 }}>
          Learn about{' '}
          <Anchor
            onClick={() => onOpenModal('helpModal')}
            style={{ color: 'dodgerblue', fontSize: 14 }}
          >
            order fulfillment
          </Anchor>
          .
        </AppText>
      </AppFlexbox> */}
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={`/vendor/orders/${pkEcomOrder}`}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard fulfillmentPreview loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

VendorOrderFulfillmentView.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default VendorOrderFulfillmentView;
