import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, NumberFormatter, Skeleton, Tooltip } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ResponsiveModal from '../../common/ResponsiveModal';

const OrderPaymentInfoCard = ({ payments, actionMenu, loading }) => {
  const [infoModalState, setInfoModalState] = useState({
    isOpen: false,
    title: '',
    info: ''
  });

  const paymentsGrouped =
    payments?.reduce((r, c) => {
      const existing = r.find((g) => g.group === c.group);
      if (existing) {
        existing.payments.push(c);
      }
      else {
        r.push({ group: c.group, payments: [c] });
      }
      return r;
    }, []) ?? [];

  return !loading ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <ResponsiveModal
        formSectionProps={{
          cancelTitle: 'Close',
          onCancel: () =>
            setInfoModalState({ ...infoModalState, isOpen: false }),
          isSubmitHidden: true
        }}
        isOpen={infoModalState.isOpen}
        onClose={() => setInfoModalState({ ...infoModalState, isOpen: false })}
        size={500}
        title={infoModalState.title || 'Details'}
      >
        {infoModalState.info}
      </ResponsiveModal>

      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>Payment</AppText>
          {actionMenu}
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            {paymentsGrouped.map((g, index) => (
              <React.Fragment key={g.group}>
                {index > 0 && <Divider />}

                <AppStack style={{ padding: 16, gap: 8 }}>
                  {g.payments.map((p) => (
                    <AppFlexbox
                      key={p.key}
                      style={{
                        gap: 8,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppFlexbox
                        key={p.key}
                        style={{ gap: 8, alignItems: 'center', flex: 1 }}
                      >
                        <Tooltip
                          disabled={!p.tooltip}
                          label={p.tooltip}
                          multiline
                          onClick={() => {
                            if (p.infoContent) {
                              setInfoModalState({
                                isOpen: true,
                                title: p.infoTitle,
                                info: p.infoContent
                              });
                            }
                          }}
                          w={250}
                          withArrow
                        >
                          <AppFlexbox
                            style={{
                              gap: 8,
                              alignItems: 'center',
                              cursor: p.infoContent ? 'pointer' : 'default'
                            }}
                          >
                            <AppText
                              style={{
                                fontSize: 14,
                                color: p.color ?? '#000',
                                fontWeight: p.weight ?? 'normal',
                                flex: 1,
                                width: '100%',
                                maxWidth: 150
                              }}
                            >
                              {p.label}
                            </AppText>
                            {p.tooltip &&
                              (p.tooltipIcon || (
                                <InfoCircle color="dodgerblue" size={18} />
                              ))}
                          </AppFlexbox>
                        </Tooltip>

                        {p.description && (
                          <AppText
                            style={{
                              fontSize: 14,
                              color: p.color ?? '#000',
                              fontWeight: p.weight ?? 'normal',
                              flex: 1
                            }}
                          >
                            {p.description}
                          </AppText>
                        )}
                      </AppFlexbox>
                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix={p.prefix || '$'}
                        style={{
                          fontSize: 14,
                          color: p.valueColor ?? p.color ?? '#000',
                          fontWeight: p.weight ?? 'normal'
                        }}
                        thousandSeparator
                        value={p.value}
                      />
                    </AppFlexbox>
                  ))}
                </AppStack>
              </React.Fragment>
            ))}
          </AppStack>
        </AppCard>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Skeleton height={22} width="20%" />
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>

            <Divider />
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
    </AppCard>
  );
};

OrderPaymentInfoCard.propTypes = {
  actionMenu: PropTypes.node,
  loading: PropTypes.bool,
  payments: PropTypes.array
};

export default OrderPaymentInfoCard;
