import { useLocation } from 'react-router-dom';

const getAdminBackPath = (defaultBack = '/admin') => {
  const { search } = useLocation();
  const fromLocations =
    new URLSearchParams(search).get('from')?.split(',') || [];

  const firstFrom = fromLocations[0]?.toLocaleLowerCase();
  const params =
    fromLocations.length > 1 ? `?from=${fromLocations.slice(1).join(',')}` : [];

  switch (firstFrom) {
    case 'analytics':
      return `/admin${params}`;
    case 'payout-balance':
      return `/admin/vendors/payout-balance${params}`;
    case 'vendors':
      return `/admin/vendors${params}`;
    default:
      return defaultBack;
  }
};

export { getAdminBackPath };
