import React, { useEffect } from 'react';
import { Divider, Loader } from '@mantine/core';
import { AlertCircle, ArrowLeft, ChevronRight } from 'tabler-icons-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import EmailNotificationPreview from './EmailNotificationPreview';
import ActionableIcon from '../../common/ActionableIcon';
import AppStack from '../../common/AppStack';
import * as classes from '../../../styles/nestedStyles.module.css';

const NotificationView = ({
  loading,
  emailNotifications,
  notificationSectionConfigs,
  notificationConfigs,
  urlBase
}) => {
  const navigate = useNavigate();
  const { sectionId, notificationId } = useParams();
  const { isTabletOrSmaller } = useMediaQueryIndex();

  const selectedSection = notificationSectionConfigs.find(
    (s) => s.path === sectionId
  );
  const sectionNotifications = notificationConfigs.filter(
    (n) => n.section === selectedSection.value
  );
  const groupedNotifications = sectionNotifications.reduce((r, c) => {
    const existing = r.find((e) => e.label === c.group);
    if (existing) {
      existing.notifications.push(c);
    }
    else {
      r.push({ label: c.group, notifications: [c] });
    }
    return r;
  }, []);

  const selectedNotificationConfig = notificationConfigs.find(
    (n) => n.path === notificationId
  );
  const storeNotification = emailNotifications?.find(
    (n) => n.uuid === selectedNotificationConfig?.value
  );

  useEffect(() => {
    if (!selectedSection) {
      navigate(`${urlBase}/${notificationSectionConfigs[0].path}`);
    }
    else if (notificationId && !storeNotification && !loading) {
      navigate(`${urlBase}/${sectionId}`);
    }
  }, [sectionId, notificationId, loading]);

  return (
    <>
      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
        {notificationId && (
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/merchant/notifications/${sectionId}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
        )}
        <AppText
          style={{
            flex: 1,
            fontSize: 22,
            fontWeight: 700,
            wordBreak: 'break-word'
          }}
        >
          {selectedNotificationConfig?.label || selectedSection?.label}
        </AppText>
      </AppFlexbox>

      {selectedNotificationConfig?.warnings?.length > 0 &&
        selectedNotificationConfig.warnings.map((e) => (
          <AppCard
            key={e.message}
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 8 }}
            withBorder
          >
            <AppFlexbox style={{ gap: 8 }}>
              <AppStack style={{ marginTop: 2 }}>
                <AlertCircle color={e.color || '#eca70a'} size={18} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>{e.message}</AppText>
            </AppFlexbox>
          </AppCard>
        ))}

      {!notificationId ? (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="sm"
          style={{ padding: 12 }}
          withBorder
        >
          <AppStack style={{ gap: 12 }}>
            {groupedNotifications.map((group) => (
              <AppCard
                key={group.label}
                radius={isTabletOrSmaller ? 0 : 'md'}
                shadow="sm"
                style={{ padding: 0 }}
                withBorder
              >
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{ padding: 12, backgroundColor: '#F1F1F1' }}
                  >
                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#000',
                        fontWeight: 500
                      }}
                    >
                      {group.label}
                    </AppText>
                  </AppFlexbox>
                  <Divider />
                  {group.notifications.map((notification, index) => (
                    <React.Fragment key={notification.value}>
                      {index > 0 && <Divider />}
                      <AppFlexbox
                        className={classes['hover-action-card']}
                        component={Link}
                        style={{
                          padding: 12,
                          color: '#666',
                          textDecoration: 'none',
                          boxSizing: 'border-box',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                        to={`${urlBase}/${sectionId}/${notification.path}`}
                      >
                        <AppStack style={{ gap: 0 }}>
                          <AppText
                            style={{
                              fontSize: 14,
                              color: '#000'
                            }}
                          >
                            {notification.label}
                          </AppText>
                          {notification.description && (
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              {notification.description}
                            </AppText>
                          )}
                        </AppStack>

                        <ChevronRight color="#666" size={18} />
                      </AppFlexbox>
                    </React.Fragment>
                  ))}
                </AppStack>
              </AppCard>
            ))}
          </AppStack>
        </AppCard>
      ) : (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="sm"
          style={{ padding: 0 }}
          withBorder
        >
          {loading ? (
            <AppFlexbox
              style={{ padding: '60px 40px', justifyContent: 'center' }}
            >
              <Loader color="dark" size={45} />
            </AppFlexbox>
          ) : (
            storeNotification && (
              <EmailNotificationPreview
                emailContent={storeNotification.emailContent}
                subject={storeNotification.subject}
              />
            )
          )}
        </AppCard>
      )}
    </>
  );
};

NotificationView.propTypes = {
  emailNotifications: PropTypes.array,
  loading: PropTypes.bool,
  notificationConfigs: PropTypes.array,
  notificationSectionConfigs: PropTypes.array,
  urlBase: PropTypes.string
};

export default NotificationView;
