import React, { useContext, useEffect, useRef } from 'react';
import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Image,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import {
  AlertCircle,
  BucketDroplet,
  Check,
  ChevronRight,
  CurrencyDollar,
  Edit,
  EyeCheck,
  EyeOff,
  Pencil,
  PhotoPlus,
  Send,
  Trash,
  World
} from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import PaginationList from '../../common/PaginationList';
import {
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST
} from '../../../config/constants';
import {
  useGlobalFormState,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import { triggerNotification } from '../../../helpers/notification';
import MediaDropzone from '../media/MediaDropzone';
import SettingCard from '../../common/SettingCard';
import CustomColorInput from '../../common/CustomColorInput';
import SettingSelector from '../../common/SettingSelector';
import ConfirmModal from '../../common/ConfirmModal';
import LaunchVendorModal from './LaunchVendorModal';
import AssignVendorProvinceModal from './AssignVendorProvinceModal';
import AppCard from '../../common/AppCard';
import { sterilizeUrlHandle } from '../../../helpers/format';
import ChangeCurrencyModal from '../../common/ChangeCurrencyModal';
import { LOCATION_LIST_OPTIONS } from '../../../config/locationConstants';
import CustomInputModal from '../../common/CustomInputModal';
import OnboardVendorPaymentProviderModal from './OnboardVendorPaymentProviderModal';

const MODAL_ACTION_ENUM = {
  UPLOAD_LOGO: 'UPLOAD_LOGO',
  UPLOAD_BANNER: 'UPLOAD_BANNER',
  EDIT_PROVINCES: 'EDIT_PROVINCES',
  SANDBOX_OFF: 'SANDBOX_OFF',
  SANDBOX_ON: 'SANDBOX_ON',
  CONNECT_PAYMENT_PROVIDER: 'CONNECT_PAYMENT_PROVIDER',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY'
};

const VendorSettingsView = () => {
  const navigate = useNavigate();
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { search } = useLocation();
  const modalAction = new URLSearchParams(search).get('modal');
  const fetchedPkEcomVendor = useRef(false);
  const { state: authState, fetchEcommerceAdminData } = useContext(AuthContext);
  const {
    state,
    fetchVendor,
    updateVendor,
    fetchVendorSetupSummary
  } = useContext(VendorContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      name: '',
      domain: '',
      supportEmail: '',
      description: '',
      logoImageUrl: '',
      bannerImageUrl: '',
      primaryColor: '',
      primaryContrastColor: '#FFF',
      secondaryColor: '',
      secondaryAltColor: '',
      secondaryContrastColor: '#FFF',
      urlHandle: '',
      provinces: [],
      fkEcomCurrency: CURRENCY_TYPE_ENUM.CAD,
      sandboxEnabled: false
    },
    { confirmDiscard: true, containerWidth: 1100 }
  );
  const loading = !fetchedPkEcomVendor.current || state.ecomVendor.loading;
  const ecomVendor =
    fetchedPkEcomVendor.current ===
    state.ecomVendor.value?.pkEcomVendor.toString()
      ? state.ecomVendor.value
      : null;
  const { value: vendorSummary } = state.ecomVendorSetupSummary;

  const selectedCurrency =
    CURRENCY_TYPE_LIST.find(
      (currency) => currency.value === formState.fkCurrencyType
    ) || CURRENCY_TYPE_LIST[0];

  useEffect(() => {
    if (
      authState.pkEcomVendor &&
      fetchedPkEcomVendor.current !== authState.pkEcomVendor
    ) {
      fetchVendor(authState.pkEcomVendor, null, (e) => triggerNotification(e));
      fetchedPkEcomVendor.current = authState.pkEcomVendor;
    }
  }, [authState.pkEcomVendor]);

  useEffect(() => {
    if (
      ecomVendor &&
      vendorSummary &&
      fetchedPkEcomVendor.current === authState.pkEcomVendor &&
      !loading
    ) {
      resetFormState({
        name: ecomVendor.name,
        domain: ecomVendor.domain,
        supportEmail: ecomVendor.supportEmail,
        description: ecomVendor.description,
        logoImageUrl: ecomVendor.logoImageUrl,
        bannerImageUrl: ecomVendor.bannerImageUrl,
        primaryColor: ecomVendor.primaryColor,
        primaryContrastColor: ecomVendor.primaryContrastColor ?? '#FFF',
        secondaryColor: ecomVendor.secondaryColor,
        secondaryAltColor: ecomVendor.secondaryAltColor,
        secondaryContrastColor: ecomVendor.secondaryContrastColor ?? '#FFF',
        urlHandle: ecomVendor.urlHandle,
        fkCurrencyType: ecomVendor.fkCurrencyType,
        sandboxEnabled: ecomVendor.sandboxEnabled,
        provinces: ecomVendor.ecomVendorProvinces
      });
    }
  }, [ecomVendor, vendorSummary, loading]);

  useEffect(() => {
    if (!loading && modalAction) {
      switch (modalAction) {
        case 'logo':
          onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO);
          break;
        case 'provinces':
          onOpenModal(MODAL_ACTION_ENUM.EDIT_PROVINCES);
          break;
        case 'sandbox':
          onOpenModal(
            ecomVendor && !ecomVendor.sandboxEnabled
              ? MODAL_ACTION_ENUM.SANDBOX_ON
              : MODAL_ACTION_ENUM.SANDBOX_OFF
          );
          break;
        default:
          break;
      }
    }
  }, [modalAction, loading]);

  const onSaveVendorSettings = () => {
    submitFormState((formData, onErrorCallback) =>
      updateVendor(
        ecomVendor.pkEcomVendor,
        formData,
        (data) => {
          triggerNotification('Vendor updated!', 'success');
          fetchEcommerceAdminData();
          if (
            data.sandboxEnabled ||
            state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled
          ) {
            fetchVendorSetupSummary(authState.pkEcomVendor, {skipLoading: true});
          }
        },
        onErrorCallback
      )
    );
  };

  return (
    <AppStack style={{ flex: 1, alignItems: 'center' }}>
      {!loading && hasInitialized ? (
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSaveVendorSettings();
          }}
          p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
          style={{
            flex: 1,
            margin: 'auto',
            width: '100%',
            maxWidth: 1100,
            paddingTop: 16,
            gap: 16
          }}
        >
          <AppFlexbox
            p={{ base: '0px 8px', sm: 0 }}
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <AppText style={{ fontSize: 22, fontWeight: 700 }}>
              Vendor settings
            </AppText>
          </AppFlexbox>
          <AppStack style={{ gap: isLargeMobileOrSmaller ? 20 : 50 }}>
            <SettingCard
              description="Manage core settings for your vendor, including the vendor name, support email, and other essential configurations."
              title="General"
            >
              <TextInput
                disabled={isSubmitting}
                label="Vendor name"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    name: e.currentTarget.value
                  })
                }
                required
                value={formState?.name ?? ''}
              />
              <AppFlexbox
                style={{
                  flexDirection: isLargeMobileOrSmaller ? 'column' : 'row',
                  flex: 1
                }}
              >
                <TextInput
                  disabled={isSubmitting}
                  label="Domain"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      domain: e.currentTarget.value
                    })
                  }
                  style={{ flex: 1 }}
                  value={formState.domain ?? ''}
                />

                <TextInput
                  disabled={isSubmitting}
                  label="Support email"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      supportEmail: e.currentTarget.value
                    })
                  }
                  required
                  style={{ flex: 1 }}
                  type="email"
                  value={formState.supportEmail ?? ''}
                />
              </AppFlexbox>

              <Textarea
                autosize
                disabled={isSubmitting}
                label="Description"
                minRows={8}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value
                  })
                }
                value={formState.description ?? ''}
              />
            </SettingCard>

            <Divider />

            <SettingCard
              action={
                <Button
                  color="dark"
                  component={Link}
                  size="compact-md"
                  style={{ fontSize: 14 }}
                  to={`/vendor/catalog/vendors/${sterilizeUrlHandle(
                    ecomVendor.name
                  )}`}
                  variant="light"
                >
                  Preview
                </Button>
              }
              description="Define the default branding that will be applied to emails and other areas of the platform. Customize your vendor's visual identity with colors, logos, and additional branding elements to ensure a cohesive and professional appearance across all communications and interactions."
              title="Branding"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Default logo</AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Used to pupulate emails and other areas of for you vendor.
                </AppText>
                {formState.logoImageUrl ? (
                  <AppStack style={{ gap: 0 }}>
                    <AppStack
                      style={{
                        width: '100%',
                        height: 150,
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8
                      }}
                    >
                      <AppStack
                        style={{
                          height: '100%',
                          width: 150,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          fit="contain"
                          h="100%"
                          src={formState.logoImageUrl}
                          w="100%"
                        />
                      </AppStack>
                    </AppStack>
                    <Button.Group style={{ flex: 1 }}>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Pencil size={18} />}
                        onClick={() =>
                          onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO)
                        }
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Change
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Trash size={18} />}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            logoImageUrl: ''
                          });
                        }}
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Remove
                      </Button>
                    </Button.Group>
                  </AppStack>
                ) : (
                  <SettingSelector
                    description="jpg, png, svg, webp, or heic. Max file size 5MB."
                    disabled={isSubmitting}
                    icon={<PhotoPlus color="#666" size={24} />}
                    onClick={() => onOpenModal(MODAL_ACTION_ENUM.UPLOAD_LOGO)}
                    title="Select your default logo"
                  />
                )}
                <AppStack style={{ display: 'none' }}>
                  <MediaDropzone
                    disabled={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_LOGO
                    }
                    enableMediaSelect
                    files={
                      formState.logoImageUrl
                        ? [
                            {
                              src: formState.logoImageUrl,
                              preview: formState.logoImageUrl
                            }
                          ]
                        : []
                    }
                    fkEcomVendor={authState.pkEcomVendor}
                    height={275}
                    isOpen={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_LOGO
                    }
                    maxSelectCount={1}
                    onClose={onCloseModal}
                    onUpload={(media) => {
                      setFormState({
                        ...formState,
                        logoImageUrl: media[0]?.src ?? ''
                      });
                    }}
                    uploadOnDrop
                  />
                </AppStack>
              </AppStack>

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Banner image</AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Displayed at the top of your vendor catalog page.
                </AppText>
                {formState.bannerImageUrl ? (
                  <AppStack style={{ gap: 0 }}>
                    <AppStack
                      style={{
                        width: '100%',
                        height: 150,
                        backgroundColor: 'rgba(240, 240, 240, 1)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8
                      }}
                    >
                      <AppStack
                        style={{
                          height: '100%',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          fit="contain"
                          h="100%"
                          src={formState.bannerImageUrl}
                          w="100%"
                        />
                      </AppStack>
                    </AppStack>
                    <Button.Group style={{ flex: 1 }}>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Pencil size={18} />}
                        onClick={() =>
                          onOpenModal(MODAL_ACTION_ENUM.UPLOAD_BANNER)
                        }
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Change
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        leftSection={<Trash size={18} />}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            bannerImageUrl: ''
                          });
                        }}
                        size="compact-md"
                        style={{
                          flex: 1,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0
                        }}
                        variant="default"
                      >
                        Remove
                      </Button>
                    </Button.Group>
                  </AppStack>
                ) : (
                  <SettingSelector
                    description="jpg, png, svg, webp, or heic. Max file size 5MB."
                    disabled={isSubmitting}
                    icon={<PhotoPlus color="#666" size={24} />}
                    onClick={() => onOpenModal(MODAL_ACTION_ENUM.UPLOAD_BANNER)}
                    title="Select your banner image"
                  />
                )}
                <AppStack style={{ display: 'none' }}>
                  <MediaDropzone
                    disabled={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_BANNER
                    }
                    enableMediaSelect
                    files={
                      formState.bannerImageUrl
                        ? [
                            {
                              src: formState.bannerImageUrl,
                              preview: formState.bannerImageUrl
                            }
                          ]
                        : []
                    }
                    fkEcomVendor={authState.pkEcomVendor}
                    height={275}
                    isOpen={
                      modalState.isOpen &&
                      modalState.action === MODAL_ACTION_ENUM.UPLOAD_BANNER
                    }
                    maxSelectCount={1}
                    onClose={onCloseModal}
                    onUpload={(media) => {
                      setFormState({
                        ...formState,
                        bannerImageUrl: media[0]?.src ?? ''
                      });
                    }}
                    skipMediaFetch
                    uploadOnDrop
                  />
                </AppStack>
              </AppStack>

              <Divider />

              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Colors</AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Used to set the default color schemes for your vendor
                  templates.
                </AppText>
              </AppStack>

              <AppFlexbox style={{ gap: 10 }}>
                <AppStack style={{ flex: 1, gap: 16 }}>
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Primary
                    </AppText>
                    <CustomColorInput
                      control={
                        <AppStack>
                          <SettingSelector
                            description={formState.primaryColor}
                            disabled={isSubmitting}
                            icon={<BucketDroplet color="#666" size={24} />}
                            leftSection={
                              formState.primaryColor ? (
                                <AppStack
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: formState.primaryColor
                                  }}
                                />
                              ) : null
                            }
                            onClick={() => {}}
                            onRemove={
                              formState.primaryColor
                                ? () => {
                                    setFormState({
                                      ...formState,
                                      primaryColor: ''
                                    });
                                  }
                                : null
                            }
                            title={
                              formState.primaryColor
                                ? 'Primary color'
                                : 'Select a primary color'
                            }
                          />
                        </AppStack>
                      }
                      label="Primary color"
                      onChange={(color) =>
                        setFormState({
                          ...formState,
                          primaryColor: color
                        })
                      }
                      value={formState.primaryColor ?? ''}
                    />

                    {formState.primaryColor && (
                      <CustomColorInput
                        control={
                          <AppStack>
                            <SettingSelector
                              description={formState.primaryContrastColor}
                              disabled={isSubmitting}
                              leftSection={
                                formState.primaryContrastColor ? (
                                  <AppStack
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor:
                                        formState.primaryContrastColor
                                    }}
                                  />
                                ) : null
                              }
                              onClick={() => {}}
                              rightSection={<></>}
                              title={
                                formState.primaryContrastColor
                                  ? 'Contrasting color'
                                  : 'Select a contrasting color'
                              }
                            />
                          </AppStack>
                        }
                        label="Contrasting color"
                        onChange={(color) =>
                          setFormState({
                            ...formState,
                            primaryContrastColor: color
                          })
                        }
                        value={formState.primaryContrastColor ?? ''}
                      />
                    )}
                  </AppStack>

                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Secondary
                    </AppText>
                    <CustomColorInput
                      control={
                        <AppStack>
                          <SettingSelector
                            description={formState.secondaryColor}
                            disabled={isSubmitting}
                            icon={<BucketDroplet color="#666" size={24} />}
                            leftSection={
                              formState.secondaryColor ? (
                                <AppStack
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: formState.secondaryColor
                                  }}
                                />
                              ) : null
                            }
                            onClick={() => {}}
                            onRemove={
                              formState.secondaryColor
                                ? () => {
                                    setFormState({
                                      ...formState,
                                      secondaryColor: ''
                                    });
                                  }
                                : null
                            }
                            title={
                              formState.secondaryColor
                                ? 'Secondary color 1'
                                : 'Select a secondary color'
                            }
                          />
                        </AppStack>
                      }
                      label="Secondary color 1"
                      onChange={(color) =>
                        setFormState({
                          ...formState,
                          secondaryColor: color
                        })
                      }
                      value={formState.secondaryColor ?? ''}
                    />

                    {formState.secondaryColor && (
                      <>
                        <CustomColorInput
                          control={
                            <AppStack>
                              <SettingSelector
                                description={formState.secondaryAltColor}
                                disabled={isSubmitting}
                                icon={<BucketDroplet color="#666" size={24} />}
                                leftSection={
                                  formState.secondaryAltColor ? (
                                    <AppStack
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:
                                          formState.secondaryAltColor
                                      }}
                                    />
                                  ) : null
                                }
                                onClick={() => {}}
                                onRemove={
                                  formState.secondaryAltColor
                                    ? () => {
                                        setFormState({
                                          ...formState,
                                          secondaryAltColor: ''
                                        });
                                      }
                                    : null
                                }
                                title={
                                  formState.secondaryAltColor
                                    ? 'Secondary color 2'
                                    : 'Select another secondary color'
                                }
                              />
                            </AppStack>
                          }
                          label="Secondary color 2"
                          onChange={(color) =>
                            setFormState({
                              ...formState,
                              secondaryAltColor: color
                            })
                          }
                          value={formState.secondaryAltColor ?? ''}
                        />

                        <CustomColorInput
                          control={
                            <AppStack>
                              <SettingSelector
                                description={formState.secondaryContrastColor}
                                disabled={isSubmitting}
                                leftSection={
                                  formState.secondaryContrastColor ? (
                                    <AppStack
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:
                                          formState.secondaryContrastColor
                                      }}
                                    />
                                  ) : null
                                }
                                onClick={() => {}}
                                rightSection={<></>}
                                title={
                                  formState.primaryContrastColor
                                    ? 'Contrasting color'
                                    : 'Select a contrasting color'
                                }
                              />
                            </AppStack>
                          }
                          label="Contrasting color"
                          onChange={(color) =>
                            setFormState({
                              ...formState,
                              secondaryContrastColor: color
                            })
                          }
                          value={formState.secondaryContrastColor ?? ''}
                        />
                      </>
                    )}
                  </AppStack>
                </AppStack>
              </AppFlexbox>
            </SettingCard>

            <Divider />

            <SettingCard
              description="Manage the visibility of your vendor by enabling sandbox mode. This feature allows you to hide the vendor from public view, making it accessible only to authorized members while updates or changes are being made."
              title="Visibility"
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Sandbox mode</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Enable sandbox mode to hide your stroe from public view
                    while making changes.
                  </AppText>
                </AppStack>

                <SettingSelector
                  description={
                    formState.sandboxEnabled
                      ? 'Vendor is invisible'
                      : 'Vendor is live'
                  }
                  descriptionColor={
                    formState.sandboxEnabled ? '#c40000' : 'green'
                  }
                  disabled={isSubmitting}
                  leftSection={
                    formState.sandboxEnabled ? (
                      <EyeOff color="#c40000" size={32} />
                    ) : (
                      <EyeCheck color="green" size={32} />
                    )
                  }
                  onClick={() => {
                    if (formState.sandboxEnabled) {
                      onOpenModal(MODAL_ACTION_ENUM.SANDBOX_OFF);
                    }
                    else {
                      onOpenModal(MODAL_ACTION_ENUM.SANDBOX_ON);
                    }
                  }}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {formState.sandboxEnabled
                          ? 'Click to go live'
                          : 'Click to hide'}
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  title={
                    formState.sandboxEnabled
                      ? 'Sandbox Enabled'
                      : 'Sandbox Disabled'
                  }
                  withBorder
                />

                <Divider />

                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Store URL</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Your vendor URL is the link to your vendor catalog. You can
                    preview this catalog at any time by clicking the preview
                    button.
                  </AppText>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#666',
                        whiteSpace: 'nowrap',
                        overflow: 'unset'
                      }}
                    >
                      Current url:
                    </AppText>
                    <Anchor
                      component={Link}
                      style={{
                        fontSize: 14,
                        color: 'dodgerblue',
                        fontWeight: 500,
                        wordBreak: 'break-word'
                      }}
                      target="_blank"
                      to={`https://store.sportsheadz.com/vendor/catalog/vendors/${formState.urlHandle}`}
                      underline="always"
                    >
                      https://store.sportsheadz.com/vendor/catalog/vendors/
                      {formState.urlHandle}
                    </Anchor>
                  </AppFlexbox>
                </AppStack>

                <CustomInputModal
                  control={
                    <SettingSelector
                      description={`/${formState.urlHandle}`}
                      disabled={isSubmitting}
                      leftSection={<World color="dodgerblue" size={32} />}
                      onClick={() => {}}
                      rightSection={
                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Click to change
                          </AppText>
                          <ChevronRight size={18} />
                        </AppFlexbox>
                      }
                      title="URL handle"
                      withBorder
                    />
                  }
                  inputProps={{
                    label: 'URL handle',
                    placeholder: 'Enter a URL handle for your vendor'
                  }}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      urlHandle: value
                        ? sterilizeUrlHandle(value)
                        : sterilizeUrlHandle(ecomVendor.name),
                      hasUnsavedChanges: true
                    })
                  }
                  onClear={() =>
                    setFormState({
                      ...formState,
                      urlHandle: '',
                      hasUnsavedChanges: true
                    })
                  }
                  parser={(value) => sterilizeUrlHandle(value)}
                  title="Vendor URL handle"
                  value={formState.urlHandle}
                />
              </AppStack>
            </SettingCard>

            <Divider />

            <SettingCard
              description="Configure the default currency for your vendor. The selected currency will be applied to all vendor transactions. Please note that all associated stores must use the same currency for adding and selling your products."
              title="Payments"
            >
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontWeight: 500 }}>Payment provider</AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Onboard with Stripe and start auto receiving payments as they
                  are made.
                </AppText>
              </AppStack>

              <SettingSelector
                description={
                  !vendorSummary.ecomVendor.ecomVendorPaymentProvider
                    ? 'Not connected'
                    : vendorSummary.ecomVendor.ecomVendorPaymentProvider
                        ?.completed
                    ? 'Connected'
                    : 'Action required'
                }
                descriptionColor={
                  vendorSummary.ecomVendor.ecomVendorPaymentProvider?.completed
                    ? '#067D62'
                    : '#c40000'
                }
                disabled={isSubmitting}
                leftSection={
                  vendorSummary.ecomVendor.ecomVendorPaymentProvider
                    ?.completed ? (
                    <Check color="#067D62" size={32} />
                  ) : (
                    <AlertCircle
                      color={
                        vendorSummary.ecomVendor.ecomVendorPaymentProvider
                          ? '#eca70a'
                          : '#c40000'
                      }
                      size={32}
                    />
                  )
                }
                onClick={() => {
                  onOpenModal(MODAL_ACTION_ENUM.CONNECT_PAYMENT_PROVIDER);
                }}
                rightSection={
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {vendorSummary.ecomVendor.ecomVendorPaymentProvider
                        ? 'Click to edit'
                        : 'Click to setup'}
                    </AppText>
                    <ChevronRight size={18} />
                  </AppFlexbox>
                }
                title={
                  vendorSummary.ecomVendor.ecomVendorPaymentProvider
                    ? `Account ID: ${vendorSummary.ecomVendor.ecomVendorPaymentProvider.accountId}`
                    : 'Click here to onboard with Stripe.'
                }
                withBorder
              />

              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Currency</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Select the preferred currency for your vendor. This currency
                    will serve as the default for all transactions. All
                    associated stores are required to use the same currency as
                    specified by the vendor.
                  </AppText>
                </AppStack>

                <SettingSelector
                  disabled={isSubmitting}
                  leftSection={<CurrencyDollar size={32} />}
                  onClick={() => onOpenModal(MODAL_ACTION_ENUM.CHANGE_CURRENCY)}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Click to change
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  title={selectedCurrency.fullLabel}
                  withBorder
                />
              </AppStack>
            </SettingCard>

            <Divider />

            <SettingCard
              description="Specify the locations where your products can be sold. This ensures your offerings are available in the designated areas."
              title="Locations"
            >
              <AppFlexbox style={{ gap: 40 }}>
                <AppFlexbox style={{ flex: 1 }}>
                  <PaginationList
                    emptyMessage="No locations assigned"
                    items={formState.provinces
                      .map((p) => {
                        const province = LOCATION_LIST_OPTIONS.find(
                          (f) => f.value === p
                        );
                        return {
                          value: province?.value,
                          label: province?.label
                        };
                      })
                      .sort((a, b) =>
                        (a.label ?? '').localeCompare(b.label ?? '')
                      )
                      .map((p) => (
                        <AppFlexbox
                          key={p.value}
                          style={{ gap: 0, padding: 10 }}
                        >
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            {p.label}
                          </AppText>
                        </AppFlexbox>
                      ))}
                    itemsPerPage={10}
                    title="Locations"
                    titleAction={
                      <Button
                        color="dark"
                        disabled={isSubmitting}
                        leftSection={<Edit size={18} />}
                        onClick={() =>
                          onOpenModal(MODAL_ACTION_ENUM.EDIT_PROVINCES)
                        }
                        size="compact-sm"
                        type="button"
                        variant="outline"
                      >
                        Assign
                      </Button>
                    }
                  />
                </AppFlexbox>
              </AppFlexbox>
            </SettingCard>

            <SettingCard
              description="Easily preview the notifications sent to your customers for key order updates, such as order received, shipped, refunded, or cancelled. This setting allows you to review and ensure the notifications align with your preferences before they are sent."
              title="Notifications"
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Notifications</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }} />
                </AppStack>

                <SettingSelector
                  description="Preview the notification sent to your customers and vendors."
                  disabled={isSubmitting}
                  leftSection={<Send color="#666" size={32} />}
                  onClick={() => navigate('/vendor/notifications')}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Click to view
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  title="View notifications"
                  withBorder
                />
              </AppStack>
            </SettingCard>
          </AppStack>
          <AppFlexbox
            p={{ base: 16, sm: '16px 0px' }}
            style={{
              justifyContent: 'flex-end',
              flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
            }}
          >
            <Button
              color="dark"
              disabled={!hasUnsavedChanges}
              id="save-button"
              loading={isSubmitting}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="submit"
              variant="filled"
            >
              Save Changes
            </Button>
          </AppFlexbox>

          {ConfirmDiscardModal}

          <OnboardVendorPaymentProviderModal
            ecomVendor={vendorSummary.ecomVendor}
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.CONNECT_PAYMENT_PROVIDER
            }
            onClose={onCloseModal}
          />

          <AssignVendorProvinceModal
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.EDIT_PROVINCES
            }
            onClose={onCloseModal}
            onConfirm={(provinces) => {
              setFormState({
                ...formState,
                provinces
              });
              onCloseModal();
            }}
            provinces={formState.provinces}
          />

          <LaunchVendorModal
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.SANDBOX_OFF
            }
            onClose={onCloseModal}
            onConfirm={() => {
              onCloseModal();
              setFormState({
                ...formState,
                sandboxEnabled: false
              });
            }}
          />

          <ConfirmModal
            confirmActionColor="red"
            confirmActionText="Yes, hide vendor"
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.SANDBOX_ON
            }
            message=""
            onCancel={onCloseModal}
            onConfirm={() => {
              onCloseModal();
              setFormState({
                ...formState,
                sandboxEnabled: true
              });
            }}
            title="Hide vendor?"
          >
            <AppStack>
              <AppText style={{ fontSize: 14 }}>
                Are you certain you want to <b>hide</b> this <b>vendor</b>?
                Doing so will make the vendor <b>private</b> and{' '}
                <b>unavailable</b> for merchants to view or add products to
                their stores.
              </AppText>

              <AppCard
                radius="md"
                shadow="none"
                style={{ padding: 10 }}
                withBorder
              >
                <Checkbox
                  label="Any products that have already been purchased will remain visible to the merchant and will still need to be fulfilled."
                  required
                />
              </AppCard>
            </AppStack>
          </ConfirmModal>

          <ChangeCurrencyModal
            fkCurrencyType={formState.fkCurrencyType}
            isOpen={
              modalState.isOpen &&
              modalState.action === MODAL_ACTION_ENUM.CHANGE_CURRENCY
            }
            onClose={onCloseModal}
            onConfirm={(fkCurrencyType) => {
              setFormState({
                ...formState,
                fkCurrencyType
              });
              onCloseModal();
            }}
            sandboxEnabled={formState.sandboxEnabled}
          />
        </AppStack>
      ) : (
        <AppStack
          p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
          style={{
            flex: 1,
            margin: 'auto',
            width: '100%',
            maxWidth: 1100,
            paddingTop: 16,
            gap: 16
          }}
        >
          <AppFlexbox
            p={{ base: '0px 8px', sm: 0 }}
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Skeleton height={34} width={160} />
          </AppFlexbox>
          <AppStack style={{ flex: 1, gap: isLargeMobileOrSmaller ? 20 : 50 }}>
            <SettingCard loading loadingHeight={370} />
            <Divider />
            <SettingCard loading loadingHeight={675} />
            <Divider />
            <SettingCard loading loadingHeight={135} />
            <Divider />
            <SettingCard loading loadingHeight={75} />
          </AppStack>
        </AppStack>
      )}
    </AppStack>
  );
};

export default VendorSettingsView;
