import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Image,
  Indicator,
  NumberFormatter,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import ProductLineItemAddonsDisplay from '../../../products/ProductLineItemAddonsDisplay';

const WsAccountOrderSummaryProduct = ({
  mediaQueries,
  baseStoreUrl,
  onNavigate,
  hidePrice,
  loading,
  urlHandle,
  shippingCount,
  count,
  cancelledCount,
  previewImage,
  productName,
  addOns,
  unitPrice,
  onViewTracking,
  trackingUrl
}) => {
  const { isTabletOrSmaller, getResponsiveStyle: rs } = mediaQueries;
  const url = `${baseStoreUrl}/products/${urlHandle}`;

  const statusInfo =
    shippingCount > 0
      ? {
          label:
            shippingCount >= count - cancelledCount
              ? 'Shipped'
              : `(${shippingCount}/${count}) Shipped`,
          color: 'dodgerblue'
        }
      : cancelledCount >= count
      ? {
          label: 'Cancelled',
          color: '#C40000'
        }
      : {
          label: 'Processing',
          color: '#000'
        };

  return !loading ? (
    <AppFlexbox
      style={{
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: rs(['column', 'column', 'column', 'row']),
        gap: 16,
        justifyContent: 'space-between'
      }}
    >
      <AppStack style={{ gap: 5 }}>
        <AppText
          style={{ fontSize: 18, fontWeight: 700, color: statusInfo.color }}
        >
          {statusInfo.label}
        </AppText>
        <AppFlexbox style={{ flexWrap: 'nowrap', alignSelf: 'stretch' }}>
          <Indicator
            color="dodgerblue"
            disabled={!count || count <= 1}
            label={count}
            size={20}
          >
            <AppStack
              component={url ? Link : null}
              onClick={onNavigate}
              style={{
                border: !previewImage ? 'solid 1px lightgrey' : 'none',
                position: 'relative',
                width: isTabletOrSmaller ? 70 : 100,
                backgroundColor: '#f2f2f2',
                overflow: 'hidden',
                aspectRatio: '1 / 1'
              }}
              to={url}
            >
              <Image
                fit="cover"
                h="100%"
                loading="lazy"
                src={previewImage}
                style={{ position: 'absolute' }}
                w="100%"
              />
            </AppStack>
          </Indicator>

          <AppStack
            style={{
              flex: 1,
              gap: 0
            }}
          >
            <AppStack
              component={url ? Link : null}
              onClick={onNavigate}
              style={{
                gap: 0,
                textDecoration: 'none',
                color: '#000'
              }}
              to={url}
            >
              <AppText size="lg" weight={700}>
                {productName}
              </AppText>

              <ProductLineItemAddonsDisplay addOns={addOns} />
            </AppStack>
            {!hidePrice && (
              <NumberFormatter
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={unitPrice / 100}
              />
            )}
          </AppStack>
        </AppFlexbox>
      </AppStack>

      <AppStack
        style={{
          alignSelf: 'start',
          placeItems: 'end',
          marginLeft: 'auto',
          gap: rs([0, 20]),
          flexDirection: rs([
            'row-reverse',
            'row-reverse',
            'row-reverse',
            'column'
          ]),
          justifyContent: rs([
            'space-between',
            'space-between',
            'space-between',
            'inital'
          ]),
          width: rs(['100%', '100%', '100%', 'unset'])
        }}
      >
        <AppStack style={{ gap: 0, alignItems: 'end' }}>
          {shippingCount > 0 && (onViewTracking || trackingUrl) && (
            <Button
              color="blue"
              component={trackingUrl ? Link : null}
              onClick={(e) => {
                if (trackingUrl) {
                  onNavigate(e);
                }
                else if (onViewTracking) {
                  onViewTracking();
                }
              }}
              size="compact-md"
              to={trackingUrl}
              variant="outline"
            >
              Track package
            </Button>
          )}
        </AppStack>
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox
      style={{
        flexWrap: 'nowrap',
        flexDirection: rs(['column', 'column', 'column', 'row'])
      }}
    >
      <AppFlexbox style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}>
        <Skeleton
          height={isTabletOrSmaller ? 70 : 100}
          width={isTabletOrSmaller ? 70 : 100}
        />
        <AppStack
          style={{
            flex: 1,
            gap: 10,
            textDecoration: 'none',
            color: '#000'
          }}
        >
          <Skeleton height={12} width="50%" />
          <Skeleton height={12} width="40%" />
        </AppStack>
      </AppFlexbox>

      <AppStack
        style={{
          flex: 1,
          alignSelf: 'start',
          placeItems: 'end',
          marginLeft: 'auto',
          gap: rs([0, 0, 0, 30]),
          flexDirection: rs([
            'row-reverse',
            'row-reverse',
            'row-reverse',
            'column'
          ]),
          justifyContent: rs([
            'space-between',
            'space-between',
            'space-between',
            'inital'
          ]),
          width: rs(['100%', '100%', '100%', 'unset'])
        }}
      >
        <AppStack style={{ gap: 0, alignItems: 'end' }}>
          <Skeleton height={20} width={100} />
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

WsAccountOrderSummaryProduct.propTypes = {
  addOns: PropTypes.array,
  baseStoreUrl: PropTypes.string,
  cancelledCount: PropTypes.number,
  count: PropTypes.number,
  hidePrice: PropTypes.bool,
  loading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  onViewTracking: PropTypes.func,
  previewImage: PropTypes.string,
  productName: PropTypes.string,
  shippingCount: PropTypes.number,
  trackingUrl: PropTypes.string,
  unitPrice: PropTypes.number,
  urlHandle: PropTypes.string
};

export default WsAccountOrderSummaryProduct;
