/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, Receipt } from 'tabler-icons-react';
import { ActionIcon, Button, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import WsAccountOrderInfo from './WsAccountOrderInfo';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import { formatUtcDate } from '../../../../../helpers/format';

const WsAccountOrderDetails = ({
  baseStoreUrl,
  mediaQueries,
  onNavigate,
  pathname,
  search,
  editModeEnabled
}) => {
  const fetchedPkEcomOrder = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchEcomStoreOrder } = useContext(StoreUserContext);
  const { isTabletOrSmaller } = mediaQueries;
  const pkEcomOrder = pathname.split('/')[3];
  const searchParams = new URLSearchParams(search);
  const guestEmail = searchParams.get('email');

  const ecomOrder = state.ecomOrder.value;
  const isOrderDetailsLoading =
    !fetchedPkEcomOrder.current || state.ecomOrder.loading;

  useEffect(() => {
    if (
      authState.tokenAttempted &&
      pkEcomOrder &&
      fetchedPkEcomOrder.current !== pkEcomOrder
    ) {
      fetchEcomStoreOrder(pkEcomOrder, { guestEmail });
      fetchedPkEcomOrder.current = pkEcomOrder;
    }
  }, [pkEcomOrder, authState.tokenAttempted]);

  return (
    <AppStack style={{ flex: 1, gap: 16 }}>
      <AppStack style={{ flex: 1, gap: 0 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppFlexbox
            style={{
              gap: 8,
              padding: isTabletOrSmaller ? '16px 16px 0px 16px' : 0
            }}
          >
            <ActionIcon
              color="dark"
              component={Link}
              onClick={onNavigate}
              to={
                !authState.isAuthenticated
                  ? `${baseStoreUrl}/account/login?redirect=/account/orders`
                  : `${baseStoreUrl}/account/orders`
              }
              variant="subtle"
            >
              <ChevronLeft size={20} />
            </ActionIcon>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 18, fontWeight: 700 }}>
                Order Details
              </AppText>
              <AppFlexbox style={{ gap: 5 }}>
                {isOrderDetailsLoading ? (
                  <Skeleton height={12} style={{ marginTop: 5 }} width={200} />
                ) : (
                  ecomOrder && (
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      {ecomOrder.checkoutBalance.purchaseDate && (
                        <>
                          Ordered on{' '}
                          {dayjs(
                            formatUtcDate(
                              ecomOrder.checkoutBalance.purchaseDate
                            )
                          ).format('MMMM D, YYYY')}{' '}
                          |{' '}
                        </>
                      )}
                      Order #{ecomOrder.pkEcomOrder}
                    </AppText>
                  )
                )}
              </AppFlexbox>
            </AppStack>
          </AppFlexbox>
        </AppFlexbox>
      </AppStack>
      {isOrderDetailsLoading ? (
        <WsAccountOrderInfo
          baseStoreUrl={baseStoreUrl}
          editModeEnabled={editModeEnabled}
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
          search={search}
        />
      ) : !ecomOrder ? (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack
            style={{
              flex: 1,
              gap: 5,
              alignItems: 'center',
              textAlign: 'center',
              padding: 40
            }}
          >
            <Receipt color="#000" size={56} />
            <AppText style={{ fontWeight: 500 }}>
              Order could not be found
            </AppText>
            <Button
              color="dark"
              component={Link}
              onClick={onNavigate}
              style={{ marginTop: 5 }}
              to={baseStoreUrl}
              variant="outline"
            >
              Back to orders
            </Button>
          </AppStack>
        </AppCard>
      ) : (
        <WsAccountOrderInfo
          baseStoreUrl={baseStoreUrl}
          ecomOrder={ecomOrder}
          editModeEnabled={editModeEnabled}
          mediaQueries={mediaQueries}
          onNavigate={onNavigate}
          pathname={pathname}
          search={search}
        />
      )}
    </AppStack>
  );
};

WsAccountOrderDetails.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string
};

export default WsAccountOrderDetails;
