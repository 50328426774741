import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import CustomTooltip from '../../common/CustomTooltip';

const AnalyticsCardSummary = ({
  label,
  count,
  value,
  loading,
  redirectPath,
  tooltip
}) => (
  <AppCard
    radius="md"
    shadow="lg"
    style={{
      flex: 1,
      width: '100%',
      height: '100%',
      minHeight: '100%',
      padding: 0
    }}
    withBorder
  >
    <AppStack style={{ flex: 1, gap: 0 }}>
      <CustomTooltip disabled={!tooltip} label={tooltip}>
        <AppFlexbox
          className={classes['hover-action-card']}
          component={redirectPath ? Link : null}
          style={{
            cursor: 'pointer',
            color: '#000',
            textDecoration: 'none',
            alignItems: 'center',
            padding: 8,
            gap: 8,
            width: '100%'
          }}
          to={redirectPath}
        >
          <AppText
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '22px',
              borderBottom: 'dotted 2px #B1B1B1'
            }}
          >
            {label}
          </AppText>
          {!loading && count !== undefined && (
            <Badge circle style={{ cursor: 'pointer' }}>
              {count}
            </Badge>
          )}
        </AppFlexbox>
      </CustomTooltip>

      <AppFlexbox
        style={{
          gap: 5,
          alignItems: 'center',
          textAlign: 'center',
          padding: '8px 8px 12px 6px'
        }}
      >
        {loading ? (
          <Skeleton height={32} width={115} />
        ) : (
          <AppText
            style={{
              fontSize: 32,
              fontWeight: 500,
              lineHeight: '32px'
            }}
          >
            {value}
          </AppText>
        )}
      </AppFlexbox>
    </AppStack>
  </AppCard>
);

AnalyticsCardSummary.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  loading: PropTypes.bool,
  redirectPath: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AnalyticsCardSummary;
