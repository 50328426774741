/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Divider, NumberFormatter, Skeleton } from '@mantine/core';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import WsCheckoutCartProductCard from '../checkoutContent/WsCheckoutCartProductCard';
import { translateStoreOrderData } from '../../../../../helpers/storeHelper';
import WsAccountOrderSummaryProduct from './WsAccountOrderSummaryProduct';
import ResponsiveWebstoreModal from '../../ResponsiveWebstoreModal';

const WsAccountOrderInfo = ({
  baseStoreUrl,
  onNavigate,
  mediaQueries,
  ecomOrder,
  editModeEnabled,
  search
}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    pkEcomOrderProduct: null
  });
  const { isTabletOrSmaller } = mediaQueries;
  const checkoutBalance = ecomOrder?.checkoutBalance;
  const currency = checkoutBalance?.currency.toUpperCase() ?? 'CAD';
  const { ecomOrderProducts: productData } = translateStoreOrderData(ecomOrder);
  const returnedProducts = productData.filter((f) => f.cancelledCount > 0);
  const proccessingProducts = productData.filter(
    (p) => p.quantity - p.cancelledCount > 0
  );

  const searchParams = new URLSearchParams(search);
  const trackingProduct = searchParams.get('tracking');
  const selectedProduct = productData.find(
    (p) =>
      p.pkEcomOrderProduct.toString() ===
      modalState.pkEcomOrderProduct?.toString()
  );

  useEffect(() => {
    if (trackingProduct) {
      setModalState({
        isOpen: true,
        pkEcomOrderProduct: trackingProduct
      });
    }
  }, [trackingProduct]);

  return ecomOrder ? (
    <>
      <ResponsiveWebstoreModal
        alignModal={isTabletOrSmaller ? 'end' : 'center'}
        editModeEnabled={editModeEnabled}
        isOpen={modalState.isOpen}
        mediaQueries={mediaQueries}
        onClose={() => {
          setModalState({
            ...modalState,
            isOpen: false
          });
        }}
        radius={0}
        size={700}
        title="Tracking details"
      >
        {selectedProduct && (
          <AppStack style={{ gap: 16 }}>
            <WsAccountOrderSummaryProduct
              addOns={selectedProduct.addOns}
              baseStoreUrl={baseStoreUrl}
              cancelledCount={0}
              count={selectedProduct.quantity - selectedProduct.cancelledCount}
              mediaQueries={mediaQueries}
              onNavigate={onNavigate}
              previewImage={selectedProduct.previewImage}
              productName={selectedProduct.name}
              shippingCount={selectedProduct.shippedCount}
              unitPrice={selectedProduct.unitCost}
              urlHandle={selectedProduct.productUrlHandle}
            />

            <AppFlexbox
              style={{
                gap: 16,
                flexDirection: isTabletOrSmaller ? 'column' : 'row'
              }}
            >
              <AppCard radius={8} shadow="none" style={{ flex: 1 }} withBorder>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                    Tracking information
                  </AppText>
                  <AppStack style={{ gap: 0 }}>
                    {selectedProduct.ecomOrderShippingProducts.length === 0 ? (
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Tracking detail will show here once the product is
                        shipped.
                      </AppText>
                    ) : selectedProduct.ecomOrderShippingProducts.every(
                        (s) =>
                          !s.ecomOrderShipping.shippingCarrier &&
                          !s.ecomOrderShipping.trackingUrl
                      ) ? (
                      <AppText
                        style={{
                          fontSize: 14,
                          fontStyle: 'italic',
                          color: '#666'
                        }}
                      >
                        No tracking was provided for this shipment
                      </AppText>
                    ) : (
                      <>
                        <AppFlexbox style={{ gap: 8 }}>
                          <AppText style={{ fontSize: 14 }}>
                            Shipped by{' '}
                            {selectedProduct.ecomOrderShippingProducts
                              .map((p) => p.ecomOrderShipping.shippingCarrier)
                              .join(', ')}
                          </AppText>
                        </AppFlexbox>
                        <AppFlexbox style={{ gap: 5 }}>
                          <AppText style={{ fontSize: 14 }}>
                            Tracking ID:
                          </AppText>
                          {selectedProduct.ecomOrderShippingProducts.some(
                            (p) =>
                              !!p.ecomOrderShipping.trackingUrl ||
                              !!p.ecomOrderShipping.trackingNumber
                          ) ? (
                            selectedProduct.ecomOrderShippingProducts
                              .filter(
                                (p) =>
                                  !!p.ecomOrderShipping.trackingUrl ||
                                  !!p.ecomOrderShipping.trackingNumber
                              )
                              .map((p) => (
                                <Anchor
                                  key={p.pkEcomOrderShippingProduct}
                                  c={
                                    p.ecomOrderShipping.trackingUrl
                                      ? 'dodgerblue'
                                      : '#000'
                                  }
                                  href={p.ecomOrderShipping.trackingUrl}
                                  style={{ fontSize: 14 }}
                                  target="_blank"
                                  underline={
                                    p.ecomOrderShipping.trackingUrl
                                      ? 'hover'
                                      : 'none'
                                  }
                                >
                                  {p.ecomOrderShipping.trackingNumber ||
                                    'View tracking'}
                                </Anchor>
                              ))
                          ) : (
                            <AppText
                              style={{
                                fontSize: 14,
                                fontStyle: 'italic',
                                color: '#666'
                              }}
                            >
                              No further tracking information is available
                            </AppText>
                          )}
                        </AppFlexbox>
                      </>
                    )}
                  </AppStack>
                </AppStack>
              </AppCard>
              <AppCard radius={8} shadow="none" style={{ flex: 1 }} withBorder>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                    Shipping address
                  </AppText>
                  {selectedProduct.ecomOrderShippingProducts[0]
                    ?.ecomOrderShipping.ecomCustomerAddress && (
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14 }}>
                        {
                          selectedProduct.ecomOrderShippingProducts[0]
                            .ecomOrderShipping.ecomCustomerAddress.fullName
                        }
                      </AppText>
                      {selectedProduct.ecomOrderShippingProducts[0]
                        .ecomOrderShipping.fkEcomOrder ===
                        ecomOrder.pkEcomOrder && (
                        <>
                          <AppText style={{ fontSize: 14 }}>
                            {
                              selectedProduct.ecomOrderShippingProducts[0]
                                .ecomOrderShipping.ecomCustomerAddress.address1
                            }
                          </AppText>
                          <AppText style={{ fontSize: 14 }}>
                            {
                              selectedProduct.ecomOrderShippingProducts[0]
                                .ecomOrderShipping.ecomCustomerAddress.city
                            }
                            ,{' '}
                            {
                              selectedProduct.ecomOrderShippingProducts[0]
                                .ecomOrderShipping.ecomCustomerAddress.state
                            }{' '}
                            {
                              selectedProduct.ecomOrderShippingProducts[0]
                                .ecomOrderShipping.ecomCustomerAddress
                                .postalCode
                            }
                          </AppText>
                          <AppText style={{ fontSize: 14 }}>
                            {
                              selectedProduct.ecomOrderShippingProducts[0]
                                .ecomOrderShipping.ecomCustomerAddress.country
                            }
                          </AppText>
                        </>
                      )}
                    </AppStack>
                  )}
                </AppStack>
              </AppCard>
            </AppFlexbox>
          </AppStack>
        )}
      </ResponsiveWebstoreModal>
      <AppCard
        radius={isTabletOrSmaller ? 0 : 8}
        shadow="xs"
        style={{ padding: 16 }}
        withBorder
      >
        <AppFlexbox
          style={{
            alignItems: 'start',
            flexDirection: isTabletOrSmaller ? 'column' : 'row',
            gap: 16
          }}
        >
          <AppStack
            style={{
              flex: 2,
              gap: 3,
              alignSelf: 'stretch'
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 700 }}>
              Shipping address
            </AppText>
            {ecomOrder.ecomCustomerAddressShipping && (
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 14 }}>
                  {ecomOrder.ecomCustomerAddressShipping.fullName}
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  {ecomOrder.ecomCustomerAddressShipping.address1}
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  {ecomOrder.ecomCustomerAddressShipping.city},{' '}
                  {ecomOrder.ecomCustomerAddressShipping.state}{' '}
                  {ecomOrder.ecomCustomerAddressShipping.postalCode}
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  {ecomOrder.ecomCustomerAddressShipping.country}
                </AppText>
              </AppStack>
            )}
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              gap: 3,
              alignSelf: 'stretch'
            }}
          >
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
                gap: 40
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 700 }}>
                Order Summary
              </AppText>
              <AppFlexbox style={{ gap: 10 }} />
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <AppText style={{ fontSize: 14 }}>Subtotal</AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText style={{ fontSize: 14 }}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={checkoutBalance.subtotalInCents / 100}
                    />
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
              {checkoutBalance.discountInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Discount</AppText>
                  <AppFlexbox style={{ fontSize: 14, gap: 10 }}>
                    <AppText>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={(checkoutBalance.discountInCents / 100) * -1}
                      />
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.taxInCents && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Taxes</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText style={{ fontSize: 14 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.taxInCents / 100}
                      />
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.serviceFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Service Fee</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText style={{ fontSize: 14 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.serviceFeeInCents / 100}
                      />
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              {checkoutBalance.tieredPricingFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>Service Fee</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText style={{ fontSize: 14 }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.tieredPricingFeeInCents / 100}
                      />
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText style={{ fontSize: 14 }}>Shipping</AppText>
                <AppFlexbox style={{ fontSize: 14, gap: 10 }}>
                  <AppText style={{ fontSize: 14 }}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14 }}
                      thousandSeparator
                      value={0}
                    />
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
              <Divider style={{ margin: '5px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText style={{ fontSize: 14 }} weight={700}>
                  Total
                </AppText>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 14 }} weight={700}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={checkoutBalance.totalInCents / 100}
                    />
                  </AppText>
                  <AppText style={{ fontSize: 14 }} weight={700}>
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>

              {checkoutBalance.refundedAmountInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText style={{ fontSize: 14, color: '#C40000' }}>
                    Refunded
                  </AppText>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14, color: '#C40000' }}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={checkoutBalance.refundedAmountInCents / 100}
                      />
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#C40000' }}>
                      {currency}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
            </AppStack>
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: isTabletOrSmaller ? 10 : 20,
          marginBottom: isTabletOrSmaller ? 10 : 0
        }}
      >
        <AppCard
          radius={isTabletOrSmaller ? 0 : 8}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack style={{ gap: 0 }}>
            {proccessingProducts.length === 0 ? (
              <AppStack style={{ padding: 16 }}>
                <AppText
                  style={{
                    fontSize: 14,
                    fontStyle: 'italic',
                    fontWeight: 500,
                    color: '#666',
                    textAlign: 'center'
                  }}
                >
                  All products in this order have been cancelled.
                </AppText>
              </AppStack>
            ) : (
              proccessingProducts.map((p, index) => (
                <React.Fragment key={p.key}>
                  {index > 0 && <Divider />}
                  <AppFlexbox style={{ padding: 16 }}>
                    <WsAccountOrderSummaryProduct
                      addOns={p.addOns}
                      baseStoreUrl={baseStoreUrl}
                      cancelledCount={0}
                      count={p.quantity - p.cancelledCount}
                      mediaQueries={mediaQueries}
                      onNavigate={onNavigate}
                      onViewTracking={() => {
                        setModalState({
                          isOpen: true,
                          pkEcomOrderProduct: p.pkEcomOrderProduct
                        });
                      }}
                      previewImage={p.previewImage}
                      productName={p.name}
                      shippingCount={p.shippedCount}
                      unitPrice={p.unitCost}
                      urlHandle={p.productUrlHandle}
                    />
                  </AppFlexbox>
                </React.Fragment>
              ))
            )}
          </AppStack>
        </AppCard>

        {returnedProducts.length > 0 && (
          <AppCard
            radius={isTabletOrSmaller ? 0 : 8}
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                  Cancelled products
                </AppText>
              </AppFlexbox>
              <Divider />
              {returnedProducts.map((p, index) => (
                <React.Fragment key={p.key}>
                  {index > 0 && <Divider />}
                  <AppFlexbox style={{ padding: 16 }}>
                    <WsAccountOrderSummaryProduct
                      addOns={p.addOns}
                      baseStoreUrl={baseStoreUrl}
                      cancelledCount={p.cancelledCount}
                      count={p.cancelledCount}
                      mediaQueries={mediaQueries}
                      onNavigate={onNavigate}
                      previewImage={p.previewImage}
                      productName={p.name}
                      shippingCount={0}
                      unitPrice={p.unitCost}
                      urlHandle={p.productUrlHandle}
                    />
                  </AppFlexbox>
                </React.Fragment>
              ))}
            </AppStack>
          </AppCard>
        )}
      </AppStack>
    </>
  ) : (
    <>
      <AppCard
        radius={isTabletOrSmaller ? 0 : 8}
        shadow="xs"
        style={{ padding: 16 }}
        withBorder
      >
        <AppFlexbox
          style={{
            alignItems: 'start',
            flexDirection: isTabletOrSmaller ? 'column' : 'row'
          }}
        >
          <AppStack style={{ flex: 2, gap: 13 }}>
            <Skeleton height={14} width={100} />
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={14} width={100} />
              <Skeleton height={14} width={120} />
              <Skeleton height={14} width={120} />
              <Skeleton height={14} width={60} />
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              gap: 13
            }}
          >
            <Skeleton height={14} width={100} />
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={70} />
                <Skeleton height={14} width={60} />
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={70} />
                <Skeleton height={14} width={60} />
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={70} />
                <Skeleton height={14} width={60} />
              </AppFlexbox>
              <Divider style={{ margin: '5px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={70} />
                <Skeleton height={14} width={80} />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: isTabletOrSmaller ? 10 : 20,
          width: isTabletOrSmaller ? '100%' : 'auto',
          marginBottom: isTabletOrSmaller ? 10 : 0
        }}
      >
        <WsCheckoutCartProductCard isOrderHistory mediaQueries={mediaQueries} />
      </AppStack>
    </>
  );
};

WsAccountOrderInfo.propTypes = {
  baseStoreUrl: PropTypes.string.isRequired,
  mediaQueries: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
  ecomOrder: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  search: PropTypes.string
};

export default WsAccountOrderInfo;
