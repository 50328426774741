import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stepper } from '@mantine/core';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppText from '../../common/AppText';

const ORDER_FULFILLMENT_TYPE_ENUM = {
  FULFILLMENT: 'FULFILLMENT',
  STORE_REFUND: 'STORE_REFUND',
  VENDOR_REFUND: 'VENDOR_REFUND'
};

const HELP_INFO = {
  [ORDER_FULFILLMENT_TYPE_ENUM.FULFILLMENT]: {
    title: 'Order fulfillment',
    message:
      'As a vendor, you will manage the process from when a customer places an order until it is completed and the customer is notified that their items are on the way.',
    subMessage:
      'Each step outlines what to do in the system to ensure a smooth experience for both you and your customer.',
    steps: [
      {
        label: 'Review the ordered products',
        subSteps: [
          'Confirm the products listed for this order. Check for any special instructions or specific shipping details required for each item.'
        ]
      },
      {
        label: 'Prepare the products for shipping',
        subSteps: [
          'Gather the products listed in the order and prepare them for shipping. This may include packaging, labeling, and any additional setup required for a secure and safe delivery.'
        ]
      },
      {
        label: 'Fulfill the ordered products',
        subSteps: [
          'Select the products that you’ll be shipping for this fulfillment, which could be all items or specific items if you’re splitting the order.',
          'Add any relevant shipping details, including tracking information if available. You can also leave the tracking details empty if you plan to add it later before notifying the customer.',
          'Once products are marked as fulfilled, they are no longer eligible for refunds through the store.'
        ]
      },
      {
        label: 'Notify the customer',
        subSteps: [
          'Notify the customer that their products are fulfilled. The system can send an automatic email, or you can choose to send the notification later if you still need to finalize tracking details.',
          'This informs the customer that their products are on the way and allows them to track the order if tracking details are available.'
        ]
      },
      {
        label: 'Update tracking information if needed',
        subSteps: [
          'You can return to add or edit tracking information at any time. Any changes to tracking details will require notifying the customer again.',
          'This is helpful if there’s a delay in obtaining tracking info or if last-minute adjustments are necessary.'
        ]
      },
      {
        label: 'Complete the Order',
        subSteps: [
          'After all products are fulfilled and the customer has been notified, the system will mark the entire order as fulfilled. This confirms the order is finalized, with items shipped and the customer informed.'
        ]
      }
    ]
  },

  [ORDER_FULFILLMENT_TYPE_ENUM.STORE_REFUND]: {
    title: 'Refunding orders',
    message: '',
    subMessage: '',
    steps: [
      {
        label: '',
        subSteps: ['']
      }
    ]
  }
};

const OrderHelpModal = ({ isOpen, onClose, type }) => {
  const helpInfo = HELP_INFO[type] || {};

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Close',
        onCancel: onClose,
        isSubmitHidden: true,
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={helpInfo.title}
    >
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ padding: 16 }}>
          <AppText style={{ fontSize: 14, textAlign: 'center' }}>
            {helpInfo.message}
          </AppText>
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            {helpInfo.subMessage}
          </AppText>
        </AppStack>
        <Divider />
        <AppStack style={{ gap: 24, padding: 16 }}>
          <Stepper color="dark" iconSize={50} orientation="vertical">
            {helpInfo.steps?.map((s) => (
              <Stepper.Step
                key={s.label}
                description={
                  <AppStack style={{ paddingBottom: 20, gap: 8 }}>
                    {s.subSteps.map((text, index) => (
                      <AppText
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        style={{ fontSize: 14, color: '#666' }}
                      >
                        {text}
                      </AppText>
                    ))}
                  </AppStack>
                }
                label={s.label}
              />
            ))}
          </Stepper>
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};
OrderHelpModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string
};

export default OrderHelpModal;

export { ORDER_FULFILLMENT_TYPE_ENUM };
