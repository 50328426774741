import React from 'react';
import { BuildingStore, User, Users } from 'tabler-icons-react';

const NOTIFICATION_SECTION_ENUM = {
  CUSTOMER_NOTIFICATIONS: 'CUSTOMER_NOTIFICATIONS',
  MERCHANT_NOTIFICATIONS: 'MERCHANT_NOTIFICATIONS',
  STAFF_NOTIFICATIONS: 'STAFF_NOTIFICATIONS'
};

const NOTIFICATION_SECTIONS = [
  {
    label: 'Customer notifications',
    value: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    icon: <User size={18} />,
    path: 'customer'
  },
  {
    label: 'Merchant notifications',
    value: NOTIFICATION_SECTION_ENUM.MERCHANT_NOTIFICATIONS,
    icon: <BuildingStore size={18} />,
    path: 'merchant'
  },
  {
    label: 'Staff notifications',
    value: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    icon: <Users size={18} />,
    path: 'staff'
  }
];

const NOTIFICATION_ENUM = {
  // CUSTOMER NOTIFICATIONS
  ORDER_CONFIRMATION: 'order_confirmation',
  SHIPPING_CONFIRMATION: 'shipping_confirmation',
  ORDER_EDITED: 'order_edited',
  ORDER_CANCELLED: 'order_cancelled',
  ORDER_REFUNDED: 'order_refunded',

  // STORE NOTIFICATIONS
  ORDER_CREDITED: 'order_credited',

  // STAFF NOTIFICATIONS
  ORDER_RECEIVED: 'order_received',
  PAYOUT_RECEIVED: 'payout_received'
};

const NOTIFICATIONS = [
  // CUSTOMER NOTIFICATIONS
  {
    label: 'Order confirmation',
    description: 'Sent to the customer when an order is placed.',
    value: NOTIFICATION_ENUM.ORDER_CONFIRMATION,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'order-confirmation',
    group: 'Order processing',
    warnings: [
      {
        message: 'Prices displayed include store markup and may vary by store.',
        color: null
      }
    ]
  },
  {
    label: 'Shipping confirmation',
    description: 'Sent to the customer when an order has shipped.',
    value: NOTIFICATION_ENUM.SHIPPING_CONFIRMATION,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'shipping-confirmation',
    group: 'Order processing'
  },
  {
    label: 'Order cancelled',
    description:
      'Sent to the customer when the order or any products are cancelled.',
    value: NOTIFICATION_ENUM.ORDER_CANCELLED,
    section: NOTIFICATION_SECTION_ENUM.CUSTOMER_NOTIFICATIONS,
    path: 'order-cancelled',
    group: 'Order changes',
    warnings: [
      {
        message: 'Prices displayed include store markup and may vary by store.',
        color: null
      }
    ]
  },

  // MERCHANT NOTIFICATIONS
  {
    label: 'Order credited',
    description: 'Sent to the merchant when an order is refunded.',
    value: NOTIFICATION_ENUM.ORDER_CREDITED,
    section: NOTIFICATION_SECTION_ENUM.MERCHANT_NOTIFICATIONS,
    path: 'order-credited',
    group: 'Order changes'
  },

  // STAFF NOTIFICATIONS
  {
    label: 'Order received',
    description: 'Sent to staff when an order is received.',
    value: NOTIFICATION_ENUM.ORDER_RECEIVED,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'order-received',
    group: 'Order processing'
  },
  {
    label: 'Payout received',
    description: 'Sent to staff when a payout is issued.',
    value: NOTIFICATION_ENUM.PAYOUT_RECEIVED,
    section: NOTIFICATION_SECTION_ENUM.STAFF_NOTIFICATIONS,
    path: 'payout-received',
    group: 'Payments'
  }
];

export { NOTIFICATION_SECTION_ENUM, NOTIFICATION_SECTIONS, NOTIFICATIONS };
