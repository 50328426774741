import onApiRequest from './ApiRequestHandler';
import createDataProvider from './createDataProvider';
import { providerContextReducer } from './providerContextReducer';

const initialPaginatedState = {
  pageIndex: 1,
  pageSize: 50,
  totalCount: 0,
  totalPages: 0,
  totalNoFilterCount: 0,
  data: [],
  filter: null,
  loading: false,
  error: null
};

const initialState = {
  ecomAdminAnalyticsOverview: { value: null, loading: false, error: null },
  ecomAdminAnalytics: { value: null, loading: false, error: null },
  ecomAdminReport: { value: null, loading: false, error: null },
  ecomStore: { value: null, loading: false, error: null },
  ecomVendor: { value: null, loading: false, error: null },
  ecomVendorPayout: { value: null, loading: false, error: null },
  paginatedEcomStores: { ...initialPaginatedState },
  paginatedEcomVendorPayouts: { ...initialPaginatedState },
  paginatedEcomVendors: { ...initialPaginatedState }
};

// #region Stores
const fetchEcomStore = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStore',
      url: `/api/staff/ecommerce/stores/${pkEcomStore}`,
      type: 'get'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchPaginatedEcomStores = (dispatch) => async (
  { search, status, teamSearch, sort, page, pageSize, appendResults },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomStores',
      url: `/api/staff/ecommerce/stores/summaries`,
      type: 'get',
      params: {
        search,
        status,
        teamSearch,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Vendors
const fetchEcomVendor = (dispatch) => async (
  pkEcomVendor,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendor',
      url: `/api/staff/ecommerce/vendors/${pkEcomVendor}`,
      type: 'get'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchPaginatedEcomVendors = (dispatch) => async (
  { search, status, sort, page, pageSize, appendResults },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomVendors',
      url: `/api/staff/ecommerce/vendors/summaries`,
      type: 'get',
      params: {
        search,
        status,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchPaginatedEcomVendorPayouts = (dispatch) => async (
  {
    pkEcomVendor,
    search,
    startDate,
    endDate,
    sort,
    page,
    pageSize,
    skipLoading,
    appendResults
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomVendorPayouts',
      url: `/api/staff/ecommerce/vendors/payouts`,
      type: 'get',
      params: {
        pkEcomVendor,
        search,
        startDate,
        endDate,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomVendorPayout = (dispatch) => async (
  pkEcomVendor,
  { pkEcomVendorPayout, startDate, endDate },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorPayout',
      url: `/api/staff/ecommerce/vendors/${pkEcomVendor}/payouts`,
      type: 'get',
      params: { pkEcomVendorPayout, startDate, endDate }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createEcomVendorPayout = (dispatch) => async (
  pkEcomVendor,
  { chequeId, description, pkEcomOrders, payoutAmount },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/staff/ecommerce/vendors/${pkEcomVendor}/payouts`,
      type: 'post',
      data: { chequeId, description, pkEcomOrders, payoutAmount }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Reports & Analytics
const fetchAdminAnalyticsOverview = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomAdminAnalyticsOverview',
      url: `/api/staff/ecommerce/analytics/overview`,
      type: 'get',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchAdminAnalytics = (dispatch) => async (
  { startDate, endDate, compareStartDate, compareEndDate },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomAdminAnalytics',
      url: `/api/staff/ecommerce/analytics`,
      type: 'get',
      successAction: 'success',
      params: {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const generateAdminReport = (dispatch) => async (
  {
    uuid,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    periodInterval,
    groupBy,
    headers,
    options
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomAdminReport',
      url: `/api/staff/ecommerce/reports/generate/${uuid}`,
      type: 'post',
      successAction: 'success',
      data: {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        periodInterval,
        groupBy,
        headers,
        options
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

export const { Provider, Context } = createDataProvider(
  providerContextReducer,
  {
    createEcomVendorPayout,
    fetchAdminAnalytics,
    fetchAdminAnalyticsOverview,
    fetchEcomStore,
    fetchEcomVendor,
    fetchEcomVendorPayout,
    fetchPaginatedEcomStores,
    fetchPaginatedEcomVendorPayouts,
    fetchPaginatedEcomVendors,
    generateAdminReport
  },
  initialState
);
