import React from 'react';
import { HoverCard } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const CustomTooltip = ({ label, children, ...rest }) => (
  <HoverCard
    closeDelay={500}
    openDelay={150}
    position="bottom"
    shadow="md"
    width={200}
    withArrow
    {...rest}
  >
    <HoverCard.Target>
      <AppFlexbox>{children}</AppFlexbox>
    </HoverCard.Target>
    <HoverCard.Dropdown>
      {typeof label === 'string' ? (
        <AppText style={{ fontSize: 14 }}>{label}</AppText>
      ) : (
        label
      )}
    </HoverCard.Dropdown>
  </HoverCard>
);

CustomTooltip.propTypes = {
  children: PropTypes.node,
  label: PropTypes.any
};

export default CustomTooltip;
