import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumberFormatter, Pagination, Skeleton, Table } from '@mantine/core';
import dayjs from 'dayjs';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { PAYMENT_TYPE_ENUM } from '../../../config/constants';
import { formatUtcDate } from '../../../helpers/format';

const OrderTransactionPaymentTable = ({ loading, storeOrder }) => {
  const MAX_PAGE_LENGTH = 5;
  const [pageIndex, setPageIndex] = useState(1);
  const payments =
    storeOrder?.ecomOrderPayments.filter(
      (p) =>
        p.amount > 0 &&
        p.fkRegFormSubmissionPaymentType.toString() !== PAYMENT_TYPE_ENUM.CREDIT
    ) ?? [];

  return (
    <AppStack>
      <AppStack style={{ overflow: 'auto', flex: 1, gap: 0 }}>
        <Table
          horizontalSpacing="md"
          style={{ border: 'solid 1px #ced4da' }}
          verticalSpacing="md"
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>
                <AppText
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  STATUS
                </AppText>
              </Table.Th>
              <Table.Th>
                <AppText
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  DATE
                </AppText>
              </Table.Th>
              <Table.Th>
                <AppText
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  TRANSACTION ID
                </AppText>
              </Table.Th>
              <Table.Th style={{ borderRight: 'solid 1px #ced4da' }}>
                <AppText
                  style={{
                    whiteSpace: 'nowrap',
                    textAlign: 'end',
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  GROSS AMOUNT
                </AppText>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Th>
                  <Skeleton height={18} width={80} />
                </Table.Th>
                <Table.Th>
                  <Skeleton height={18} width={200} />
                </Table.Th>
                <Table.Th>
                  <Skeleton height={18} width={200} />
                </Table.Th>
                <Table.Th style={{ borderRight: 'solid 1px #ced4da' }}>
                  <Skeleton
                    height={18}
                    style={{ marginLeft: 'auto' }}
                    width={80}
                  />
                </Table.Th>
              </Table.Tr>
            ) : payments.length === 0 ? (
              <Table.Tr>
                <Table.Td
                  colSpan="6"
                  style={{ padding: 50, textAlign: 'center' }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    - No Payments Received -
                  </AppText>
                </Table.Td>
              </Table.Tr>
            ) : (
              payments
                ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

                .slice(
                  -MAX_PAGE_LENGTH + pageIndex * MAX_PAGE_LENGTH,
                  pageIndex * MAX_PAGE_LENGTH
                )
                .map((p) => {
                  const statusInfo =
                    p.fkRegFormSubmissionPaymentType.toString() ===
                    PAYMENT_TYPE_ENUM.REFUND
                      ? {
                          color: '#c40000',
                          label: 'REFUND'
                        }
                      : p.fkRegFormSubmissionPaymentType.toString() ===
                        PAYMENT_TYPE_ENUM.REIMBURSEMENT
                      ? {
                          color: 'dodgerblue',
                          label: 'REIMBURSEMENT'
                        }
                      : {
                          color: '#067D62',
                          label: 'PAID'
                        };

                  const paymentVendors = p.ecomOrderProductPayments.reduce(
                    (r, c) => {
                      const vendor =
                        storeOrder.ecomOrderProducts.find(
                          (f) => f.pkEcomOrderProduct === c.fkEcomOrderProduct
                        )?.ecomStoreProductVariant.ecomStoreProduct
                          .ecomVendorProduct.vendorName || '';
                      if (!r.includes(vendor)) {
                        r.push(vendor);
                      }
                      return r;
                    },
                    []
                  );

                  return (
                    <Table.Tr key={p.pkEcomOrderPayment}>
                      <Table.Th>
                        <AppStack style={{ gap: 0 }}>
                          <AppText
                            style={{
                              whiteSpace: 'nowrap',
                              fontSize: 14,
                              color: statusInfo.color,
                              fontWeight: 650
                            }}
                          >
                            {statusInfo.label}
                          </AppText>
                          {paymentVendors.length === 1 && (
                            <AppText
                              style={{
                                whiteSpace: 'nowrap',
                                fontSize: 14,
                                color: '#666',
                                fontWeight: 'normal'
                              }}
                            >
                              {paymentVendors[0]}
                            </AppText>
                          )}
                        </AppStack>
                      </Table.Th>
                      <Table.Th>
                        <AppText
                          style={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}
                        >
                          {dayjs(formatUtcDate(p.createdAt)).format(
                            'MMMM D, YYYY h:mm A'
                          )}
                        </AppText>
                      </Table.Th>
                      <Table.Th>
                        <AppText
                          style={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}
                        >
                          {p.sourceTransaction}
                        </AppText>
                      </Table.Th>
                      <Table.Th style={{ borderRight: 'solid 1px #ced4da' }}>
                        <AppText
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'end',
                            fontWeight: 'normal'
                          }}
                        >
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{
                              fontSize: 14,
                              fontWeight: 650
                            }}
                            suffix=" CAD"
                            thousandSeparator
                            value={p.amount / 100}
                          />
                        </AppText>
                      </Table.Th>
                    </Table.Tr>
                  );
                })
            )}
          </Table.Tbody>
        </Table>
      </AppStack>
      <AppFlexbox style={{ justifyContent: 'center' }}>
        <AppStack>
          {!loading && payments.length > MAX_PAGE_LENGTH && (
            <Pagination
              color="blue"
              onChange={setPageIndex}
              total={Math.ceil(payments.length / MAX_PAGE_LENGTH)}
              value={pageIndex}
            />
          )}
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

OrderTransactionPaymentTable.propTypes = {
  loading: PropTypes.bool,
  onShowRefund: PropTypes.func,
  showAdminActions: PropTypes.bool,
  storeOrder: PropTypes.object
};

export default OrderTransactionPaymentTable;
