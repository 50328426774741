import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Badge,
  Button,
  Divider,
  NumberFormatter,
  Skeleton,
  Table
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import CustomTooltip from '../../common/CustomTooltip';

const OrderProductsInfoCard = ({
  title,
  description,
  productData,
  hideStatus,
  onNotify,
  fulfillLink,
  loading,
  showVendorName,
  showLimit,
  actionButton,
  showReimbursement,
  isCancelled,
  onAcceptOrder,
  onViewTransactions,
  isVendorView
}) => {
  const totalUnitCount =
    productData?.reduce(
      (acc, p) => acc + Math.max(0, p.quantity - p.cancelledCount),
      0
    ) ?? 0;

  const hasPendingProducts =
    productData?.some(
      (p) =>
        p.fkEcomOrderProductStatus?.toString() ===
        ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
    ) || false;
  const showAcceptButton = hasPendingProducts && !!onAcceptOrder;

  const isFulfilled =
    productData?.every(
      (p) =>
        p.fkEcomOrderProductStatus?.toString() !==
          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
        p.fkEcomOrderProductStatus?.toString() !==
          ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
    ) || false;
  const showFulfillmentButtons = !isFulfilled && (onNotify || fulfillLink);

  const shippedProductCount =
    productData?.reduce((acc, p) => acc + p.shippedCount, 0) || 0;

  return !loading && productData ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            padding: 16,
            paddingBottom: 8,
            gap: 8,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppFlexbox style={{ alignItems: 'center' }}>
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>
              {title || 'Products'}
            </AppText>

            {!hideStatus &&
              (isCancelled ? (
                <Badge color="#C40000" variant="filled">
                  Cancelled
                </Badge>
              ) : shippedProductCount >= totalUnitCount ? (
                <Badge color="green" size="md">
                  Fulfilled
                </Badge>
              ) : (
                <Badge color="orange" size="md">
                  {shippedProductCount}/{totalUnitCount} Unfulfilled
                </Badge>
              ))}
          </AppFlexbox>
          {actionButton}
        </AppFlexbox>

        {description && (
          <AppText
            style={{
              padding: 8,
              textAlign: 'center',
              fontSize: 14,
              color: '#666',
              fontStyle: 'italic'
            }}
          >
            {description}
          </AppText>
        )}
        <AppText />
        {productData.length === 0 ? (
          <>
            <Divider style={{ marginTop: 8 }} />
            <AppText
              style={{
                textAlign: 'center',
                color: '#666',
                fontWeight: 500,
                fontSize: 14,
                padding: 12
              }}
            >
              {isCancelled
                ? 'All products in this order have been cancelled.'
                : 'This order only contains group order products.'}
            </AppText>
          </>
        ) : (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Product
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox style={{ justifyContent: 'start' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Quantity
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
                <Table.Th style={{ padding: '8px 16px' }}>
                  <AppFlexbox style={{ justifyContent: 'end' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Total
                    </AppText>
                  </AppFlexbox>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {productData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p) => (
                  <Table.Tr key={p.key}>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <VendorOrderProductPreview
                        hideStatus={hideStatus}
                        product={p}
                        showVendorName={showVendorName}
                      />
                    </Table.Td>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <AppFlexbox style={{ gap: 8, justifyContent: 'start' }}>
                        {showReimbursement ? (
                          <AppText style={{ fontSize: 14 }}>
                            {p.cancelledCount}
                          </AppText>
                        ) : (
                          <AppText style={{ fontSize: 14 }}>
                            {Math.max(0, p.quantity - p.cancelledCount)}
                            {showLimit &&
                              p.minimumQuantity &&
                              ` / ${p.minimumQuantity}`}
                          </AppText>
                        )}
                      </AppFlexbox>
                    </Table.Td>
                    <Table.Td
                      style={{ padding: '8px 16px', alignContent: 'start' }}
                    >
                      <AppFlexbox style={{ justifyContent: 'end' }}>
                        <AppStack style={{ gap: 0, alignItems: 'end' }}>
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{ fontSize: 14 }}
                            thousandSeparator
                            value={
                              (p.unitCost *
                                (showReimbursement
                                  ? p.cancelledCount
                                  : Math.max(
                                      0,
                                      p.quantity - p.cancelledCount
                                    ))) /
                              100
                            }
                          />
                          {!isVendorView && showReimbursement && (
                            <>
                              <AppText style={{ fontSize: 14 }}>
                                Cost:{' '}
                                <NumberFormatter
                                  decimalScale={2}
                                  fixedDecimalScale
                                  prefix="$"
                                  style={{ fontSize: 14, color: '#C40000' }}
                                  thousandSeparator
                                  value={
                                    (p.refundValue * p.cancelledCount * -1) /
                                    100
                                  }
                                />
                              </AppText>
                              <CustomTooltip
                                disabled={
                                  p.reimbursedAmount >=
                                  p.refundValue * p.cancelledCount
                                }
                                label={
                                  <AppStack style={{ gap: 8 }}>
                                    <AppText
                                      style={{ fontSize: 14, fontWeight: 500 }}
                                    >
                                      Reduced when cancelled by vendor.
                                    </AppText>

                                    <AppText style={{ fontSize: 14 }}>
                                      Only SportsHeadz fees are recovered from
                                      the product automatically.
                                    </AppText>

                                    <AppText style={{ fontSize: 14 }}>
                                      When a vendor credit is issued, it will be
                                      displayed as a separate transaction.
                                    </AppText>

                                    <Anchor
                                      onClick={onViewTransactions}
                                      style={{
                                        color: 'dodgerblue',
                                        fontSize: 14
                                      }}
                                    >
                                      View transactions
                                    </Anchor>
                                  </AppStack>
                                }
                                styles={{ dropdown: { zIndex: 1 } }}
                                width={300}
                              >
                                <AppFlexbox
                                  style={{ gap: 5, alignItems: 'center' }}
                                >
                                  {p.reimbursedAmount <
                                    p.refundValue * p.cancelledCount && (
                                    <AlertCircle color="dodgerblue" size={18} />
                                  )}

                                  <AppText style={{ fontSize: 14 }}>
                                    Reimbursed:{' '}
                                    <NumberFormatter
                                      decimalScale={2}
                                      fixedDecimalScale
                                      prefix="+$"
                                      style={{
                                        fontSize: 14,
                                        color: '#067D62'
                                      }}
                                      thousandSeparator
                                      value={p.reimbursedAmount / 100}
                                    />
                                  </AppText>
                                </AppFlexbox>
                              </CustomTooltip>
                            </>
                          )}
                        </AppStack>
                      </AppFlexbox>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
        )}

        {(showFulfillmentButtons || showAcceptButton) && (
          <>
            <Divider />

            <AppFlexbox
              style={{
                padding: 12,
                alignItems: 'center',
                justifyContent: 'end'
              }}
            >
              {onNotify && (
                <Button
                  color="dark"
                  onClick={onNotify}
                  size="sm"
                  variant="outline"
                >
                  Notify customer
                </Button>
              )}

              {fulfillLink && (
                <Button
                  color="dark"
                  component={Link}
                  size="sm"
                  to={fulfillLink}
                  variant="filled"
                >
                  Fulfill order
                </Button>
              )}

              {showAcceptButton && (
                <Button
                  color="blue"
                  onClick={onAcceptOrder}
                  size="sm"
                  variant="filled"
                >
                  Accept order
                </Button>
              )}
            </AppFlexbox>
          </>
        )}
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox style={{ padding: 16, paddingBottom: 8 }}>
          <Skeleton height={18} width="20%" />
        </AppFlexbox>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
              </Table.Th>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="100%" />
                </AppFlexbox>
              </Table.Th>
              <Table.Th style={{ width: '100%', padding: '8px 16px' }}>
                <AppFlexbox style={{ flex: 1, justifyContent: 'end' }}>
                  <Skeleton height={18} width="100%" />
                </AppFlexbox>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.from(Array(2)).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Table.Tr key={i}>
                <Table.Td
                  style={{
                    width: '100%',
                    padding: '8px 16px',
                    alignContent: 'start'
                  }}
                >
                  <VendorOrderProductPreview loading />
                </Table.Td>
                <Table.Td
                  style={{ padding: '8px 16px', alignContent: 'start' }}
                >
                  <Skeleton height={18} width={60} />
                </Table.Td>
                <Table.Td
                  style={{ padding: '8px 16px', alignContent: 'start' }}
                >
                  <AppFlexbox style={{ justifyContent: 'end' }}>
                    <Skeleton height={18} width={100} />
                  </AppFlexbox>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </AppStack>
    </AppCard>
  );
};

OrderProductsInfoCard.propTypes = {
  actionButton: PropTypes.any,
  description: PropTypes.string,
  fulfillLink: PropTypes.string,
  hideStatus: PropTypes.bool,
  isCancelled: PropTypes.bool,
  isVendorView: PropTypes.bool,
  loading: PropTypes.bool,
  onAcceptOrder: PropTypes.func,
  onNotify: PropTypes.func,
  onViewTransactions: PropTypes.func,
  productData: PropTypes.array,
  showLimit: PropTypes.bool,
  showReimbursement: PropTypes.bool,
  showVendorName: PropTypes.bool,
  title: PropTypes.string
};

export default OrderProductsInfoCard;
