import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ArrowLeft,
  ChevronDown,
  CreditCard,
  Dots,
  Download,
  InfoCircle,
  Receipt,
  X
} from 'tabler-icons-react';
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Menu,
  Skeleton
} from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import AppMenu from '../../common/AppMenu';
import OrderTransactionModal from './OrderTransactionModal';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_ORDER_STATUS_ENUM,
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';
import { translateStoreOrderData } from '../../../helpers/storeHelper';
import StoreOrderContributorsInfoCard from './StoreOrderContributorsInfoCard';
import OrderCancelModal from './OrderCancelModal';

const StoreOrderDetailsView = ({ loading, ecomOrder, isBulkOrders }) => {
  const { isLargeMobileOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { search } = useLocation();
  const modalAction = new URLSearchParams(search).get('modal');
  const {
    ecomOrderProducts: productData,
    groupedEcomOrderProducts,
    ecomCustomers
  } = translateStoreOrderData(ecomOrder);
  const regularProducts = productData.filter(
    (f) => !f.isBulkShipment && f.returnedCount + f.cancelledCount < f.quantity
  );
  const groupOrderProducts = productData.filter(
    (f) => f.isBulkShipment && f.returnedCount + f.cancelledCount < f.quantity
  );
  const returnedProducts = productData.filter((f) => f.cancelledCount > 0);

  const totalUnitCost = productData.reduce((acc, p) => acc + p.totalCost, 0);
  const totalRegularProductUnitCost =
    regularProducts.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;
  const totalBulkProductUnitCost =
    groupOrderProducts.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;

  const vendorPayout = productData.reduce((r, c) => r + c.vendorCost, 0) || 0;
  const expectedVsRecordedServiceFeeDifference = ecomOrder
    ? ecomOrder.checkoutBalance.serviceFeeRecordedInCents -
      ecomOrder.checkoutBalance.baseServiceFeeInCents
    : 0;
  const totalFees = ecomOrder
    ? productData.reduce((r, c) => r + c.serviceFees, 0) +
      ecomOrder.checkoutBalance.tieredPricingFeeInCents +
      expectedVsRecordedServiceFeeDifference
    : 0;

  const { payments = [], reimbursement = [], refunds = [] } =
    ecomOrder?.ecomOrderPayments
      .sort((a, b) => new Date(b.created) - new Date(a.created))
      .reduce(
        (r, c) => {
          if (
            c.fkRegFormSubmissionPaymentType.toString() ===
            PAYMENT_TYPE_ENUM.REIMBURSEMENT.toString()
          ) {
            r.reimbursement.push(c);
          }
          else if (
            c.fkRegFormSubmissionPaymentType.toString() ===
            PAYMENT_TYPE_ENUM.REFUND.toString()
          ) {
            r.refunds.push(c);
          }
          else {
            r.payments.push(c);
          }
          return r;
        },
        {
          payments: [],
          reimbursement: [],
          refunds: []
        }
      ) || {};

  const paidAmount = payments.reduce((r, c) => r + c.amount, 0);
  const refundAmount = refunds.reduce((r, c) => r + c.amount, 0);
  const reimbursementAmount = reimbursement.reduce((r, c) => r + c.amount, 0);

  const isFullyCancelled =
    productData.length > 0 &&
    productData.every((p) => p.cancelledCount >= p.quantity);
  const isFullyRefunded = refundAmount >= paidAmount;
  const isPendingFulfillment = productData.some(
    (p) =>
      p.fkEcomOrderProductStatus.toString() ===
      ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
  );

  const baseUrl = isBulkOrders ? '/merchant/group-orders' : '/merchant/orders';

  const firstPayment = ecomOrder?.ecomOrderPayments.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  )[0];
  const accountPayee = firstPayment?.ecomStorePaymentProvider?.accountId;
  const isEscrowPayment = !accountPayee;

  const baseStoreProfit = ecomOrder
    ? (ecomOrder.checkoutBalance.totalInCents || totalUnitCost) -
      vendorPayout -
      totalFees
    : 0;
  const avaialbleBalance = ecomOrder
    ? (ecomOrder.checkoutBalance.totalInCents || totalUnitCost) -
      vendorPayout -
      refundAmount -
      totalFees +
      reimbursementAmount
    : 0;

  useEffect(() => {
    if (modalAction) {
      switch (modalAction) {
        case 'cancel':
          onOpenModal('cancel_order');
          break;
        default:
          break;
      }
    }
  }, [modalAction]);

  const renderStatusBadges = () => (
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      {isFullyCancelled ? (
        <Badge color="#C40000" variant="filled">
          Cancelled
        </Badge>
      ) : ecomOrder?.fkEcomOrderStatus.toString() ===
        ECOM_ORDER_STATUS_ENUM.ACCUMULATING.toString() ? (
        <Badge color="blue" variant="filled">
          Accumulating
        </Badge>
      ) : (
          ecomOrder.isBulkShipment
            ? groupOrderProducts.every(
                (p) =>
                  p.fkEcomOrderProductStatus?.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
                  p.fkEcomOrderProductStatus?.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
              )
            : regularProducts.every(
                (p) =>
                  p.fkEcomOrderProductStatus?.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
                  p.fkEcomOrderProductStatus?.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
              )
        ) ? (
        <Badge color="green" variant="filled">
          Fulfilled
        </Badge>
      ) : (
        <Badge color="dark" variant="light">
          Unfulfilled
        </Badge>
      )}
      {refunds.length > 0 ? (
        refundAmount >= paidAmount ? (
          <Badge color="#C40000" variant="filled">
            Refunded
          </Badge>
        ) : (
          <Badge color="grey" variant="filled">
            Partially refunded
          </Badge>
        )
      ) : (
        <Badge color="blue" variant="filled">
          Paid
        </Badge>
      )}
      {!ecomOrder?.isBulkShipment && isEscrowPayment && (
        <Badge color="blue" variant="filled">
          Escrow
        </Badge>
      )}
    </AppFlexbox>
  );

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      {!isBulkOrders && (
        <>
          <OrderTransactionModal
            ecomOrder={ecomOrder}
            isOpen={
              modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.VIEW
            }
            onClose={onCloseModal}
          />
          <OrderCancelModal
            ecomOrder={ecomOrder}
            isOpen={
              modalState.isOpen &&
              (modalState.action === 'cancel_order' ||
                modalState.action === 'cancel_products')
            }
            isProductCancel={modalState.action === 'cancel_products'}
            onClose={onCloseModal}
          />
        </>
      )}

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={baseUrl}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 10 }}>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppText
                  style={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 700,
                    wordBreak: 'break-word'
                  }}
                >
                  Order #{ecomOrder.pkEcomOrder}
                </AppText>
                <AppFlexbox visibleFrom="xsm">
                  {renderStatusBadges()}
                </AppFlexbox>
              </AppFlexbox>

              <AppText
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  color: '#666'
                }}
              >
                Placed{' '}
                {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                  'MMMM D, YYYY'
                )}{' '}
                at{' '}
                {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
              </AppText>
            </AppStack>
            <AppFlexbox hiddenFrom="xsm">{renderStatusBadges()}</AppFlexbox>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox
          style={{
            alignItems: 'center',
            gap: 8,
            marginTop: 5
          }}
        >
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14, flex: 1 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item leftSection={<Download size={18} />} onClick={() => {}}>
              Export order
            </Menu.Item>
            {!isBulkOrders && (
              <>
                <Menu.Item
                  leftSection={<Receipt size={18} />}
                  onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.VIEW)}
                >
                  View transactions
                </Menu.Item>

                {(!isFullyRefunded || !isFullyCancelled) && (
                  <>
                    <Menu.Divider />
                    {!isFullyCancelled && isPendingFulfillment && (
                      <Menu.Item
                        leftSection={<X color="#c40000" size={18} />}
                        onClick={() => onOpenModal('cancel_order')}
                        style={{ color: '#c40000' }}
                      >
                        Cancel order
                      </Menu.Item>
                    )}
                    {!isFullyRefunded && (
                      <Menu.Item
                        component={Link}
                        leftSection={<CreditCard color="#c40000" size={18} />}
                        style={{ color: '#c40000' }}
                        to={`/merchant/orders/${ecomOrder.pkEcomOrder}/refund`}
                      >
                        Refund order
                      </Menu.Item>
                    )}
                  </>
                )}
              </>
            )}
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          {ecomOrder.isBulkShipment ? (
            <>
              <OrderProductsInfoCard
                hideStatus
                productData={groupedEcomOrderProducts}
                showLimit
                title="Included products"
              />
              <OrderProductsInfoCard
                productData={productData}
                title="Variants"
              />
            </>
          ) : (
            <>
              <OrderProductsInfoCard
                actionButton={
                  isPendingFulfillment &&
                  !isFullyCancelled &&
                  regularProducts.some((p) => p.amountRemaining > 0) && (
                    <AppMenu
                      control={
                        <ActionIcon color="dark" variant="subtle">
                          <Dots />
                        </ActionIcon>
                      }
                    >
                      <Menu.Item
                        leftSection={<X color="#c40000" size={18} />}
                        onClick={() => onOpenModal('cancel_products')}
                        style={{ color: '#c40000' }}
                      >
                        Cancel products
                      </Menu.Item>
                    </AppMenu>
                  )
                }
                isCancelled={isFullyCancelled}
                productData={regularProducts}
                showVendorName
                title={ecomOrder.isBulkShipment ? 'Variants' : 'Products'}
              />

              {groupOrderProducts.length > 0 && (
                <OrderProductsInfoCard
                  actionButton={
                    !isFullyCancelled &&
                    groupOrderProducts.some((p) => p.amountRemaining > 0) && (
                      <AppMenu
                        control={
                          <ActionIcon color="dark" variant="subtle">
                            <Dots />
                          </ActionIcon>
                        }
                      >
                        <Menu.Item
                          leftSection={<X color="#c40000" size={18} />}
                          onClick={() => onOpenModal('cancel_products')}
                          style={{ color: '#c40000' }}
                        >
                          Cancel products
                        </Menu.Item>
                      </AppMenu>
                    )
                  }
                  description="These products are grouped with items from other orders and will be fulfilled separately."
                  productData={groupOrderProducts}
                  showVendorName
                  title="Grouped products"
                />
              )}
            </>
          )}

          <OrderPaymentInfoCard
            actionMenu={
              !isBulkOrders && (
                <AppMenu
                  control={
                    <ActionIcon color="dark" variant="subtle">
                      <Dots />
                    </ActionIcon>
                  }
                >
                  <Menu.Item
                    leftSection={<Receipt size={18} />}
                    onClick={() => {
                      onOpenModal(VIEW_ACTIONS_ENUM.VIEW);
                    }}
                  >
                    View transactions
                  </Menu.Item>
                  {(!isFullyRefunded || !isFullyCancelled) && (
                    <>
                      <Menu.Divider />
                      {!isFullyCancelled && isPendingFulfillment && (
                        <Menu.Item
                          leftSection={<X color="#c40000" size={18} />}
                          onClick={() => onOpenModal('cancel_order')}
                          style={{ color: '#c40000' }}
                        >
                          Cancel order
                        </Menu.Item>
                      )}
                      {!isFullyRefunded && (
                        <Menu.Item
                          component={Link}
                          leftSection={<CreditCard color="#c40000" size={18} />}
                          style={{ color: '#c40000' }}
                          to={`/merchant/orders/${ecomOrder.pkEcomOrder}/refund`}
                        >
                          Refund order
                        </Menu.Item>
                      )}
                    </>
                  )}
                </AppMenu>
              )
            }
            payments={[
              ...(groupOrderProducts.length > 0 && regularProducts.length > 0
                ? [
                    {
                      key: 1,
                      group: 1,
                      label: 'Regular products',
                      color: '#666',
                      description: singularPluralFormat(
                        regularProducts.reduce((r, c) => r + c.quantity, 0),
                        'item',
                        'items'
                      ),
                      value: totalRegularProductUnitCost / 100
                    },
                    {
                      key: 2,
                      group: 1,
                      label: 'Grouped products',
                      color: '#666',
                      description: singularPluralFormat(
                        groupOrderProducts.reduce((r, c) => r + c.quantity, 0),
                        'item',
                        'items'
                      ),
                      value: totalBulkProductUnitCost / 100
                    }
                  ]
                : [
                    {
                      key: 1,
                      group: 1,
                      label: 'Subtotal',
                      color: '#666',
                      description: singularPluralFormat(
                        productData.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        ),
                        'item',
                        'items'
                      ),
                      value: totalUnitCost / 100
                    }
                  ]),
              ...(ecomOrder.checkoutBalance.tieredPricingFeeInCents > 0
                ? [
                    {
                      key: 3,
                      group: 1,
                      label: 'Platform fee',
                      color: '#666',
                      description: '-',
                      value:
                        ecomOrder.checkoutBalance.tieredPricingFeeInCents / 100
                    }
                  ]
                : []),
              {
                key: 4,
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value:
                  (ecomOrder.checkoutBalance.totalInCents || totalUnitCost) /
                  100
              },
              {
                key: 5,
                group: 2,
                label: 'Vendor payout',
                color: '#666',
                value: (vendorPayout / 100) * -1
              },
              {
                key: 6,
                group: 2,
                label: 'Fees',
                color: '#666',
                value: (totalFees / 100) * -1,
                tooltipIcon: <InfoCircle color="#C40000" size={18} />,
                tooltip: expectedVsRecordedServiceFeeDifference !== 0 && (
                  <AppStack style={{ gap: 8 }}>
                    <AppText style={{ fontSize: 14 }}>
                      Recorded service fee{' '}
                      <b>
                        {currencyFormat(
                          expectedVsRecordedServiceFeeDifference / 100
                        )}{' '}
                        higher
                      </b>{' '}
                      than anticipated.
                    </AppText>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Click for more info.
                    </AppText>
                  </AppStack>
                ),
                infoTitle: 'Service fee discrepancy',
                infoContent:
                  expectedVsRecordedServiceFeeDifference !== 0 ? (
                    <AppStack style={{ gap: 8 }}>
                      <AppText style={{ fontSize: 14 }}>
                        Recorded service fee{' '}
                        <b>
                          {currencyFormat(
                            expectedVsRecordedServiceFeeDifference / 100
                          )}{' '}
                          {expectedVsRecordedServiceFeeDifference < 0
                            ? 'lower'
                            : 'higher'}
                        </b>{' '}
                        than anticipated.
                      </AppText>
                      <AppText style={{ fontSize: 14 }}>
                        This discrepancy may be due to additional service fees
                        applied by Stripe, such as a{' '}
                        <b>+0.8% fee for international cards</b>.
                      </AppText>
                      <AppText style={{ fontSize: 14 }}>
                        For more details, please visit{' '}
                        <Anchor
                          component="a"
                          href="https://stripe.com/en-ca/pricing"
                          style={{ fontSize: 14, color: 'dodgerblue' }}
                          target="_blank"
                        >
                          Stripe pricing
                        </Anchor>{' '}
                        or contact support if you have any questions.
                      </AppText>

                      <AppText
                        style={{
                          marginTop: 8,
                          fontSize: 14,
                          fontStyle: 'italic',
                          fontWeight: 500,
                          color: '#666',
                          textAlign: 'center'
                        }}
                      >
                        This variance will directly impact the store's profit
                        margin.
                      </AppText>
                    </AppStack>
                  ) : null
              },
              ...(refunds.length > 0 || reimbursementAmount > 0
                ? [
                    {
                      key: 8,
                      group: 3,
                      label: 'Received',
                      weight: 500,
                      description: '',
                      value: baseStoreProfit / 100
                    },
                    {
                      key: 10,
                      group: 3,
                      label: 'Reimbursed',
                      prefix: reimbursementAmount > 0 ? '+$' : '$',
                      color: '#666',
                      valueColor: reimbursementAmount > 0 ? '#067D62' : '#666',
                      description: '',
                      value: reimbursementAmount / 100
                    },
                    {
                      key: 9,
                      group: 3,
                      label: 'Refunded',
                      color: '#c40000',
                      description: '',
                      value: (refundAmount * -1) / 100
                    }
                  ]
                : []),
              {
                key: 7,
                group: 4,
                label: 'Profit',
                weight: 500,
                description: '',
                tooltipIcon: <InfoCircle color="dodgerblue" size={18} />,
                tooltip: isEscrowPayment && avaialbleBalance > baseStoreProfit && (
                  <AppStack style={{ gap: 8 }}>
                    <AppText style={{ fontSize: 14 }}>
                      You cannot claim more than the amount received from
                      reimbursements for escrow payments.
                    </AppText>
                  </AppStack>
                ),
                value:
                  (isEscrowPayment
                    ? Math.min(baseStoreProfit, avaialbleBalance)
                    : avaialbleBalance) / 100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomOrderShipping={ecomOrder.ecomOrderShipping}
            loading={loading}
            productData={productData}
          />

          {returnedProducts.length > 0 && (
            <OrderProductsInfoCard
              description="Cancelled product will not be fulfilled."
              hideStatus
              onViewTransactions={() => onOpenModal(VIEW_ACTIONS_ENUM.VIEW)}
              productData={returnedProducts}
              showReimbursement
              showVendorName
              title="Cancelled products"
            />
          )}
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            billingAddress={
              payments.find((p) => !!p.ecomCustomerAddressBilling)
                ?.ecomCustomerAddressBilling
            }
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/merchant/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
          />

          {ecomOrder.isBulkShipment && (
            <StoreOrderContributorsInfoCard
              ecomCustomers={ecomCustomers}
              ecomOrder={ecomOrder}
            />
          )}
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={baseUrl}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />
          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

StoreOrderDetailsView.propTypes = {
  loading: PropTypes.bool.isRequired,
  ecomOrder: PropTypes.object,
  isBulkOrders: PropTypes.bool
};

export default StoreOrderDetailsView;
