import React, { useContext, useEffect, useRef } from 'react';
import { Badge, Divider, NumberFormatter, Skeleton } from '@mantine/core';
import { ChevronRight } from 'tabler-icons-react';
import { Link, useLocation } from 'react-router-dom';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import PageView from '../../common/PageView';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import { getAdminBackPath } from '../../../helpers/adminHelper';

const AdminVendorPayoutBalanceView = () => {
  const hasFetched = useRef(false);
  const { search } = useLocation();
  const fromLocation = new URLSearchParams(search).get('from');
  const {
    state,
    fetchAdminAnalyticsOverview,
    fetchPaginatedEcomVendorPayouts
  } = useContext(AdminContext);
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const backPath = getAdminBackPath('/admin/vendors');

  const ecomVendors =
    state.ecomAdminAnalyticsOverview.value?.ecomVendors.filter(
      (v) => v.payoutBalance !== 0
    ) || [];
  const payoutBalance = ecomVendors.reduce((r, c) => r + c.payoutBalance, 0);
  const payoutLocked = ecomVendors.reduce((r, c) => r + c.payoutLocked, 0);

  const loading = !state.ecomAdminAnalyticsOverview.value;
  const payoutsLoading =
    !hasFetched.current || state.paginatedEcomVendorPayouts.loading;

  const {
    totalNoFilterCount: totalPayoutCount,
    data: payoutData
  } = state.paginatedEcomVendorPayouts;

  useEffect(() => {
    if (!state.ecomAdminAnalyticsOverview.value) {
      fetchAdminAnalyticsOverview();
    }
    fetchPaginatedEcomVendorPayouts({});
    hasFetched.current = true;
  }, []);

  return (
    <PageView backPath={backPath} title="Vendor payouts">
      <AppFlexbox
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{ padding: '8px 16px', gap: 8, alignItems: 'center' }}
              >
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Pending vendors
                </AppText>
                {!loading && ecomVendors.length > 0 && (
                  <Badge circle>{ecomVendors.length}</Badge>
                )}
              </AppFlexbox>
              {ecomVendors.map((v) => (
                <React.Fragment key={v.ecomVendor.pkEcomVendor}>
                  <Divider />
                  <AppFlexbox
                    className={classes['hover-action-card']}
                    component={Link}
                    style={{
                      padding: 16,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      textDecoration: 'none',
                      color: '#000'
                    }}
                    to={`/admin/vendors/${
                      v.ecomVendor.pkEcomVendor
                    }/payouts/create?from=payout-balance${
                      fromLocation ? `,${fromLocation}` : ''
                    }`}
                  >
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {v.ecomVendor.name}
                      </AppText>
                    </AppStack>

                    <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                      {v.payoutLocked > 0 && (
                        <>
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{
                              fontSize: 14,
                              textDecoration: 'line-through'
                            }}
                            thousandSeparator
                            value={(v.payoutLocked + v.payoutBalance) / 100}
                          />

                          <ChevronRight size={18} />
                        </>
                      )}
                      <AppStack>
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontSize: 14 }}
                          thousandSeparator
                          value={v.payoutBalance / 100}
                        />
                      </AppStack>
                      <ChevronRight color="#666" size={18} />
                    </AppFlexbox>
                  </AppFlexbox>
                </React.Fragment>
              ))}
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 400 }}
        >
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Amount owed today
                </AppText>
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  style={{ fontSize: 22 }}
                  thousandSeparator
                  value={payoutBalance / 100}
                />
              </AppStack>
              <Divider />
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Amount locked
                </AppText>
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  style={{ fontSize: 22 }}
                  thousandSeparator
                  value={payoutLocked / 100}
                />
              </AppStack>
              <Divider />
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Total owed
                </AppText>
                <NumberFormatter
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  style={{ fontSize: 22 }}
                  thousandSeparator
                  value={(payoutBalance + payoutLocked) / 100}
                />
              </AppStack>
            </AppStack>
          </AppCard>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{ padding: '8px 16px', gap: 8, alignItems: 'center' }}
              >
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Payouts
                </AppText>
                {!payoutsLoading && totalPayoutCount > 0 && (
                  <Badge circle>{totalPayoutCount}</Badge>
                )}
              </AppFlexbox>
              {payoutsLoading ? (
                Array.from(Array(5)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={i}>
                    <Divider />
                    <AppFlexbox
                      style={{
                        padding: 16,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppStack style={{ gap: 5 }}>
                        <Skeleton height={18} width={200} />
                        <Skeleton height={18} width={200} />
                      </AppStack>
                    </AppFlexbox>
                  </React.Fragment>
                ))
              ) : payoutData.length === 0 ? (
                <>
                  <Divider />
                  <AppFlexbox style={{ padding: 16, justifyContent: 'center' }}>
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#666',
                        textAlign: 'center'
                      }}
                    >
                      No payouts found
                    </AppText>
                  </AppFlexbox>
                </>
              ) : (
                payoutData.map((v) => (
                  <React.Fragment key={v.pkEcomVendorPayout}>
                    <Divider />
                    <AppFlexbox
                      className={classes['hover-action-card']}
                      component={Link}
                      style={{
                        padding: 16,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        textDecoration: 'none',
                        color: '#000'
                      }}
                      to={`/admin/vendors/${v.fkEcomVendor}/payouts/${v.pkEcomVendorPayout}`}
                    >
                      <AppStack style={{ gap: 0 }}>
                        <NumberFormatter
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="$"
                          style={{ fontSize: 14 }}
                          thousandSeparator
                          value={v.amountPaid / 100}
                        />
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          {v.vendorName}
                        </AppText>
                      </AppStack>

                      <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                        <ChevronRight color="#666" size={18} />
                      </AppFlexbox>
                    </AppFlexbox>
                  </React.Fragment>
                ))
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </PageView>
  );
};

export default AdminVendorPayoutBalanceView;
