import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  NumberFormatter,
  Progress,
  Skeleton,
  Table
} from '@mantine/core';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppFlexbox from '../../common/AppFlexbox';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import AppText from '../../common/AppText';
import PaymentPriceItem from '../../common/PaymentPriceItem';
import OrderTransactionPaymentTable from './OrderTransactionPaymentTable';

const OrderTransactionModal = ({
  pkEcomOrder,
  ecomOrder,
  isOpen,
  onClose,
  onShowRefund
}) => {
  const { state: authState } = useContext(AuthContext);
  const { state, fetchStoreOrder } = useContext(StoreContext);

  const storeOrder =
    ecomOrder ??
    pkEcomOrder?.toString() === state.ecomOrder.value?.pkEcomOrder.toString()
      ? state.ecomOrder.value
      : null;
  const loading = !storeOrder;
  const checkoutBalance = storeOrder?.checkoutBalance;
  const currency = checkoutBalance?.currency?.toUpperCase() ?? 'CAD';

  const statusInfo = checkoutBalance
    ? checkoutBalance.amountPaidInCents >= 0 &&
      checkoutBalance.refundedAmountInCents >= checkoutBalance.amountPaidInCents
      ? { label: 'Refunded', color: '#C40000' }
      : checkoutBalance.refundedAmountInCents > 0
      ? { label: 'Partially Refunded', color: '#666' }
      : checkoutBalance.amountPaidInCents >= 0
      ? { label: 'Paid', color: 'dodgerblue' }
      : { label: 'Unpaid', color: '#666' }
    : {};

  useEffect(() => {
    if (isOpen && pkEcomOrder && !storeOrder) {
      fetchStoreOrder(authState.pkEcomStore, { pkEcomOrder }, null, (e) => {
        triggerNotification(e);
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Close',
        onCancel: onClose,
        isSubmitHidden: true
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={900}
      title="Transaction Details"
    >
      <AppStack style={{ gap: 40 }}>
        <AppFlexbox
          style={{
            gap: 40,
            alignItems: 'start'
          }}
        >
          <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <AppText
              style={{ color: 'dodgerblue', fontSize: 18, fontWeight: 500 }}
            >
              Order status
            </AppText>
            <Divider size={2} />
            {loading ? (
              <AppStack style={{ gap: 5 }}>
                <Skeleton height={12} width="40%" />
                <Skeleton height={8} width="100%" />
              </AppStack>
            ) : (
              <AppStack style={{ gap: 0 }}>
                <AppText
                  style={{ fontSize: 16, color: statusInfo.color }}
                  weight={500}
                >
                  {statusInfo.label}
                </AppText>
                <Progress
                  value={
                    ((checkoutBalance.amountPaidInCents -
                      checkoutBalance.refundedAmountInCents) /
                      checkoutBalance.totalInCents) *
                    100
                  }
                />
              </AppStack>
            )}
          </AppStack>
          <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <AppText
              style={{ color: 'dodgerblue', fontSize: 18, fontWeight: 500 }}
            >
              Overview
            </AppText>
            <Divider size={2} />

            {loading ? (
              <>
                <PaymentPriceItem label="Transaction Total" loading />
                <PaymentPriceItem label="Amount Paid" loading />
                <PaymentPriceItem label="Balance" loading />
              </>
            ) : (
              <>
                <PaymentPriceItem
                  currency={currency}
                  label="Transaction Total"
                  value={checkoutBalance.totalInCents / 100}
                  weight={700}
                />
                <PaymentPriceItem
                  color="#37b24d"
                  currency={currency}
                  label="Amount Paid"
                  value={checkoutBalance.amountPaidInCents / 100}
                />
                {checkoutBalance.refundedAmountInCents > 0 && (
                  <PaymentPriceItem
                    color="#c40000"
                    currency={currency}
                    label="Refunded Amount"
                    value={checkoutBalance.refundedAmountInCents / 100}
                  />
                )}
                <PaymentPriceItem
                  currency={currency}
                  isDividerHidden
                  label="Balance"
                  value={checkoutBalance.balanceInCents / 100}
                  weight={700}
                />
              </>
            )}
          </AppStack>
        </AppFlexbox>
        <AppStack>
          <AppStack style={{ gap: 5 }}>
            <AppText
              style={{ color: 'dodgerblue', fontSize: 18, fontWeight: 500 }}
            >
              Checkout summary
            </AppText>
            <Divider />
            <Table horizontalSpacing={0}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <AppStack style={{ fontSize: 14, fontWeight: 500 }}>
                      ITEMS
                    </AppStack>
                  </Table.Th>
                  <Table.Th>
                    <AppStack
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: 'end'
                      }}
                    >
                      QTY
                    </AppStack>
                  </Table.Th>
                  <Table.Th>
                    <AppStack
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: 'end'
                      }}
                    >
                      UNIT PRICE
                    </AppStack>
                  </Table.Th>
                  <Table.Th>
                    <AppStack
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: 'end'
                      }}
                    >
                      AMOUNT
                    </AppStack>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {loading ? (
                  <>
                    <Table.Tr>
                      <Table.Td>
                        <Skeleton height={20} width="40%" />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={30}
                        />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={60}
                        />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={60}
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Skeleton height={20} width={100} />
                      </Table.Td>
                      <Table.Td />
                      <Table.Td />
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={60}
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td />
                      <Table.Td />
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={60}
                        />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton
                          height={20}
                          style={{ marginLeft: 'auto' }}
                          width={60}
                        />
                      </Table.Td>
                    </Table.Tr>
                  </>
                ) : (
                  <>
                    {checkoutBalance.lineItems.map((lineItem) => (
                      <Table.Tr key={lineItem.entityId}>
                        <Table.Td>
                          <AppStack
                            style={{
                              fontSize: 14,
                              fontWeight: 650
                            }}
                          >
                            {lineItem.productName}
                          </AppStack>
                        </Table.Td>
                        <Table.Td>
                          <AppStack
                            style={{
                              fontSize: 14,
                              textAlign: 'end'
                            }}
                          >
                            {lineItem.quantity}
                          </AppStack>
                        </Table.Td>
                        <Table.Td>
                          <AppText style={{ textAlign: 'end' }}>
                            <NumberFormatter
                              decimalScale={2}
                              fixedDecimalScale
                              prefix="$"
                              style={{ fontSize: 14 }}
                              suffix=" CAD"
                              thousandSeparator
                              value={lineItem.totalInCents / 100}
                            />
                          </AppText>
                        </Table.Td>
                        <Table.Td>
                          <AppText style={{ textAlign: 'end' }}>
                            <NumberFormatter
                              decimalScale={2}
                              fixedDecimalScale
                              prefix="$"
                              style={{ fontSize: 14 }}
                              suffix=" CAD"
                              thousandSeparator
                              value={
                                (lineItem.totalInCents * lineItem.quantity) /
                                100
                              }
                            />
                          </AppText>
                        </Table.Td>
                      </Table.Tr>
                    ))}

                    {checkoutBalance.tieredPricingFeeInCents > 0 && (
                      <Table.Tr>
                        <Table.Td>
                          <AppStack
                            style={{
                              fontSize: 14,
                              fontWeight: 650
                            }}
                          >
                            Service Fee
                          </AppStack>
                        </Table.Td>
                        <Table.Td />
                        <Table.Td />
                        <Table.Td>
                          <AppText style={{ textAlign: 'end' }}>
                            <NumberFormatter
                              decimalScale={2}
                              fixedDecimalScale
                              prefix="$"
                              style={{ fontSize: 14 }}
                              suffix=" CAD"
                              thousandSeparator
                              value={
                                checkoutBalance.tieredPricingFeeInCents / 100
                              }
                            />
                          </AppText>
                        </Table.Td>
                      </Table.Tr>
                    )}
                    <Table.Tr>
                      <Table.Td />
                      <Table.Td />
                      <Table.Td>
                        <AppStack
                          style={{
                            fontSize: 14,
                            fontWeight: 650,
                            textAlign: 'end'
                          }}
                        >
                          TOTAL
                        </AppStack>
                      </Table.Td>
                      <Table.Td>
                        <AppText style={{ textAlign: 'end' }}>
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{
                              fontSize: 14,
                              fontWeight: 650
                            }}
                            suffix=" CAD"
                            thousandSeparator
                            value={checkoutBalance.totalInCents / 100}
                          />
                        </AppText>
                      </Table.Td>
                    </Table.Tr>
                  </>
                )}
              </Table.Tbody>
            </Table>
          </AppStack>

          <AppStack style={{ gap: 5 }}>
            <AppText
              style={{ color: 'dodgerblue', fontSize: 18, fontWeight: 500 }}
            >
              Transactions
            </AppText>
            <OrderTransactionPaymentTable
              loading={loading}
              storeOrder={storeOrder}
            />
          </AppStack>
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

OrderTransactionModal.propTypes = {
  ecomOrder: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onShowRefund: PropTypes.func,
  pkEcomOrder: PropTypes.number
};

export default OrderTransactionModal;
