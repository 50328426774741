/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Button, Pagination } from '@mantine/core';
import { ChevronLeft, Receipt } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import WsAccountOrderDetails from './WsAccountOrderDetails';
import WsAccountOrderSummary from './WsAccountOrderSummary';
import { usePaginationFilter } from '../../../../../helpers/hooks';

const WsAccountOrders = ({
  baseStoreUrl,
  mediaQueries,
  onNavigate,
  onShowMobileNavigation,
  pathname,
  ecomStoreTheme,
  ...rest
}) => {
  const hasFetchedCartHistory = useRef(false);
  const { state, fetchEcomStoreOrdersPaginated } = useContext(StoreUserContext);
  const { isTabletOrSmaller } = mediaQueries;
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {},
    (newFilter) => {
      fetchEcomStoreOrdersPaginated(
        ecomStoreTheme.ecomStore.pkEcomStore,
        newFilter
      );
    },
    state.paginatedEcomOrders
  );
  const pkEcomStoreCart = pathname.split('/')[3];

  useEffect(() => {
    if (!pkEcomStoreCart && !hasFetchedCartHistory.current) {
      fetchEcomStoreOrdersPaginated(ecomStoreTheme.ecomStore.pkEcomStore, {
        pageSize: 5,
        page: 1
      });
      hasFetchedCartHistory.current = true;
    }
  }, [pkEcomStoreCart]);

  return pkEcomStoreCart ? (
    <WsAccountOrderDetails
      baseStoreUrl={baseStoreUrl}
      ecomStoreTheme={ecomStoreTheme}
      mediaQueries={mediaQueries}
      onNavigate={onNavigate}
      pathname={pathname}
      {...rest}
    />
  ) : (
    <AppStack
      id="ws-account-orders"
      style={{ flex: 1, gap: 16, marginBottom: 16 }}
    >
      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ flex: 1, padding: 0 }}
        withBorder
      >
        <AppFlexbox style={{ padding: 16, gap: 8 }}>
          {isTabletOrSmaller && (
            <ActionIcon
              color="dark"
              onClick={onShowMobileNavigation}
              variant="subtle"
            >
              <ChevronLeft size={20} />
            </ActionIcon>
          )}
          <AppText style={{ fontSize: 18, fontWeight: 700 }}>
            Order History
          </AppText>
        </AppFlexbox>
      </AppCard>
      {!hasFetchedCartHistory.current || state.paginatedEcomOrders.loading ? (
        Array.from(Array(1)).map((x, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <WsAccountOrderSummary key={i} mediaQueries={mediaQueries} />
        ))
      ) : orderData.length === 0 ? (
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 0 }}
          withBorder
        >
          <AppStack
            style={{
              flex: 1,
              gap: 5,
              alignItems: 'center',
              textAlign: 'center',
              padding: 40
            }}
          >
            <Receipt color="#000" size={56} />
            <AppText style={{ fontWeight: 500 }}>
              You have no orders yet.
            </AppText>
            <Button
              color="dark"
              component={Link}
              onClick={onNavigate}
              style={{ marginTop: 5 }}
              to={`${baseStoreUrl}/products`}
              variant="outline"
            >
              Start shopping
            </Button>
          </AppStack>
        </AppCard>
      ) : (
        <AppStack>
          {orderData.map((d) => (
            <WsAccountOrderSummary
              key={d.pkEcomOrder}
              baseStoreUrl={baseStoreUrl}
              ecomOrderSummary={d}
              mediaQueries={mediaQueries}
              onNavigate={onNavigate}
            />
          ))}

          {totalPages > 1 && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Pagination
                color="dark"
                onChange={() => {
                  onPageChange(pageIndex + 1);
                  const scrollContainerRef = document.getElementById(
                    'ws-account-orders'
                  );
                  if (scrollContainerRef) {
                    scrollContainerRef.scrollIntoView({ block: 'start' });
                  }
                }}
                total={totalPages}
                value={pageIndex}
              />
            </AppFlexbox>
          )}
        </AppStack>
      )}
    </AppStack>
  );
};

WsAccountOrders.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  onShowMobileNavigation: PropTypes.func,
  pathname: PropTypes.string
};

export default WsAccountOrders;
