import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  CircleCheck,
  CreditCard,
  Dots,
  Download,
  Eye,
  Receipt,
  TruckDelivery,
  X
} from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { ActionIcon, Badge, Button, Menu } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import TableView from '../../common/TableView';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import {
  currencyFormat,
  formatUtcDate,
  singularPluralFormat
} from '../../../helpers/format';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import AppMenu from '../../common/AppMenu';
import AppStack from '../../common/AppStack';
import VendorOrderAcceptModal from '../orders/VendorOrderAcceptModal';

const VIEW_ACTIONS = [
  {
    label: 'View order',
    value: VIEW_ACTIONS_ENUM.VIEW,
    section: 1,
    icon: <Eye size={18} />
  },
  {
    label: 'Accept order',
    value: 'accept',
    section: 1,
    icon: <CircleCheck size={18} />,
    isVisible: ({ hasPendingProduct }) => hasPendingProduct
  },
  {
    label: 'Fulfill items',
    value: 'fulfill',
    section: 1,
    icon: <TruckDelivery size={18} />,
    isVisible: ({ isFulfilled }) => !isFulfilled
  },
  {
    label: 'Cancel ',
    value: 'cancel',
    section: 2,
    color: '#C40000',
    icon: <X color="#C40000" size={18} />,
    isVisible: ({ isCancelled, isBulkOrders }) => !isCancelled && !isBulkOrders
  },
  {
    label: 'Refund',
    value: VIEW_ACTIONS_ENUM.REFUND,
    section: 2,
    color: '#C40000',
    icon: <CreditCard color="#C40000" size={18} />,
    isVisible: ({ isRefunded, isBulkOrders }) => !isRefunded && !isBulkOrders
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Order ID',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Fulfillment status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Payment status',
    value: 'payment_status',
    sortable: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Merchant',
    value: 'store',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total',
    value: 'total',
    sortable: true
  }
];

const VendorOrderTable = ({ isBulkOrders }) => {
  const fetchedTableType = useRef(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedVendorOrders,
    fetchVendorOrdersSummary
  } = useContext(VendorContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      store: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    isBulkOrders ? state.paginatedEcomOrdersBulk : state.paginatedEcomOrders
  );
  const tableInfo = isBulkOrders
    ? {
        title: 'Bulk orders',
        type: 'bulk',
        urlBase: '/vendor/bulk-orders'
      }
    : {
        title: 'Orders',
        type: 'normal',
        urlBase: '/vendor/orders'
      };

  const loading = fetchedTableType.current !== tableInfo.type || dataLoading;

  const pendingOrderCount = isBulkOrders
    ? state.ecomOrdersSummary.value?.pendingBulkOrders.length ?? 0
    : state.ecomOrdersSummary.value?.pendingOrders.length ?? 0;

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorOrders(
        authState.pkEcomVendor,
        {
          isBulkShipment: isBulkOrders,
          search: new URLSearchParams(search).get('customer') || '',
          store: new URLSearchParams(search).get('merchant') || '',
          sort: 'order_desc'
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchVendorOrdersSummary(authState.pkEcomVendor, null, (error) => {
        triggerNotification(error);
      });

      fetchedTableType.current = tableInfo.type;
    }
  }, [authState.pkEcomVendor, isBulkOrders]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: tableInfo.title,
          to: tableInfo.urlBase
        }
      ]}
      rightSection={
        !loading &&
        !state.ecomOrdersSummary.loading && (
          <Button
            color="dark"
            disabled={pendingOrderCount === 0}
            onClick={() => onOpenModal('accept-all')}
            radius="md"
            size="compact-md"
            style={{ fontSize: 14 }}
            variant="filled"
            visibleFrom="xsm"
          >
            {pendingOrderCount === 0
              ? 'Accept orders'
              : `Accept ${singularPluralFormat(
                  pendingOrderCount,
                  'order',
                  'orders'
                )}`}
          </Button>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                search: v
              })
          },
          {
            key: 2,
            label: 'Search',
            placeholder: 'Filter by merchant',
            value: filter.store || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                store: v
              })
          },
          {
            key: 3,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' },
              { value: 'refunded', label: 'Refunded' },
              { value: 'cancelled', label: 'Cancelled' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onAction={(action, item) => {
          switch (action) {
            case VIEW_ACTIONS_ENUM.VIEW:
              navigate(`${tableInfo.urlBase}/${item.key}`);
              break;
            case VIEW_ACTIONS_ENUM.REFUND:
              navigate(`${tableInfo.urlBase}/${item.key}/refund`);
              break;
            case 'fulfill':
              navigate(`${tableInfo.urlBase}/${item.key}/fulfill`);
              break;
            case 'cancel':
              navigate(`${tableInfo.urlBase}/${item.key}?modal=cancel`);
              break;
            default:
              onOpenModal(action, [item.key]);
              break;
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={orderData.map((d) => {
          const refunded = d.ecomOrderPayments
            .filter(
              (p) =>
                p.fkRegFormSubmissionPaymentType.toString() ===
                PAYMENT_TYPE_ENUM.CREDIT
            )
            .reduce((r, c) => r + c.amount, 0);
          const payoutRefundAmount = 0;
          const totals = [
            ...d.ecomOrderProducts.map((p) => p.ecomOrderProductPayments),
            ...d.ecomOrderProductBulk.map(
              (p) => p.ecomOrderProduct.ecomOrderProductPayments
            )
          ]
            .flat()
            .reduce(
              (r, { fkRegFormSubmissionPaymentType, amount, payoutAmount }) => {
                const type = fkRegFormSubmissionPaymentType.toString();
                return {
                  ...r,
                  paid:
                    r.paid + (type !== PAYMENT_TYPE_ENUM.CREDIT ? amount : 0),
                  payoutAmount:
                    r.payoutAmount +
                    (type !== PAYMENT_TYPE_ENUM.CREDIT ? payoutAmount : 0)
                };
              },
              { paid: 0, payoutAmount: 0 }
            );
          const isPayoutCompleted =
            totals.payoutAmount - payoutRefundAmount >= totals.paid - refunded;
          const completelyRefunded = totals.paid && refunded >= totals.paid;

          const bulkInfo = d.ecomOrderProductBulk.reduce(
            (
              r,
              {
                ecomOrderProduct,
                count,
                cancelledCount,
                fkEcomOrderProductStatus
              }
            ) => ({
              totalCost:
                r.totalCost +
                (ecomOrderProduct.productCost + ecomOrderProduct.addonCost) *
                  count,
              itemCount: r.itemCount + count,
              cancelledCount: r.cancelledCount + cancelledCount,
              isFullyFulfilled:
                r.isFullyFulfilled &&
                ![
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING,
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
                ].includes(fkEcomOrderProductStatus.toString()),
              hasPendingProduct:
                r.hasPendingProduct ||
                fkEcomOrderProductStatus.toString() ===
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              totalCost: 0,
              itemCount: 0,
              cancelledCount: 0,
              isFullyFulfilled: true,
              hasPendingProduct: false
            }
          );

          const productInfo = d.ecomOrderProducts.reduce(
            (
              r,
              {
                productCost,
                addonCost,
                count,
                cancelledCount,
                fkEcomOrderProductStatus,
                isBulkShipment
              }
            ) => ({
              totalCost: r.totalCost + (productCost + addonCost) * count,
              itemCount: r.itemCount + count,
              cancelledCount: r.cancelledCount + cancelledCount,
              isFullyFulfilled: isBulkShipment
                ? r.isFullyFulfilled
                : r.isFullyFulfilled &&
                  ![
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING,
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
                  ].includes(fkEcomOrderProductStatus.toString()),
              hasPendingProduct: isBulkShipment
                ? r.hasPendingProduct
                : r.hasPendingProduct ||
                  fkEcomOrderProductStatus.toString() ===
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
            }),
            {
              totalCost: 0,
              itemCount: 0,
              cancelledCount: 0,
              isFullyFulfilled: true,
              hasPendingProduct: false
            }
          );

          const isFullyCancelled =
            !isBulkOrders &&
            productInfo.cancelledCount === productInfo.itemCount;

          const statusInfo = isFullyCancelled
            ? { color: '#C40000', label: 'Cancelled' }
            : productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled
            ? { color: 'green', label: 'Fulfilled' }
            : productInfo.hasPendingProduct || bulkInfo.hasPendingProduct
            ? { color: 'grey', label: 'pending' }
            : { color: 'orange', label: 'Unfulfilled' };

          const paymentStatusInfo = completelyRefunded
            ? { color: '#C40000', label: 'Refunded' }
            : totals.refunded > 0
            ? { color: '#666', label: 'Partially refunded' }
            : isPayoutCompleted
            ? { color: 'dodgerblue', label: 'Paid' }
            : { color: 'grey', label: 'Unpaid' };

          return {
            key: d.pkEcomOrder,
            actions: VIEW_ACTIONS.filter(
              (f) =>
                !f.isVisible ||
                f.isVisible({
                  hasPendingProduct:
                    productInfo.hasPendingProduct || bulkInfo.hasPendingProduct,
                  isCancelled: isFullyCancelled,
                  isRefunded: completelyRefunded,
                  isFulfilled:
                    productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled,
                  isBulkOrders
                })
            ),
            columns: [
              {
                key: 1,
                label: `#${d.pkEcomOrder}`,
                weight: 500,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 2,
                label: dayjs(formatUtcDate(d.purchasedAt)).format(
                  'MMMM D, YYYY h:mm A'
                )
              },
              {
                key: 3,
                customComponent: (
                  <Badge color={statusInfo.color} variant="light">
                    {statusInfo.label}
                  </Badge>
                )
              },
              ...(isBulkOrders
                ? []
                : [
                    {
                      key: 4,
                      customComponent: (
                        <Badge color={paymentStatusInfo.color} variant="light">
                          {paymentStatusInfo.label}
                        </Badge>
                      )
                    }
                  ]),
              {
                key: 5,
                label: d.ecomCustomer.user?.name,
                weight: 500,
                subLabel: d.ecomCustomer.email,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 6,
                label: d.ecomStore.name,
                weight: 500,
                subLabel: d.ecomStore.domain,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 7,
                label: productInfo.itemCount + bulkInfo.itemCount
              },
              {
                key: 8,
                label: currencyFormat(totals.paid / 100)
              }
            ]
          };
        })}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              {/* <Menu.Item
                leftSection={<Download size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                visibleFrom="xsm"
              >
                Export orders
              </Menu.Item> */}
              <Menu.Item
                leftSection={<CircleCheck size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onOpenModal('accept', selectedRows);
                }}
              >
                Accept orders
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={selectedRows}
        selectInputOnly
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle={tableInfo.title}
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <VendorOrderAcceptModal
        isBulkOrders={isBulkOrders}
        isOpen={
          modalState.isOpen &&
          (modalState.action === 'accept-all' || modalState.action === 'accept')
        }
        onClose={onCloseModal}
        onSuccess={onRefresh}
        pkEcomOrders={selectedRows}
        selectAll={modalState.action === 'accept-all'}
      />
    </HeaderView>
  );
};

VendorOrderTable.propTypes = { isBulkOrders: PropTypes.bool };

export default VendorOrderTable;
