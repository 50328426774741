import React, { useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { Anchor, Divider, NumberFormatter, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { currencyFormat } from '../../../helpers/format';
import ResponsiveModal from '../../common/ResponsiveModal';

const OrderRefundPaymentSummary = ({
  amountPaidInCents,
  unlockRefundAmount,
  hasRefundedAllItems,
  reimbursedAmount,
  refundedAmountInCents,
  selectedItemsRefundValue,
  serviceFeePaid,
  tieredPricing,
  totalProductFulfillmentCost,
  totalStoreProfit,
  availableRefundAmount,
  expectedVsRecordedServiceFeeDifference
}) => {
  const [infoModalState, setInfoModalState] = useState({
    isOpen: false,
    title: '',
    info: ''
  });

  return (
    <AppStack style={{ gap: 8 }}>
      <ResponsiveModal
        formSectionProps={{
          cancelTitle: 'Close',
          onCancel: () =>
            setInfoModalState({ ...infoModalState, isOpen: false }),
          isSubmitHidden: true
        }}
        isOpen={infoModalState.isOpen}
        onClose={() => setInfoModalState({ ...infoModalState, isOpen: false })}
        size={500}
        title={infoModalState.title || 'Details'}
      >
        {infoModalState.info}
      </ResponsiveModal>

      {[
        {
          label: 'Amount paid',
          value: amountPaidInCents / 100
        },
        {
          label: 'Stripe fees',
          labelColor: '#666',
          value: (serviceFeePaid * -1) / 100,
          valueColor: '#c40000',
          ignored: unlockRefundAmount,
          tooltipIcon: <InfoCircle color="#C40000" size={18} />,
          tooltip: expectedVsRecordedServiceFeeDifference !== 0 && (
            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                Recorded service fee{' '}
                <b>
                  {currencyFormat(expectedVsRecordedServiceFeeDifference / 100)}{' '}
                  higher
                </b>{' '}
                than anticipated.
              </AppText>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Click for more info.
              </AppText>
            </AppStack>
          ),
          infoTitle: 'Service fee discrepancy',
          infoContent:
            expectedVsRecordedServiceFeeDifference !== 0 ? (
              <AppStack style={{ gap: 8 }}>
                <AppText style={{ fontSize: 14 }}>
                  Recorded service fee{' '}
                  <b>
                    {currencyFormat(
                      expectedVsRecordedServiceFeeDifference / 100
                    )}{' '}
                    {expectedVsRecordedServiceFeeDifference < 0
                      ? 'lower'
                      : 'higher'}
                  </b>{' '}
                  than anticipated.
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  This discrepancy may be due to additional service fees applied
                  by Stripe, such as a <b>+0.8% fee for international cards</b>.
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  For more details, please visit{' '}
                  <Anchor
                    component="a"
                    href="https://stripe.com/en-ca/pricing"
                    style={{ fontSize: 14, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    Stripe pricing
                  </Anchor>{' '}
                  or contact support if you have any questions.
                </AppText>

                <AppText
                  style={{
                    marginTop: 8,
                    fontSize: 14,
                    fontStyle: 'italic',
                    fontWeight: 500,
                    color: '#666',
                    textAlign: 'center'
                  }}
                >
                  This variance will directly impact the store's profit margin.
                </AppText>
              </AppStack>
            ) : null
        },
        {
          label: 'Platform fee',
          labelColor: '#666',
          value: (tieredPricing * -1) / 100,
          valueColor: '#c40000',
          ignored: hasRefundedAllItems || unlockRefundAmount,
          tooltip:
            "Adjusted to account for Stripe's $0.30 flat rate fee and any rounding-related fee adjustments."
        },
        {
          label: 'Product fulfillment',
          labelColor: '#666',
          value: (totalProductFulfillmentCost * -1) / 100,
          valueColor: '#c40000',
          ignored: unlockRefundAmount
        },
        {
          label: 'Store profit',
          labelWeight: 500,
          value: totalStoreProfit / 100,
          withDivider: true
        },
        {
          label: 'Cancelled products',
          labelColor: '#666',
          prefix: selectedItemsRefundValue > 0 ? '+$' : '$',
          value: selectedItemsRefundValue / 100,
          valueColor: selectedItemsRefundValue > 0 ? '#067D62' : '#666'
        },
        ...(reimbursedAmount > 0
          ? [
              {
                label: 'Reimbursed amount',
                labelColor: '#666',
                prefix: '+$',
                value: reimbursedAmount / 100,
                valueColor: '#067D62'
              }
            ]
          : []),
        ...(refundedAmountInCents > 0
          ? [
              {
                label: 'Refunded amount',
                labelColor: '#C40000',
                value: (refundedAmountInCents * -1) / 100,
                valueColor: '#C40000'
              }
            ]
          : []),
        {
          label: 'Available refund',
          labelWeight: 500,
          value: availableRefundAmount / 100,
          withDivider: true
        }
      ].map((i) => (
        <React.Fragment key={i.label}>
          {i.withDivider && <Divider />}
          <AppFlexbox style={{ justifyContent: 'space-between' }}>
            <Tooltip
              disabled={!i.tooltip}
              label={i.tooltip}
              multiline
              onClick={() => {
                if (i.infoContent) {
                  setInfoModalState({
                    isOpen: true,
                    title: i.infoTitle,
                    info: i.infoContent
                  });
                }
              }}
              w={250}
              withArrow
            >
              <AppFlexbox
                style={{
                  gap: 8,
                  alignItems: 'center',
                  cursor: i.infoContent ? 'pointer' : 'default'
                }}
              >
                <AppText
                  style={{
                    fontSize: 14,
                    color: i.labelColor ?? '#000',
                    textDecoration: i.ignored ? 'line-through' : 'none'
                  }}
                >
                  {i.label}
                </AppText>
                {i.tooltip &&
                  (i.tooltipIcon || (
                    <InfoCircle color="dodgerblue" size={18} />
                  ))}
              </AppFlexbox>
            </Tooltip>
            <NumberFormatter
              decimalScale={2}
              displayType="text"
              fixedDecimalScale
              prefix={i.prefix || '$'}
              style={{
                color: i.valueColor ?? '#000',
                textDecoration: i.ignored ? 'line-through' : 'none'
              }}
              thousandSeparator
              value={i.value}
            />
          </AppFlexbox>
        </React.Fragment>
      ))}
    </AppStack>
  );
};

OrderRefundPaymentSummary.propTypes = {
  amountPaidInCents: PropTypes.number,
  availableRefundAmount: PropTypes.number,
  expectedVsRecordedServiceFeeDifference: PropTypes.number,
  hasRefundedAllItems: PropTypes.bool,
  refundedAmountInCents: PropTypes.number,
  reimbursedAmount: PropTypes.number,
  selectedItemsRefundValue: PropTypes.number,
  serviceFeePaid: PropTypes.number,
  tieredPricing: PropTypes.number,
  totalProductFulfillmentCost: PropTypes.number,
  totalStoreProfit: PropTypes.number,
  unlockRefundAmount: PropTypes.bool
};

export default OrderRefundPaymentSummary;
