import React, { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Image } from '@mantine/core';
import dayjs from 'dayjs';
import { Eye } from 'tabler-icons-react';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import TableView from '../../common/TableView';
import { usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import AppStack from '../../common/AppStack';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';

const TABLE_COLUMNS = [
  {
    label: 'Store',
    value: 'name',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Gross sales',
    value: 'gross_sales',
    sortable: true
  },
  {
    label: 'Payout locked',
    value: 'payout_locked',
    sortable: true
  },
  {
    label: 'Payout earned',
    value: 'payout_earned',
    sortable: true
  },
  {
    label: 'Payout received',
    value: 'payout_received',
    sortable: true
  },
  {
    label: 'Payout balance',
    value: 'payout_balance',
    sortable: true
  },
  {
    label: 'Last Paid',
    value: 'last_paid',
    sortable: true
  },
  {
    label: 'Payment provider',
    value: 'payment_provider',
    sortable: true
  },
  {
    label: 'Created At',
    value: 'created',
    sortable: true
  }
];

const AdminVendorsTableView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state, fetchPaginatedEcomVendors } = useContext(AdminContext);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    { search: '' },
    (newFilter) => {
      fetchPaginatedEcomVendors(newFilter, null, (error) => {
        triggerNotification(error);
      });
    },
    state.paginatedEcomVendors
  );
  const loading = !hasFetched.current || dataLoading;

  useEffect(() => {
    fetchPaginatedEcomVendors({}, null, (error) => {
      triggerNotification(error);
    });
    hasFetched.current = true;
  }, []);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Vendors',
          to: '/admin/vendors'
        }
      ]}
      rightSection={
        <Button
          color="dark"
          component={Link}
          leftSection={<Eye />}
          radius="md"
          size="compact-md"
          style={{ fontSize: 14 }}
          to="/admin/vendors/payout-balance"
          variant="filled"
          visibleFrom="xsm"
        >
          Payout balance
        </Button>
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No vendors found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by name',
            value: filter.search || '',
            onChange: (search) =>
              onFilterChange({
                ...filter,
                search
              })
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'deleted', label: 'Deleted' },
              { value: 'sandbox', label: 'Sandbox' },
              { value: 'live', label: 'Live' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasFetched.current || loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        pageIndex={pageIndex}
        rows={data.map((v) => ({
          key: v.ecomVendor.pkEcomVendor,
          columns: [
            {
              key: 1,
              label: v.ecomVendor.name,
              subLabel: v.ecomVendor.domain,
              weight: 500,
              onClick: () =>
                navigate(`/admin/vendors/${v.ecomVendor.pkEcomVendor}`),
              component: Link,
              to: `/admin/vendors/${v.ecomVendor.pkEcomVendor}`,
              onSubLabelClick: () => window.open(v.ecomVendor.domain, '_blank'),
              icon: (
                <AppStack
                  style={{
                    width: 40,
                    height: 40,
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: v.ecomVendor.logoImageUrl
                      ? 'none'
                      : 'solid 1px lightgrey'
                  }}
                >
                  <Image h="100%" src={v.ecomVendor.logoImageUrl} w="100%" />
                </AppStack>
              )
            },
            {
              key: 2,
              label: v.ecomVendor.deleted
                ? 'Deleted'
                : v.ecomVendor.sandboxEnabled
                ? 'Sandbox'
                : 'Live',
              color: v.ecomVendor.deleted
                ? '#c40000'
                : v.ecomVendor.sandboxEnabled
                ? '#eca70a'
                : '#067D62'
            },
            {
              key: 3,
              label: currencyFormat(
                (v.ecomVendorPayoutBalance?.ecomOrders.reduce(
                  (r, c) => r + c.amountPaid,
                  0
                ) ?? 0) / 100
              )
            },
            {
              key: 4,
              label: currencyFormat(
                (v.ecomVendorPayoutBalance?.payoutLocked ?? 0) / 100
              )
            },
            {
              key: 5,
              label: currencyFormat(
                (v.ecomVendorPayoutBalance?.payoutEarned ?? 0) / 100
              )
            },
            {
              key: 6,
              label: currencyFormat(
                (v.ecomVendorPayoutBalance?.payoutReceived ?? 0) / 100
              )
            },
            {
              key: 7,
              label: currencyFormat(
                (v.ecomVendorPayoutBalance?.payoutBalance ?? 0) / 100
              )
            },
            {
              key: 8,
              label: v.ecomVendorPayoutBalance?.lastPayoutDate
                ? dayjs(
                    formatUtcDate(v.ecomVendorPayoutBalance.lastPayoutDate)
                  ).format('MMM D, YYYY')
                : 'N/A'
            },
            {
              key: 9,
              label: v.ecomVendor.ecomVendorPaymentProvider?.accountId || 'N/A',
              onClick: () =>
                window.open(
                  `https://dashboard.stripe.com/connect/accounts/${v.ecomVendor.ecomVendorPaymentProvider?.accountId}`,
                  '_blank'
                )
            },
            {
              key: 10,
              label: dayjs(formatUtcDate(v.ecomVendor.createdAt)).format(
                'MMM D, YYYY'
              )
            }
          ]
        }))}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Vendors"
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </HeaderView>
  );
};

export default AdminVendorsTableView;
