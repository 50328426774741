import React, { useContext, useEffect, useRef } from 'react';
import { Divider } from '@mantine/core';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import AppStack from '../../common/AppStack';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import {
  NOTIFICATION_SECTIONS as STORE_NOTIFICATION_SECTIONS,
  NOTIFICATIONS as STORE_NOTIFICATIONS
} from './storeNotificationsConfig';
import {
  NOTIFICATION_SECTIONS as VENDOR_NOTIFICATION_SECTIONS,
  NOTIFICATIONS as VENDOR_NOTIFICATIONS
} from './vendorNotificationsConfig';
import * as classes from '../../../styles/nestedStyles.module.css';
import NotificationView from './NotificationView';

const NotificationsViews = ({ isVendorView }) => {
  const hasFetched = useRef(false);
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { isMobileOrSmaller, isTabletOrSmaller } = useMediaQueryIndex();
  const { state: storeState, fetchStoreNotifications } = useContext(
    StoreContext
  );
  const { state: vendorState, fetchVendorNotifications } = useContext(
    VendorContext
  );
  const notificationState = isVendorView
    ? vendorState.ecomNotifications
    : storeState.ecomNotifications;
  const { emails: emailNotifications = [] } = notificationState.value || {};

  const loading = !hasFetched.current || notificationState.loading;
  const baseUrl = isVendorView
    ? '/vendor/notifications'
    : '/merchant/notifications';

  const notificationSections = isVendorView
    ? VENDOR_NOTIFICATION_SECTIONS
    : STORE_NOTIFICATION_SECTIONS;

  useEffect(() => {
    if (authState.pkEcomStore) {
      if (isVendorView) {
        fetchVendorNotifications(authState.pkEcomVendor);
      }
      else {
        fetchStoreNotifications(authState.pkEcomStore);
      }
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore]);

  return (
    <AppStack style={{ flex: 1, alignItems: 'center' }}>
      <AppStack
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 1100,
          padding: isMobileOrSmaller ? '8px 0px' : '16px 16px 80px 16px',
          gap: 16
        }}
      >
        <AppFlexbox style={{ flex: 1, gap: 16 }}>
          <AppStack style={{ flex: 1, maxWidth: 400 }} visibleFrom="lg">
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="sm"
              style={{ padding: 0 }}
              withBorder
            >
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{ padding: '8px 12px', backgroundColor: '#F1F1F1' }}
                >
                  <AppText style={{ fontWeight: 700, fontSize: 18 }}>
                    Notifications
                  </AppText>
                </AppFlexbox>
                <Divider />
                <AppStack style={{ padding: 12, gap: 5 }}>
                  {notificationSections.map((s, index) => {
                    const isSelected =
                      pathname.startsWith(`${baseUrl}/${s.path}`) ||
                      (index === 0 && !pathname.startsWith(baseUrl));
                    return (
                      <AppFlexbox
                        key={s.value}
                        className={classes['hover-action-card']}
                        component={Link}
                        style={{
                          borderRadius: 8,
                          padding: 8,
                          gap: 8,
                          backgroundColor: isSelected ? '#dee2e6' : 'unset',
                          color: '#000',
                          textDecoration: 'none',
                          alignItems: 'center'
                        }}
                        to={`${baseUrl}/${s.path}`}
                      >
                        {s.icon}
                        <AppStack style={{ gap: 0 }}>
                          <AppText
                            style={{
                              fontSize: 14,
                              fontWeight: isSelected ? 500 : 'normal'
                            }}
                          >
                            {s.label}
                          </AppText>
                          {s.description && (
                            <AppText style={{ fontSize: 16, color: '#666' }}>
                              {s.label}
                            </AppText>
                          )}
                        </AppStack>
                      </AppFlexbox>
                    );
                  })}
                </AppStack>
              </AppStack>
            </AppCard>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <Routes>
              <Route
                element={
                  <NotificationView
                    emailNotifications={emailNotifications}
                    loading={loading}
                    notificationConfigs={
                      isVendorView ? VENDOR_NOTIFICATIONS : STORE_NOTIFICATIONS
                    }
                    notificationSectionConfigs={notificationSections}
                    urlBase={baseUrl}
                  />
                }
                path="/:sectionId/:notificationId"
              />
              <Route
                element={
                  <NotificationView
                    emailNotifications={emailNotifications}
                    loading={loading}
                    notificationConfigs={
                      isVendorView ? VENDOR_NOTIFICATIONS : STORE_NOTIFICATIONS
                    }
                    notificationSectionConfigs={notificationSections}
                    urlBase={baseUrl}
                  />
                }
                path="/:sectionId/*"
              />
              <Route
                element={
                  <Navigate
                    replace
                    to={`${baseUrl}/${notificationSections[0].path}`}
                  />
                }
                path="*"
              />
            </Routes>
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </AppStack>
  );
};

NotificationsViews.propTypes = { isVendorView: PropTypes.bool };

export default NotificationsViews;
