import React from 'react';
import PropTypes from 'prop-types';
import { NumberFormatter, Skeleton } from '@mantine/core';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const AdminVendorPayoutSummary = ({
  chequeId,
  reason,
  totalOrderCount,
  payoutAmount,
  fontSize,
  loading
}) => (
  <AppStack style={{ gap: 8 }}>
    <AppFlexbox
      style={{
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <AppText style={{ fontSize: fontSize ?? 16 }}>Cheque ID</AppText>
      {loading ? (
        <Skeleton height={14} width={50} />
      ) : (
        <AppText style={{ fontSize: fontSize ?? 16, fontWeight: 500 }}>
          {chequeId || 'N/A'}
        </AppText>
      )}
    </AppFlexbox>

    <AppFlexbox
      style={{
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <AppText style={{ fontSize: fontSize ?? 16 }}>Reason</AppText>
      {loading ? (
        <Skeleton height={14} width={50} />
      ) : (
        <AppText style={{ fontSize: fontSize ?? 16, fontWeight: 500 }}>
          {reason}
        </AppText>
      )}
    </AppFlexbox>

    <AppFlexbox
      style={{
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <AppText style={{ fontSize: fontSize ?? 16 }}>Orders</AppText>
      {loading ? (
        <Skeleton height={14} width={50} />
      ) : (
        <AppText style={{ fontSize: fontSize ?? 16, fontWeight: 500 }}>
          {totalOrderCount}
        </AppText>
      )}
    </AppFlexbox>

    <AppFlexbox
      style={{
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <AppText style={{ fontSize: fontSize ?? 16 }}>Payout amount</AppText>
      {loading ? (
        <Skeleton height={14} width={50} />
      ) : (
        <NumberFormatter
          decimalScale={2}
          fixedDecimalScale
          prefix="$"
          style={{ fontSize: fontSize ?? 16, fontWeight: 500 }}
          thousandSeparator
          value={payoutAmount}
        />
      )}
    </AppFlexbox>
  </AppStack>
);

AdminVendorPayoutSummary.propTypes = {
  chequeId: PropTypes.string,
  fontSize: PropTypes.number,
  loading: PropTypes.bool,
  payoutAmount: PropTypes.number,
  reason: PropTypes.string,
  totalOrderCount: PropTypes.number
};

export default AdminVendorPayoutSummary;
