import React from 'react';
import { Divider, NumberFormatter, NumberInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';

const OrderProductSelect = ({
  productData,
  onGetSelectedCount,
  onChange,
  disabled,
  showCancelWarnings
}) => (
  <AppStack style={{ gap: 16 }}>
    {productData
      .filter((f) => f.returnedCount + f.cancelledCount < f.quantity)
      .map((p, index) => {
        const selectedCount = onGetSelectedCount(p);
        const count = selectedCount
          ? selectedCount > p.amountRemaining
            ? p.amountRemaining
            : selectedCount
          : 0;

        return (
          <React.Fragment key={p.key}>
            {index > 0 && <Divider />}

            <AppFlexbox
              style={{
                gap: 16,
                justifyContent: 'space-between'
              }}
            >
              <AppFlexbox style={{ flex: 1 }}>
                <VendorOrderProductPreview
                  product={p}
                  showCancelWarnings={showCancelWarnings}
                  showPrice
                />
              </AppFlexbox>
              <AppFlexbox style={{ alignItems: 'start' }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <NumberInput
                    disabled={disabled}
                    max={p.amountRemaining}
                    min={0}
                    onChange={(v) => onChange(p, v)}
                    rightSection={
                      <AppFlexbox>
                        <AppText style={{ fontSize: 15, color: '#999' }}>
                          of {p.amountRemaining}
                        </AppText>
                      </AppFlexbox>
                    }
                    rightSectionWidth="40%"
                    style={{ width: '100%', maxWidth: 100 }}
                    value={selectedCount}
                  />

                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'end',
                      gap: 24,
                      minWidth: 100
                    }}
                  >
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={(p.unitCost * count) / 100}
                    />
                  </AppFlexbox>
                </AppFlexbox>
              </AppFlexbox>
            </AppFlexbox>
          </React.Fragment>
        );
      })}
  </AppStack>
);

OrderProductSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onGetSelectedCount: PropTypes.func.isRequired,
  productData: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  showCancelWarnings: PropTypes.bool
};

export default OrderProductSelect;
