import React from 'react';
import { AlertCircle } from 'tabler-icons-react';
import { Alert, Checkbox, Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { currencyFormat, singularPluralFormat } from '../../../helpers/format';

const OrderRefundConfirmSummary = ({
  hasRefundedAllItems,
  maxRefundAmountWithoutLosses,
  productData,
  refundAmount,
  selectedItemCount,
  selectedItemsRefundValue,
  selectedProducts,
  tieredPricing,
  isProductCancel,
  children,
  isVendorView
}) => (
  <AppStack style={{ gap: 0 }}>
    <AppStack style={{ padding: 16, gap: 8 }}>
      {isProductCancel ? (
        <>
          <AppText style={{ fontSize: 14 }}>
            Are you sure you want to cancel{' '}
            <b>
              {singularPluralFormat(selectedItemCount, 'product', 'products')}
            </b>
            ?
          </AppText>

          {isVendorView ? (
            <AppText style={{ fontSize: 14 }}>
              The merchant's refund will need to be handled separately.
            </AppText>
          ) : (
            <AppText style={{ fontSize: 14 }}>
              You will recover{' '}
              <b>{currencyFormat(selectedItemsRefundValue / 100)}</b> from the
              vendor's product fulfillment costs, but the customer refund will
              need to be processed separately.
            </AppText>
          )}
        </>
      ) : (
        <>
          <AppText style={{ fontSize: 14 }}>
            Are you sure you want to refund this order? This will cancel{' '}
            <b>
              {singularPluralFormat(selectedItemCount, 'product', 'products')}
            </b>{' '}
            currently in progress.
          </AppText>
          {!isVendorView && selectedItemsRefundValue > 0 && (
            <AppText style={{ fontSize: 14 }}>
              You will recover{' '}
              <b>
                {currencyFormat(
                  (selectedItemsRefundValue +
                    (hasRefundedAllItems ? tieredPricing : 0)) /
                    100
                )}
              </b>{' '}
              from the vendor's product fulfillment costs.
            </AppText>
          )}
          {refundAmount && (
            <AppText style={{ fontSize: 14 }}>
              {isVendorView ? 'The merchant' : 'The customer'} will receive a
              refund of <b>{currencyFormat(refundAmount / 100)}</b>
              {isVendorView &&
                ' but will need to issue a separate refund to the customer'}
              .
            </AppText>
          )}
        </>
      )}
      <AppText
        style={{
          fontSize: 14,
          fontWeight: '500',
          color: '#C40000'
        }}
      >
        This action cannot be undone.
      </AppText>
      {refundAmount && maxRefundAmountWithoutLosses - refundAmount < 0 && (
        <Alert
          color="#C40000"
          icon={<AlertCircle color="#C40000" size={18} />}
          title="Refund at a loss"
          variant="outline"
        >
          <AppStack style={{ gap: 8 }}>
            <AppText style={{ fontSize: 14 }}>
              You are about to issue a refund that will result in a loss of{' '}
              <b>
                {currencyFormat(
                  ((maxRefundAmountWithoutLosses - refundAmount) * -1) / 100
                )}
              </b>
              .
            </AppText>
            <Checkbox
              label={`I acknowledge that this refund will result in a loss of ${currencyFormat(
                ((maxRefundAmountWithoutLosses - refundAmount) * -1) / 100
              )} and understand that this action is irreversible.`}
              required
            />
          </AppStack>
        </Alert>
      )}
    </AppStack>

    {children}

    {selectedItemCount > 0 && (
      <>
        <Divider
          label={`CANCELLING ${singularPluralFormat(
            selectedItemCount,
            'PRODUCT',
            'PRODUCTS'
          )}`}
        />
        <AppStack style={{ padding: 16, gap: 8 }}>
          {selectedProducts
            .filter((p) => p.count > 0)
            .map((p, index) => {
              const product = productData.find(
                (pr) => pr.pkEcomOrderProduct === p.pkEcomOrderProduct
              );

              return (
                <React.Fragment key={p.pkEcomOrderProduct}>
                  {index > 0 && <Divider />}

                  <AppFlexbox style={{ gap: 16 }}>
                    <VendorOrderProductPreview
                      hideStatus
                      product={product}
                      quantityIndicatorAmount={p.count}
                      showCancelWarnings
                    />
                  </AppFlexbox>
                </React.Fragment>
              );
            })}
        </AppStack>
      </>
    )}
  </AppStack>
);

OrderRefundConfirmSummary.propTypes = {
  children: PropTypes.node,
  hasRefundedAllItems: PropTypes.bool,
  isProductCancel: PropTypes.bool,
  isVendorView: PropTypes.bool,
  maxRefundAmountWithoutLosses: PropTypes.number,
  productData: PropTypes.array,
  refundAmount: PropTypes.number,
  selectedItemCount: PropTypes.number,
  selectedItemsRefundValue: PropTypes.number,
  selectedProducts: PropTypes.array,
  tieredPricing: PropTypes.number
};

export default OrderRefundConfirmSummary;
