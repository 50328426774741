import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const EmailNotificationPreview = ({ subject, emailContent }) => {
  const { isMobileOrSmaller } = useMediaQueryIndex();
  const [height, setHeight] = useState(null);
  const sanitizedContent = emailContent
    ? DOMPurify.sanitize(emailContent)
    : null;

  useEffect(() => {
    if (sanitizedContent) {
      const iframe = document.getElementById('dynamicIframe');
      iframe.onload = () => {
        try {
          iframe.style.height = `${
            iframe.contentWindow.document.body.scrollHeight + 16
          }px`;
          setHeight(iframe.style.height);
        } catch (e) {}
      };
    }
  }, [sanitizedContent]);

  return (
    <AppStack style={{ gap: 0 }}>
      <AppStack style={{ padding: 16 }}>
        <AppText style={{ fontSize: 16, color: '#666' }}>
          <span style={{ fontWeight: 500 }}>Subject:</span> {subject}
        </AppText>
      </AppStack>
      <Divider />
      <AppStack
        style={{
          flex: 1,
          padding: isMobileOrSmaller ? 0 : '8px 4px 0px 4px',
          minHeight: 400
        }}
      >
        <iframe
          allowFullScreen
          id="dynamicIframe"
          sandbox="allow-same-origin"
          scrolling={height ? 'yes' : 'no'}
          srcDoc={sanitizedContent}
          style={{ border: 0 }}
          title="Email Template"
          width="100%"
        />
      </AppStack>
    </AppStack>
  );
};

EmailNotificationPreview.propTypes = {
  emailContent: PropTypes.string,
  subject: PropTypes.string
};

export default EmailNotificationPreview;
